import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import {
  InputTextFieldEquip,
  InputTextFieldMultiEquip,
} from "../Parts/InputText";
import { InputDateMonthEquip } from "../Parts/InputDate";
import { Invest0Equip } from "../Parts/Invest0";
import { InvestE10 } from "../Parts/Invest1";
import { CheckFormEquipment } from "../Parts/CheckForm";
import { bangou, ariari, arinashi } from "../Parts/investLine";
import { COPYDATA_EQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";

const EInvestLight = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="検査結果表（非常用の照明装置）"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <EInvest0 />
        <Invest0Equip type={"e40_3_"} />
        <InvestE10 />
        <EInvest11 />
        <EInvest12 />
        <EInvest13 />
        <EInvest14 />
        <EInvest15 />
        <EInvest16 />
        <EInvest18 />
      </Box>
    </GenericTemplate>
  );
};

export default EInvestLight;

export const EInvest0 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_EQUIPMENT,
      bulding,
      page,
      page_moto: "e43",
    });
  };

  if (location.pathname !== "/einvestlight/e43/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#81c783" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

const EInvest11 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          1　照明器具
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          非常用の照明装置
        </Grid>

        <Grid item xs={4} sx={{ ...ariari }}>
          使用電球、ランプ等
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"1"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          照明器具の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"1"} row={"2"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest12 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          2　電池内蔵形の蓄電池、電源別置形の蓄電池及び自家用発電装置
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          予備電源
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          予備電源への切替え及び器具の点灯の状況並びに予備電源の性能
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"2"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          照度
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          照度の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"2"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          分電盤
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          非常用電源分岐回路の表示の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"2"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          配線
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          配電管等の防火区画貫通措置の状況(隠蔽部分及び埋設部分を除く。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"2"} row={"4"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest13 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          3　電源別置形の蓄電池及び自家用発電装置
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          配線
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          照明器具の取付けの状況及び配線の接続の状況(隠蔽部分及び埋設部分を除く。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          電気回路の接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          接続部(幹線分岐及びボックス内に限る。)の耐熱処理の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          予備電源から非常用の照明器具間の配線の耐熱処理の状況(隠蔽部分及び埋設部分を除
          く。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          切替回路
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          常用の電源から蓄電池設備への切替えの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          蓄電池設備と自家用発電装置併用の場合の切替えの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"3"} row={"6"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest14 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          4　電池内蔵形の蓄電池
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          配線及び充電ランプ
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          充電ランプの点灯の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"4"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          誘導灯及び非常用照明兼用器具の専用回路の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"4"} row={"2"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest15 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          5　電源別置形の蓄電池
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          蓄電池
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          蓄電池等の状況
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          蓄電池室の防火区画等の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          蓄電池室の換気の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          蓄電池の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電圧
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電解液比重
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電解液の温度
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          充電器
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          充電器室の防火区画等の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          キュービクルの取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"5"} row={"8"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest16 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          6　自家用発電装置
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          自家用発電装置
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自家用発電装置等の状況
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電機室の防火区画等の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          発電機の発電容量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          発電機及び原動機の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          燃料油、潤滑油及び冷却水の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          始動用の空気槽の圧力
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          セル始動用蓄電池及び電気ケーブルの接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          燃料及び冷却水の漏洩の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          計器類及びランプ類の指示及び点灯の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電装置の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電機室の給排気の状況(屋内に設置されている場合に限 る。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          接地線の接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          絶縁抵抗
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自家用発電装置等の 性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電源の切替えの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          始動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          運転の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排気の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          コンプレッサー、燃料ポンプ、冷却水ポンプ等の補機類の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"3"} num={"6"} row={"17"} />
        </Grid>
      </Grid>
    </>
  );
});

// const EInvest17 = React.memo(() => {
//   return (
//     <>
//       <Grid
//         container
//         alignItems="center"
//         sx={{ height: "57px", borderBottom: 1 }}
//       >
//         <Grid item xs={12} sx={{}}>
//           7　上記以外の検査項目
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （１）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_3_7_10"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"3"} num={"7"} row={"1"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （２）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_3_7_20"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"3"} num={"7"} row={"2"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （３）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_3_7_30"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"3"} num={"7"} row={"3"} />
//         </Grid>
//       </Grid>
//     </>
//   );
// });

const EInvest18 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          特記事項
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          番号
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          検査項目
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          指摘の具体的内容等
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          改善策の具体的内容等
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          改善（予定）年月
        </Grid>
      </Grid>
      <EInvest181 low={1} />
      <EInvest181 low={2} />
      <EInvest181 low={3} />
      <EInvest181 low={4} />
      <EInvest181 low={5} />
    </>
  );
});

const EInvest181 = React.memo(({ low }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{}}>
        <Grid item xs={1} sx={{ ...bangou }}>
          <InputTextFieldEquip key_num={`e41_3_8_${low}1`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_3_8_${low}2`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_3_8_${low}3`} />
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_3_8_${low}4`} />
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          <InputDateMonthEquip key_num1={`e41_3_8_${low}5_1`} />
        </Grid>
      </Grid>
    </>
  );
});
