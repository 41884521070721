import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CreateIcon from "@mui/icons-material/Create";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import GenericTemplateArchitecture from "./GenericTemplateArchitecture";
import GenericTemplateEquipment from "./GenericTemplateEquipment";
import GenericTemplateFireEquipment from "./GenericTemplateFireEquipment";

import { FaRegBuilding } from "react-icons/fa";
import { FaFan } from "react-icons/fa";
import { ImFire } from "react-icons/im";

const drawerWidth = 240;

const typography = {
  fontFamily: [
    "Noto Sans JP",
    "Lato",
    "游ゴシック Medium",
    "游ゴシック体",
    "Yu Gothic Medium",
    "YuGothic",
    "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN",
    "メイリオ",
    "Meiryo",
    "ＭＳ Ｐゴシック",
    "MS PGothic",
    "sans-serif",
  ].join(","),
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      shizuoka prefecture mine yasutaka {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const GenericTemplate = ({ children, title, type, selectedIndex }) => {
  const buildingNumberArchitecture = useSelector(
    (state) => state["Architecture"]["B1"]["a0"]["a01_ro2"]
  );

  const buildingNameArchitecture = useSelector(
    (state) => state["Architecture"]["B1"]["a9"]["a92_1"]
  );

  let buildingNumberArchitectureList = [];
  if (buildingNumberArchitecture > 1) {
    for (let index = 2; index < buildingNumberArchitecture + 1; index++) {
      buildingNumberArchitectureList.push(
        <GenericTemplateArchitecture
          key={index}
          number={index}
          selectedIndex={selectedIndex}
        />
      );
    }
  }

  const buildingNumberEquipment = useSelector(
    (state) => state["Equipment"]["B1"]["e0"]["e01_ro2"]
  );

  const buildingNameEquipment = useSelector(
    (state) => state["Equipment"]["B1"]["e9"]["e92_1"]
  );

  const buildingNumberEquipmentList = [];
  if (buildingNumberEquipment > 1) {
    for (let index = 2; index < buildingNumberEquipment + 1; index++) {
      buildingNumberEquipmentList.push(
        <GenericTemplateEquipment
          key={index}
          number={index}
          selectedIndex={selectedIndex}
        />
      );
    }
  }

  const buildingNumberFireEquipment = useSelector(
    (state) => state["FireEquipment"]["B1"]["f0"]["f01_ro2"]
  );

  const buildingNameFireEquipment = useSelector(
    (state) => state["FireEquipment"]["B1"]["f9"]["f92_1"]
  );

  let buildingNumberFireEquipmentList = [];
  if (buildingNumberFireEquipment > 1) {
    for (let index = 2; index < buildingNumberFireEquipment + 1; index++) {
      buildingNumberFireEquipmentList.push(
        <GenericTemplateFireEquipment
          key={index}
          number={index}
          selectedIndex={selectedIndex}
        />
      );
    }
  }

  const CheackInvestVentilation = useSelector(
    (state) => state["Equipment"]["B1"]["e2"]["e21_ni1"]
  );
  const CheackInvestSmoke = useSelector(
    (state) => state["Equipment"]["B1"]["e2"]["e21_ni2"]
  );
  const CheackInvestLight = useSelector(
    (state) => state["Equipment"]["B1"]["e2"]["e21_ni3"]
  );

  const CheackInvestFireDoor = useSelector(
    (state) => state["FireEquipment"]["B1"]["f2"]["f25_ro1_1"]
  );
  const CheackInvestFireShutter = useSelector(
    (state) => state["FireEquipment"]["B1"]["f2"]["f25_ro2_1"]
  );
  const CheackInvestFireProtectionCrossScreen = useSelector(
    (state) => state["FireEquipment"]["B1"]["f2"]["f25_ro3_1"]
  );
  const CheackInvestDrencher = useSelector(
    (state) => state["FireEquipment"]["B1"]["f2"]["f25_ro4_1"]
  );

  let theme = null;
  if (type === "特殊建築物等") {
    theme = createTheme({
      typography,
      palette: {
        primary: { main: colors.brown[300] }, // テーマの色
      },
    });
  } else if (type === "建築設備") {
    theme = createTheme({
      typography,
      palette: {
        primary: { main: colors.green[300] }, // テーマの色
      },
    });
  } else if (type === "防火設備") {
    theme = createTheme({
      typography,
      palette: {
        primary: { main: colors.red[300] }, // テーマの色
      },
    });
  } else {
    theme = createTheme({
      typography,
      palette: {
        primary: { main: colors.blue[300] }, // テーマの色
      },
    });
  }

  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        display: "flex",
      },
      toolbar: {
        paddingRight: 24,
      },
      toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
      },
      menuButtonHidden: {
        display: "none",
      },
      title: {
        flexGrow: 1,
      },
      pageTitle: {
        marginBottom: theme.spacing(1),
      },
      drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
      },
      link: {
        textDecoration: "none",
        color: theme.palette.text.secondary,
      },
      link_1: {
        textDecoration: "underline",
        color: theme.palette.text.secondary,
      },
      Annex_building: {
        paddingLeft: "15px",
      },
    })
  );

  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (type === "特殊建築物等") {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <FaRegBuilding />
                {`　特定建築物等`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link to="/adata" className={classes.link}>
                <ListItem button selected={selectedIndex === "/adata"}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="データ入出力" />
                </ListItem>
              </Link>
              <Link to="/a0/a0/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/a0/a0/B1"}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="共通事項"
                    secondary="所有者・調査者等"
                  />
                </ListItem>
              </Link>
              <Link to={`/a1/a1/B1`} className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
                  </ListItemIcon>
                  <ListItemText primary={`第1棟 ${buildingNameArchitecture}`} />
                </ListItem>
              </Link>
              <Link to="/a1/a1/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === `/a1/a1/B1`}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第一面"
                  />
                </ListItem>
              </Link>

              <Link to={`/a2/a2/B1`} className={classes.link}>
                <ListItem button selected={selectedIndex === `/a2/a2/B1`}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第二面"
                  />
                </ListItem>
              </Link>
              <Link to={`/a3/a3/B1`} className={classes.link}>
                <ListItem button selected={selectedIndex === `/a3/a3/B1`}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第三面"
                  />
                </ListItem>
              </Link>
              <Link to={`/a4/a4/B1`} className={classes.link}>
                <ListItem button selected={selectedIndex === `/a4/a4/B1`}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第四面"
                  />
                </ListItem>
              </Link>
              <Link to={`/ainvest/a5/B1`} className={classes.link}>
                <ListItem button selected={selectedIndex === `/ainvest/a5/B1`}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="調査結果表"
                  />
                </ListItem>
              </Link>
              {buildingNumberArchitectureList}
              <Link to="/" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="ホームへ" />
                </ListItem>
              </Link>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.pageTitle}
              >
                {title}
              </Typography>
              {children}
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (type === "建築設備") {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <FaFan />
                {`　建築設備`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link to="/edata" className={classes.link}>
                <ListItem button selected={selectedIndex === "/edata"}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="データ入出力" />
                </ListItem>
              </Link>
              <Link to="/e0/e0/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/e0/e0/B1"}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="共通事項"
                    secondary="所有者・検査者等"
                  />
                </ListItem>
              </Link>
              <Link to={`/e1/e1/B1`} className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
                  </ListItemIcon>
                  <ListItemText primary={`第1棟 ${buildingNameEquipment}`} />
                </ListItem>
              </Link>
              <Link to="/e1/e1/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/e1/e1/B1"}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第一面"
                  />
                </ListItem>
              </Link>

              <Link to="/e2/e2/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/e2/e2/B1"}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第二面"
                  />
                </ListItem>
              </Link>
              <Link to="/e3/e3/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/e3/e3/B1"}>
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.Annex_building}
                    primary="第三面"
                  />
                </ListItem>
              </Link>
              {CheackInvestVentilation && (
                <Link to="/einvestventilation/e41/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/einvestventilation/e41/B1"}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.Annex_building}
                      primary="検査結果表"
                      secondary={"(換気設備)"}
                    />
                  </ListItem>
                </Link>
              )}
              {CheackInvestSmoke && (
                <Link to="/einvestsmoke/e42/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/einvestsmoke/e42/B1"}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.Annex_building}
                      primary="検査結果表"
                      secondary={"(排煙設備)"}
                    />
                  </ListItem>
                </Link>
              )}
              {CheackInvestLight && (
                <Link to="/einvestlight/e43/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/einvestlight/e43/B1"}
                  >
                    <ListItemIcon>
                      <CreateIcon />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.Annex_building}
                      primary="検査結果表"
                      secondary={"(非常用の照明装置)"}
                    />
                  </ListItem>
                </Link>
              )}
              {buildingNumberEquipmentList}
              <Link to={`/esummry/esummry/B1`} className={classes.link}>
                <ListItem
                  button
                  selected={selectedIndex === "/esummry/esummry/B1"}
                >
                  <ListItemIcon>
                    <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
                  </ListItemIcon>
                  <ListItemText primary={`概要書`} />
                </ListItem>
              </Link>
              <Link to="/" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="ホームへ" />
                </ListItem>
              </Link>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.pageTitle}
              >
                {title}
              </Typography>
              {children}
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (type === "防火設備") {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <ImFire />
                {`　防火設備`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link to="/fdata" className={classes.link}>
                <ListItem button selected={selectedIndex === "/fdata"}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="データ入出力" />
                </ListItem>
              </Link>
              <Link to="/f0/f0/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === "/f0/f0/B1"}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="共通事項"
                    secondary="所有者・検査者等"
                  />
                </ListItem>
              </Link>
              <Link to={`/f1/f1/B1`} className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`第1棟 ${buildingNameFireEquipment}`}
                  />
                </ListItem>
              </Link>
              <Link to="/f1/f1/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === `/f1/f1/B1`}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="第一面" />
                </ListItem>
              </Link>
              <Link to="/f2/f2/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === `/f2/f2/B1`}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="第二面" />
                </ListItem>
              </Link>
              <Link to="/f3/f3/B1" className={classes.link}>
                <ListItem button selected={selectedIndex === `/f3/f3/B1`}>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primary="第三面" />
                </ListItem>
              </Link>
              {CheackInvestFireDoor && (
                <Link to="/finvestfiredoor/f41/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/finvestfiredoor/f41/B1"}
                  >
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText primary="検査結果表" secondary={"(防火扉)"} />
                  </ListItem>
                </Link>
              )}
              {CheackInvestFireShutter && (
                <Link to="/finvestfireshutter/f42/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/finvestfireshutter/f42/B1"}
                  >
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="検査結果表"
                      secondary={"(防火シャッター)"}
                    />
                  </ListItem>
                </Link>
              )}
              {CheackInvestFireProtectionCrossScreen && (
                <Link
                  to="/finvestfireprotectioncrossscreen/f43/B1"
                  className={classes.link}
                >
                  <ListItem
                    button
                    selected={
                      selectedIndex ===
                      "/finvestfireprotectioncrossscreen/f43/B1"
                    }
                  >
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="検査結果表"
                      secondary={"(耐火クロススクリーン)"}
                    />
                  </ListItem>
                </Link>
              )}
              {CheackInvestDrencher && (
                <Link to="/finvestdrencher/f44/B1" className={classes.link}>
                  <ListItem
                    button
                    selected={selectedIndex === "/finvestdrencher/f44/B1"}
                  >
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="検査結果表"
                      secondary={`(ドレンチャー)`}
                    />
                  </ListItem>
                </Link>
              )}
              {buildingNumberFireEquipmentList}
              <Link to={`/fsummry/fsummry/B1`} className={classes.link}>
                <ListItem
                  button
                  selected={selectedIndex === "/fsummry/fsummry/B1"}
                >
                  <ListItemIcon>
                    <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
                  </ListItemIcon>
                  <ListItemText primary={`概要書`} />
                </ListItem>
              </Link>
              <Link to="/" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="ホームへ" />
                </ListItem>
              </Link>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.pageTitle}
              >
                {title}
              </Typography>
              {children}
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="absolute"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                <HomeIcon />
                {`　ホーム`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {/* <List>
              <Link to="/" className={classes.link}>
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary="ホームへ" />
                </ListItem>
              </Link>
            </List> */}
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.pageTitle}
              >
                {title}
              </Typography>
              {children}
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    );
  }
};

export default React.memo(GenericTemplate);
