import React from "react";
import { Link } from "react-router-dom";

import ApartmentIcon from "@mui/icons-material/Apartment";
import CreateIcon from "@mui/icons-material/Create";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";

const typography = {
  fontFamily: [
    "Noto Sans JP",
    "Lato",
    "游ゴシック Medium",
    "游ゴシック体",
    "Yu Gothic Medium",
    "YuGothic",
    "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN",
    "メイリオ",
    "Meiryo",
    "ＭＳ Ｐゴシック",
    "MS PGothic",
    "sans-serif",
  ].join(","),
};

const GenericTemplateArchitecture = ({ selectedIndex, number }) => {
  const buildingName = useSelector(
    (state) => state["Architecture"][`B${number}`]["a9"]["a92_1"]
  );

  const theme = createTheme({
    typography,
    palette: {
      primary: { main: colors.brown[300] }, // テーマの色
    },
  });

  const useStyles = makeStyles(() =>
    createStyles({
      link: {
        textDecoration: "none",
        color: theme.palette.text.secondary,
      },
      Annex_building: {
        paddingLeft: "15px",
      },
    })
  );
  const classes = useStyles();

  return (
    <>
      <Link to={`/a2/a2/B${number}`} className={classes.link}>
        <ListItem button>
          <ListItemIcon>
            <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
          </ListItemIcon>
          <ListItemText primary={`第${number}棟 ${buildingName}`} />
        </ListItem>
      </Link>
      <Link to={`/a2/a2/B${number}`} className={classes.link}>
        <ListItem button selected={selectedIndex === `/a2/a2/B${number}`}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText className={classes.Annex_building} primary="第二面" />
        </ListItem>
      </Link>
      <Link to={`/a3/a3/B${number}`} className={classes.link}>
        <ListItem button selected={selectedIndex === `/a3/a3/B${number}`}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText className={classes.Annex_building} primary="第三面" />
        </ListItem>
      </Link>
      <Link to={`/ainvest/a5/B${number}`} className={classes.link}>
        <ListItem button selected={selectedIndex === `/ainvest/a5/B${number}`}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.Annex_building}
            primary="調査結果表"
          />
        </ListItem>
      </Link>
    </>
  );
};

export default React.memo(GenericTemplateArchitecture);
