import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import {
  InputTextFieldEquip,
  InputTextFieldMultiEquip,
} from "../Parts/InputText";
import { InputRadioEquip } from "../Parts/InputRadio";
import { InputCheakBoxEquip } from "../Parts/InputCheakBox";
import { InputDateEquip, InputDateMonthEquip } from "../Parts/InputDate";
import { InputSelectEquip } from "../Parts/InputSelect";
import { COPYDATA_EQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";
import { prefecture } from "../Prefecture/Prefecture";

const E2 = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="第２面　建築設備の状況等"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <E20 />
        <E21 />
        <E22 />
        <E23 />
        <E24 />
        <E25 />
        <E26 />
        <E27 />
        <E28 />
        <E29 />
        <E210 />
        <E211 />
        <E212 />
        <E213 />
        <E214 />
        <E215 />
        <E220 />
      </Box>
    </GenericTemplate>
  );
};

export default E2;

export const E20 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_EQUIPMENT,
      bulding,
      page,
      page_moto: "e2",
    });
  };

  if (location.pathname !== "/e2/e2/B1") {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#81c783" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextFieldEquip key_num={"e92_1"} page={"e9"} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextFieldEquip key_num={"e92_1"} page={"e9"} />
          </Grid>
        </Grid>
      </>
    );
  }
});

export const E21 = React.memo(() => {
  return (
    <div>
      【1　建築物の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　階数
        </Grid>
        <Grid item xs={2} textAlign="left">
          地上
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e21_i1"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
        <Grid item xs={2} textAlign="left">
          地下
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e21_i2"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　建築面積
        </Grid>
        <Grid item xs={8}>
          <InputTextFieldEquip key_num={"e21_ro"} />
        </Grid>
        <Grid item xs={2}>
          ㎡
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　延べ面積
        </Grid>
        <Grid item xs={8}>
          <InputTextFieldEquip key_num={"e21_ha"} />
        </Grid>
        <Grid item xs={2}>
          ㎡
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ mb: 1 }}>
          ニ　検査対象建築設備
        </Grid>
        <Grid item xs={3} sx={{ mb: 1 }}>
          <InputCheakBoxEquip key_num={"e21_ni1"} />
          換気設備
        </Grid>
        <Grid item xs={3} sx={{ mb: 1 }}>
          <InputCheakBoxEquip key_num={"e21_ni2"} />
          排煙設備
        </Grid>
        <Grid item xs={3} sx={{ mb: 1 }}>
          <InputCheakBoxEquip key_num={"e21_ni3"} />
          非常用の照明設備
        </Grid>
      </Grid>
    </div>
  );
});

export const E22 = React.memo(() => {
  return (
    <div>
      【２　確認済証交付年月日等】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　確認済証交付年月日
        </Grid>
        <Grid item xs={5}>
          <InputDateEquip
            key_num1={"e22_i1_1"}
            placeholderText={"交付年月日"}
          />
        </Grid>
        <Grid item xs={5}>
          <InputTextFieldEquip
            key_num={"e22_i2"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　確認済証交付者
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e22_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextFieldEquip key_num={"e22_ro2"} />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　検査済証交付年月日
        </Grid>
        <Grid item xs={5}>
          <InputDateEquip
            key_num1={"e22_ha1_1"}
            placeholderText={"交付年月日"}
          />
        </Grid>
        <Grid item xs={5}>
          <InputTextFieldEquip
            key_num={"e22_ha2"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ニ　検査済証交付者
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e22_ni1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextFieldEquip key_num={"e22_ni2"} />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E23 = React.memo(() => {
  return (
    <div>
      【3　検査日等】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　今回の検査
        </Grid>
        <Grid item xs={10} textAlign="left">
          <InputDateEquip key_num1={"e23_i_1"} placeholderText={"実施日"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ロ　前回の検査
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputRadioEquip key_num={"e23_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDateEquip
                  key_num1={"e23_ro2_1"}
                  placeholderText={"報告日"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ mb: 1 }}>
          ハ　前回の検査に関する書類の写し
        </Grid>
        <Grid item xs={9} sx={{ mb: 1 }}>
          <InputRadioEquip key_num={"e23_ha"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E24 = React.memo(() => {
  return (
    <div>
      【4　換気設備の検査者】
      <div>（代表となる検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e24_ai1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e24_ai2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e24_ai3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建築設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e24_ai4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_aro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_aha"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_ani1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e24_ani2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e24_ani3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip
            key_num={"e24_ani4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_aho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_ahe"} />
        </Grid>
        <Grid item xs={2}>
          ト　電話番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_ato"} />
        </Grid>
      </Grid>
      <div>（その他の検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e24_bi1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e24_bi2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e24_bi3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建築設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e24_bi4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_bro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_bha"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_bni1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e24_bni2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e24_bni3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip
            key_num={"e24_bni4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_bho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e24_bhe"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ト　電話番号
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e24_bto"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E25 = React.memo(() => {
  return (
    <div>
      【5　換気設備の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          イ　無窓居室
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_i1_1"} />
              自然換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_i1_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_i2_1"} />
              機械換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_i2_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_i3_1"} />
              中央管理方式の空気調和設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_i3_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_i4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_i4_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_i5"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ロ　火気使用室
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ro1_1"} />
              自然換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ro1_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ro2_1"} />
              機械換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ro2_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ro3_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ro3_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ro4"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ハ　居室等
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ha1_1"} />
              自然換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ha1_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ha2_1"} />
              機械換気設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ha2_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ha3_1"} />
              中央管理方式の空気調和設備
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ha3_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ha4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e25_ha4_2"}
                label={"◯系統◯室と記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e25_ha5"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={4}>
          ニ　防火ダンパーの有無
        </Grid>
        <Grid item xs={8}>
          <InputRadioEquip key_num={"e25_ni"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E26 = React.memo(() => {
  return (
    <div>
      【6　換気設備の検査の状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e26_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxEquip key_num={"e26_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 3 }}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputTextFieldMultiEquip key_num={"e26_ro"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e26_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonthEquip key_num1={"e26_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E27 = React.memo(() => {
  return (
    <div>
      【7　換気設備の不具合の発生状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　不具合
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e27_i"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　不具合記録
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e27_ro"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善の状況
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e27_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={3}>
                <InputDateMonthEquip key_num1={"e27_ha2_1"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E28 = React.memo(() => {
  return (
    <div>
      【8　排煙設備の検査者】
      <div>（代表となる検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e28_ai1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e28_ai2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e28_ai3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建築設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e28_ai4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_aro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_aha"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_ani1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e28_ani2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e28_ani3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip
            key_num={"e28_ani4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_aho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_ahe"} />
        </Grid>
        <Grid item xs={2}>
          ト　電話番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_ato"} />
        </Grid>
      </Grid>
      <div>（その他の検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e28_bi1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e28_bi2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e28_bi3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建築設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e28_bi4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_bro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_bha"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_bni1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e28_bni2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e28_bni3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip
            key_num={"e28_bni4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_bho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e28_bhe"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ト　電話番号
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e28_bto"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E29 = React.memo(() => {
  return (
    <div>
      【9　排煙設備の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          イ　避難安全検証法等の適用
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_i1_1"} />
              区画避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_i1_2"}
                label={"◯階の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_i2_1"} />
              階避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_i2_2"}
                label={"◯階の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_i3_1"} />
              全館避難安全検証法
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_i4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip key_num={"e29_i4_2"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ロ　特別避難階段の階段室又は附室
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ro1_1"} />
              吸引式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ro1_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ro2_1"} />
              給気式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ro2_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ro3_1"} />
              加圧式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ro3_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ro4_1"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ハ　非常用エレベーターの昇降路又は乗降ロビー
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ha1_1"} />
              吸引式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ha1_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ha2_1"} />
              給気式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ha2_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ha3_1"} />
              加圧式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ha3_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ha4_1"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ニ　非常用エレベーターの昇降ロビーの用に供する附室
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ni1_1"} />
              吸引式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ni1_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ni2_1"} />
              給気式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ni2_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ni3_1"} />
              加圧式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ni3_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ni4_1"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          ホ　居室等
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ho1_1"} />
              吸引式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ho1_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ho2_1"} />
              給気式
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e29_ho2_2"}
                label={"◯区画の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_ho3_1"} />無
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={4}>
          へ　予備電源
        </Grid>
        <Grid item xs={4} textAlign="left">
          <InputCheakBoxEquip key_num={"e29_he1_1"} />
          吸引式
        </Grid>
        <Grid item xs={4}>
          <InputCheakBoxEquip key_num={"e29_he2_1"} />
          自家用発電装置
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <InputCheakBoxEquip key_num={"e29_he3_1"} />
          直結エンジン
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e29_he4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip key_num={"e29_he4_2"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export const E210 = React.memo(() => {
  return (
    <div>
      【10　排煙設備の検査の状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e210_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxEquip key_num={"e210_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMultiEquip key_num={"e210_ro"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e210_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonthEquip key_num1={"e210_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E211 = React.memo(() => {
  return (
    <div>
      【11　排煙設備の不具合の発生状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　不具合
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e211_i"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　不具合記録
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e211_ro"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善の状況
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e211_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={3}>
                <InputDateMonthEquip key_num1={"e211_ha2_1"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E212 = React.memo(() => {
  return (
    <div>
      【12　非常用の照明装置の検査者】
      <div>（代表となる検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e212_ai1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e212_ai2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e212_ai3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建物設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e212_ai4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_aro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_aha"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_ani1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e212_ani2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip key_num={"e212_ani3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip key_num={"e212_ani4"} />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_aho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_ahe"} />
        </Grid>
        <Grid item xs={2}>
          ト　電話番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_ato"} />
        </Grid>
      </Grid>
      <div>（その他の検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip
            key_num={"e212_bi1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectEquip key_num={"e212_bi2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e212_bi3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          建物設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldEquip
            key_num={"e212_bi4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_bro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_bha"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip
            key_num={"e212_bni1"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectEquip
            key_num={"e212_bni2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldEquip
            key_num={"e212_bni3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldEquip
            key_num={"e212_bni4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_bho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldEquip key_num={"e212_bhe"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ト　電話番号
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldEquip key_num={"e212_bto"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const E213 = React.memo(() => {
  return (
    <div>
      【13　非常用の照明装置の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          イ　照明器具
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_i1_1"} />
              白熱灯
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e213_i1_2"}
                label={"◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_i2_1"} />
              蛍光灯
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e213_i2_2"}
                label={"◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_i3_1"} />
              LEDランプ
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e213_i3_2"}
                label={"◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_i4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldEquip
                key_num={"e213_i4_2"}
                label={"◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ロ　予備電源
        </Grid>
        <Grid item xs={10} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_ro1_1"} />
              蓄電池（内蔵形）
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro1_2"}
                label={"居室◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro1_3"}
                label={"廊下◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro1_4"}
                label={"階段◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_ro2_1"} />
              蓄電池（別置形）
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro2_2"}
                label={"居室◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro2_3"}
                label={"廊下◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro2_4"}
                label={"階段◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_ro3_1"} />
              　自家用発電装置
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro3_2"}
                label={"居室◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro3_3"}
                label={"廊下◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro3_4"}
                label={"階段◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_ro4_1"} />
              蓄電池（別置形） ・自家発電装置併用
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro4_2"}
                label={"居室◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro4_3"}
                label={"廊下◯灯の◯の部分のみ記載"}
              />
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip
                key_num={"e213_ro4_4"}
                label={"階段◯灯の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxEquip key_num={"e213_ro5_1"} />
              　その他
            </Grid>
            <Grid item xs={2}>
              <InputTextFieldEquip key_num={"e213_ro5_2"} />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export const E214 = React.memo(() => {
  return (
    <div>
      【14　非常用の照明装置の検査の状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e214_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxEquip key_num={"e214_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMultiEquip key_num={"e214_ro"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e214_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonthEquip key_num1={"e214_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E215 = React.memo(() => {
  return (
    <div>
      【15　非常用の照明装置の不具合の発生状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　不具合
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e215_i"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　不具合記録
        </Grid>
        <Grid item xs={10}>
          <InputRadioEquip key_num={"e215_ro"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善の状況
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioEquip key_num={"e215_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={3}>
                <InputDateMonthEquip key_num1={"e215_ha2_1"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
            </Grid>
          </InputRadioEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const E220 = React.memo(() => {
  return (
    <div>
      【20 備考】
      <InputTextFieldMultiEquip key_num={"e220_1"} />
    </div>
  );
});
