import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
} from "./Word";

export const InputSelect = React.memo(({ key_num, items }) => {
  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const value = useSelector(
    (state) => state["Architecture"][bulding][page][key_num]
  );

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_ARCHITECTURE,
      payload: { [key]: value },
      bulding,
      page,
    });
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={value}
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export const InputSelectEquip = React.memo(({ key_num, items }) => {
  const params = useParams();
  const bulding = params.b;
  const page = params.page;
  const value = useSelector(
    (state) => state["Equipment"][bulding][page][key_num]
  );

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_EQUIPMENT,
      payload: { [key]: value },
      bulding,
      page,
    });
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={value}
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export const InputSelectFireEquip = React.memo(({ key_num, items }) => {
  const params = useParams();
  const bulding = params.b;
  const page = params.page;
  const value = useSelector(
    (state) => state["FireEquipment"][bulding][page][key_num]
  );

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_FIREEQUIPMENT,
      payload: { [key]: value },
      bulding,
      page,
    });
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={value}
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
