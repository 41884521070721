import React from "react";
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { InputTextFieldMultiEquip } from "../Parts/InputText";
import { InputDateMonthEquip } from "../Parts/InputDate";

import GenericTemplate from "../GenericTemplate";

const E3 = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="第3面　建築設備に係る不具合等の状況"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          １　換気設備
        </Grid>
      </Grid>
      <E311 />
      <E312 num={"1"} row={"1"} />
      <E312 num={"1"} row={"2"} />
      <E312 num={"1"} row={"3"} mb={3} borderBottom={1} />

      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          ２　排煙設備
        </Grid>
      </Grid>
      <E311 />
      <E312 num={"2"} row={"1"} />
      <E312 num={"2"} row={"2"} />
      <E312 num={"2"} row={"3"} mb={3} borderBottom={1} />

      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          ３　非常用の照明装置
        </Grid>
      </Grid>
      <E311 />
      <E312 num={"3"} row={"1"} />
      <E312 num={"3"} row={"2"} />
      <E312 num={"3"} row={"3"} />
    </GenericTemplate>
  );
};

export default React.memo(E3);

const E311 = React.memo(() => {
  return (
    <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={1}>
        不具合等を把握した年月
      </Grid>
      <Grid item xs={3}>
        不具合等の概要
      </Grid>
      <Grid item xs={3}>
        考えられる原因
      </Grid>
      <Grid item xs={1}>
        改善（予定）年月
      </Grid>
      <Grid item xs={4}>
        改善措置の概要等
      </Grid>
    </Grid>
  );
});

const E312 = React.memo(({ num, row, mb = 1, borderBottom = 0 }) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      sx={{ mb: 1, borderBottom }}
    >
      <Grid item xs={1} sx={{ mb }}>
        <InputDateMonthEquip
          key_num1={`e3_${num}_${row}1_1`}
          placeholderText={"年月"}
        />
      </Grid>
      <Grid item xs={3} sx={{ mb }}>
        <InputTextFieldMultiEquip key_num={`e3_${num}_${row}2`} />
      </Grid>
      <Grid item xs={3} sx={{ mb }}>
        <InputTextFieldMultiEquip key_num={`e3_${num}_${row}3`} />
      </Grid>
      <Grid item xs={1} sx={{ mb }}>
        <InputDateMonthEquip
          key_num1={`e3_${num}_${row}4_1`}
          placeholderText={"年月"}
        />
      </Grid>
      <Grid item xs={4} sx={{ mb }}>
        <InputTextFieldMultiEquip key_num={`e3_${num}_${row}5`} />
      </Grid>
    </Grid>
  );
});
