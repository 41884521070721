import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ApartmentIcon from "@mui/icons-material/Apartment";
import CreateIcon from "@mui/icons-material/Create";
import { createTheme } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const typography = {
  fontFamily: [
    "Noto Sans JP",
    "Lato",
    "游ゴシック Medium",
    "游ゴシック体",
    "Yu Gothic Medium",
    "YuGothic",
    "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN",
    "メイリオ",
    "Meiryo",
    "ＭＳ Ｐゴシック",
    "MS PGothic",
    "sans-serif",
  ].join(","),
};

const GenericTemplateEquipment = ({ selectedIndex, number }) => {
  const buildingName = useSelector(
    (state) => state["Equipment"][`B${number}`]["e9"]["e92_1"]
  );

  const CheackInvestVentilation = useSelector(
    (state) => state["Equipment"][`B${number}`]["e2"]["e21_ni1"]
  );
  const CheackInvestSmoke = useSelector(
    (state) => state["Equipment"][`B${number}`]["e2"]["e21_ni2"]
  );
  const CheackInvestLight = useSelector(
    (state) => state["Equipment"][`B${number}`]["e2"]["e21_ni3"]
  );

  const theme = createTheme({
    typography,
    palette: {
      primary: { main: colors.brown[300] }, // テーマの色
    },
  });

  const useStyles = makeStyles(() =>
    createStyles({
      link: {
        textDecoration: "none",
        color: theme.palette.text.secondary,
      },
      Annex_building: {
        paddingLeft: "15px",
      },
    })
  );

  const classes = useStyles();

  return (
    <>
      <Link to={`/e2/e2/B${number}`} className={classes.link}>
        <ListItem button>
          <ListItemIcon>
            <ApartmentIcon style={{ color: "rgb(161,136,127)" }} />
          </ListItemIcon>
          <ListItemText primary={`第${number}棟 ${buildingName}`} />
        </ListItem>
      </Link>
      <Link to={`/e2/e2/B${number}`} className={classes.link}>
        <ListItem button selected={selectedIndex === `/e2/e2/B${number}`}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText className={classes.Annex_building} primary="第二面" />
        </ListItem>
      </Link>
      {CheackInvestVentilation && (
        <Link
          to={`/einvestventilation/e41/B${number}`}
          className={classes.link}
        >
          <ListItem
            button
            selected={selectedIndex === `/einvestventilation/e41/B${number}`}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="検査結果表" secondary={"(換気設備)"} />
          </ListItem>
        </Link>
      )}
      {CheackInvestSmoke && (
        <Link to={`/einvestsmoke/e42/B${number}`} className={classes.link}>
          <ListItem
            button
            selected={selectedIndex === `/einvestsmoke/e42/B${number}`}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="検査結果表" secondary={"(排煙設備)"} />
          </ListItem>
        </Link>
      )}
      {CheackInvestLight && (
        <Link to={`/einvestlight/e43/B${number}`} className={classes.link}>
          <ListItem
            button
            selected={selectedIndex === `/einvestlight/e43/B${number}`}
          >
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText
              primary="検査結果表"
              secondary={"(非常用の照明装置)"}
            />
          </ListItem>
        </Link>
      )}
    </>
  );
};

export default React.memo(GenericTemplateEquipment);
