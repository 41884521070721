export const prefecture = [
  "大臣登録",
  "北海道知事登録",
  "青森県知事登録",
  "岩手県知事登録",
  "宮城県知事登録",
  "秋田県知事登録",
  "山形県知事登録",
  "福島県知事登録",
  "茨城県知事登録",
  "栃木県知事登録",
  "群馬県知事登録",
  "埼玉県知事登録",
  "千葉県知事登録",
  "東京都知事登録",
  "神奈川県知事登録",
  "新潟県知事登録",
  "富山県知事登録",
  "石川県知事登録",
  "福井県知事登録",
  "山梨県知事登録",
  "長野県知事登録",
  "岐阜県知事登録",
  "静岡県知事登録",
  "愛知県知事登録",
  "三重県知事登録",
  "滋賀県知事登録",
  "京都府知事登録",
  "大阪府知事登録",
  "兵庫県知事登録",
  "奈良県知事登録",
  "和歌山県知事登録",
  "鳥取県知事登録",
  "島根県知事登録",
  "岡山県知事登録",
  "広島県知事登録",
  "山口県知事登録",
  "徳島県知事登録",
  "香川県知事登録",
  "愛媛県知事登録",
  "高知県知事登録",
  "福岡県知事登録",
  "佐賀県知事登録",
  "長崎県知事登録",
  "熊本県知事登録",
  "大分県知事登録",
  "宮崎県知事登録",
  "鹿児島県知事登録",
  "沖縄県知事登録",
];
