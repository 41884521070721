import React from "react";
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import { InputTextFieldMultiFireEquip } from "../Parts/InputText";
import { InputRadioFireEquip } from "../Parts/InputRadio";
import { InputDateMonthFireEquip } from "../Parts/InputDate";
import { DisplayField } from "../Parts/DisplayField";

import GenericTemplate from "../GenericTemplate";

const FSummry = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="概要書"
      type={"防火設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <FSummry5 />
      </Box>
    </GenericTemplate>
  );
};

export default FSummry;

export const FSummry5 = React.memo(() => {
  return (
    <div>
      【5　不具合の発生状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　不具合
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"fsummry5_i"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　不具合記録
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"fsummry5_ro"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　不具合の概要
        </Grid>
        <Grid item xs={10}>
          <DisplayField
            key_num={["f3_1_12", "f3_1_22", "f3_1_32"]}
            type={"FireEquipment"}
            bulding={`B1`}
            page={"f3"}
            label={"第三面から自動転記"}
          />
        </Grid>
      </Grid>
      <InputRadioFireEquip key_num={"fsummry5_ni1"}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={2} sx={{ mb: 1 }}>
            ニ　改善の状況
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDateMonthFireEquip key_num1={"fsummry5_ni2_1"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={2} sx={{ mb: 3 }}></Grid>
          <Grid item xs={10} sx={{ mb: 3 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
              <Grid item xs={8}>
                <InputTextFieldMultiFireEquip
                  key_num={"fsummry5_ni3"}
                  label={"理由"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </InputRadioFireEquip>
    </div>
  );
});
