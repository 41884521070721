import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import RadioGroup from "@mui/material/RadioGroup";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
} from "./Word";

export const InputRadio = React.memo(({ key_num, children, bulding, page }) => {
  const params = useParams();
  bulding = params.b;
  page = params.page;

  const value = useSelector(
    (state) => state["Architecture"][bulding][page][key_num]
  );

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_ARCHITECTURE,
      payload: { [key]: value },
      bulding,
      page,
    });
  };

  return (
    <RadioGroup
      name="controlled-radio-buttons-group"
      value={value}
      onChange={(e) => {
        change(key_num, e.target.value);
      }}
      onClick={(e) => {
        if (e.target.value === value) {
          e.target.value = "";
          change(key_num, e.target.value);
        }
      }}
    >
      {children}
    </RadioGroup>
  );
});

export const InputRadioEquip = React.memo(
  ({ key_num, children, bulding, page }) => {
    const params = useParams();
    bulding = params.b;
    page = params.page;

    const value = useSelector(
      (state) => state["Equipment"][bulding][page][key_num]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_EQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    return (
      <RadioGroup
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        onClick={(e) => {
          if (e.target.value === value) {
            e.target.value = "";
            change(key_num, e.target.value);
          }
        }}
      >
        {children}
      </RadioGroup>
    );
  }
);

export const InputRadioFireEquip = React.memo(
  ({ key_num, children, bulding, page }) => {
    const params = useParams();
    bulding = params.b;
    page = params.page;

    const value = useSelector(
      (state) => state["FireEquipment"][bulding][page][key_num]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_FIREEQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    return (
      <RadioGroup
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        onClick={(e) => {
          if (e.target.value === value) {
            e.target.value = "";
            change(key_num, e.target.value);
          }
        }}
      >
        {children}
      </RadioGroup>
    );
  }
);
