import { createStore, compose } from "redux";

import { flatten } from "flat";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
  SET_DATA,
  SET_DATA_EQUIPMENT,
  SET_DATA_FIREEQUIPMENT,
  RESET_DATA,
  SET_LOCALSTORAGE_DATA,
  COPYDATA_ARCHITECTURE,
  COPYDATA_EQUIPMENT,
  COPYDATA_FIREEQUIPMENT,
} from "../Components/Parts/Word";

import { initialState } from "./InitialState";
import { architectureDataSet } from "./architectureDataSet";
import { equipmentDataSet } from "./equipmentDataSet";
import { fireProtectionFacilityDataSet } from "./fireProtectionFacilityDataSet";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DATA_ARCHITECTURE:
      return {
        Architecture: {
          ...state["Architecture"],
          [action.bulding]: {
            ...state["Architecture"][action.bulding],
            [action.page]: {
              ...state["Architecture"][action.bulding][action.page],
              ...action.payload,
            },
          },
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case CHANGE_DATA_EQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
          [action.bulding]: {
            ...state["Equipment"][action.bulding],
            [action.page]: {
              ...state["Equipment"][action.bulding][action.page],
              ...action.payload,
            },
          },
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case CHANGE_DATA_FIREEQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
          [action.bulding]: {
            ...state["FireEquipment"][action.bulding],
            [action.page]: {
              ...state["FireEquipment"][action.bulding][action.page],
              ...action.payload,
            },
          },
        },
      };
    case SET_DATA:
      return {
        Architecture: {
          ...state["Architecture"],
          [action.bulding]: action.payload,
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case SET_DATA_EQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
          [action.bulding]: action.payload,
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case SET_DATA_FIREEQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
          [action.bulding]: action.payload,
        },
      };
    case COPYDATA_ARCHITECTURE:
      return {
        Architecture: {
          ...state["Architecture"],
          [action.bulding]: {
            ...state["Architecture"][action.bulding],
            [action.page]: {
              ...state["Architecture"]["B1"][action.page_moto],
            },
          },
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case COPYDATA_EQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
          [action.bulding]: {
            ...state["Equipment"][action.bulding],
            [action.page]: {
              ...state["Equipment"]["B1"][action.page_moto],
            },
          },
        },
        FireEquipment: {
          ...state["FireEquipment"],
        },
      };
    case COPYDATA_FIREEQUIPMENT:
      return {
        Architecture: {
          ...state["Architecture"],
        },
        Equipment: {
          ...state["Equipment"],
        },
        FireEquipment: {
          ...state["FireEquipment"],
          [action.bulding]: {
            ...state["FireEquipment"][action.bulding],
            [action.page]: {
              ...state["FireEquipment"]["B1"][action.page_moto],
            },
          },
        },
      };
    case RESET_DATA:
      return initialState;
    case SET_LOCALSTORAGE_DATA:
      return action.payload;
    default:
      return state;
  }
};

const store = createStore(
  reducer,
  compose(
    process.env.NODE_ENV === "development" && window.devToolsExtension
      ? window.devToolsExtension()
      : (f) => f
  )
);

export default store;

export const headersLabel_A = [
  ...Object.keys(flatten(architectureDataSet)).map((value) => {
    return { label: value, key: value };
  }),
];

export const headersLabel_E = [
  ...Object.keys(flatten(equipmentDataSet)).map((value) => {
    return { label: value, key: value };
  }),
];

export const headersLabel_F = [
  ...Object.keys(flatten(fireProtectionFacilityDataSet)).map((value) => {
    return { label: value, key: value };
  }),
];
