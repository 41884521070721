import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
} from "./Word";

export const InputCheakBox = React.memo(({ key_num }) => {
  const params = useParams();
  const bulding = params.b;
  const page = params.page;

  let value = useSelector(
    (state) => state["Architecture"][bulding][page][key_num]
  );
  if (value === "") {
    value = false;
  }

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_ARCHITECTURE,
      payload: { [key]: value },
      bulding,
      page,
    });
  };

  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        change(key_num, !value);
      }}
    />
  );
});

export const InputCheakBoxEquip = React.memo(({ key_num }) => {
  const params = useParams();
  const bulding = params.b;
  const page = params.page;

  let value = useSelector(
    (state) => state["Equipment"][bulding][page][key_num]
  );
  if (value === "") {
    value = false;
  }

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_EQUIPMENT,
      payload: { [key]: value },
      bulding,
      page,
    });
  };

  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        change(key_num, !value);
      }}
    />
  );
});

export const InputCheakBoxFireEquip = React.memo(({ key_num }) => {
  const params = useParams();
  const bulding = params.b;
  const page = params.page;

  let value = useSelector(
    (state) => state["FireEquipment"][bulding][page][key_num]
  );
  if (value === "") {
    value = false;
  }

  const dispatch = useDispatch();

  const change = (key, value) => {
    dispatch({
      type: CHANGE_DATA_FIREEQUIPMENT,
      payload: { [key]: value },
      bulding,
      page,
    });
  };

  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        change(key_num, !value);
      }}
    />
  );
});
