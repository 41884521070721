import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import lscache from "lscache";

import TopPage from "./Components/TopPage";

import AData from "./Components/Architecture/AData";
import A0 from "./Components/Architecture/A0";
import A1 from "./Components/Architecture/A1";
import A2 from "./Components/Architecture/A2";
import A3 from "./Components/Architecture/A3";
import A4 from "./Components/Architecture/A4";
import AInvest from "./Components/Architecture/AInvest";

import EData from "./Components/Equipment/EData";
import E0 from "./Components/Equipment/E0";
import E1 from "./Components/Equipment/E1";
import E2 from "./Components/Equipment/E2";
import E3 from "./Components/Equipment/E3";
import EInvestVentilation from "./Components/Equipment/EInvestVentilation";
import EInvestSmoke from "./Components/Equipment/EInvestSmoke";
import EInvestLight from "./Components/Equipment/EInvestLight";
import ESummry from "./Components/Equipment/ESummry";

import FData from "./Components/FireProtectionFacility/FData";
import F0 from "./Components/FireProtectionFacility/F0";
import F1 from "./Components/FireProtectionFacility/F1";
import F2 from "./Components/FireProtectionFacility/F2";
import F3 from "./Components/FireProtectionFacility/F3";
import FInvestFireDoor from "./Components/FireProtectionFacility/FInvestFireDoor";
import FInvestFireShutter from "./Components/FireProtectionFacility/FInvestFireShutter";
import FInvestFireProtectionCrossScreen from "./Components/FireProtectionFacility/FInvestFireProtectionCrossScreen";
import FInvestDrencher from "./Components/FireProtectionFacility/FInvestDrencher";
import FSummry from "./Components/FireProtectionFacility/FSummry";

import useLocationChange from "./Components/useLocationChange";

import store from "./store";

import { SET_LOCALSTORAGE_DATA } from "./Components/Parts/Word";
import { initialState } from "./store/InitialState";

const App = () => {
  lscache.flushExpired();

  const dispatch = useDispatch();

  useEffect(() => {
    lscache.get("state") &&
      dispatch({
        type: SET_LOCALSTORAGE_DATA,
        payload: { ...initialState, ...lscache.get("state") },
      });
  }, [dispatch]);

  useLocationChange((location) => {
    const state = store.getState();

    lscache.set("state", state, 60);
  });

  return (
    <Routes>
      <Route path="/" element={<TopPage />} />
      <Route exact path="/adata" element={<AData />} />
      <Route exact path="/a0/:page/:b" element={<A0 />} />
      <Route exact path="/a1/:page/:b" element={<A1 />} />
      <Route exact path="/a2/:page/:b" element={<A2 />} />
      <Route exact path="/a3/:page/:b" element={<A3 />} />
      <Route exact path="/a4/:page/:b" element={<A4 />} />
      <Route exact path="/ainvest/:page/:b" element={<AInvest />} />

      <Route exact path="/edata" element={<EData />} />
      <Route exact path="/e0/:page/:b" element={<E0 />} />
      <Route exact path="/e1/:page/:b" element={<E1 />} />
      <Route exact path="/e2/:page/:b" element={<E2 />} />
      <Route exact path="/e3/:page/:b" element={<E3 />} />
      <Route
        exact
        path="/einvestventilation/:page/:b"
        element={<EInvestVentilation />}
      />
      <Route exact path="/einvestsmoke/:page/:b" element={<EInvestSmoke />} />
      <Route exact path="/einvestlight/:page/:b" element={<EInvestLight />} />
      <Route exact path="/esummry/:page/:b" element={<ESummry />} />

      <Route exact path="/fdata" element={<FData />} />
      <Route exact path="/f0/:page/:b" element={<F0 />} />
      <Route exact path="/f1/:page/:b" element={<F1 />} />
      <Route exact path="/f2/:page/:b" element={<F2 />} />
      <Route exact path="/f3/:page/:b" element={<F3 />} />
      <Route
        exact
        path="/finvestfiredoor/:page/:b"
        element={<FInvestFireDoor />}
      />
      <Route
        exact
        path="/finvestfireshutter/:page/:b"
        element={<FInvestFireShutter />}
      />
      <Route
        exact
        path="/finvestfireprotectioncrossscreen/:page/:b"
        element={<FInvestFireProtectionCrossScreen />}
      />
      <Route
        exact
        path="/finvestdrencher/:page/:b"
        element={<FInvestDrencher />}
      />
      <Route exact path="/fsummry/:page/:b" element={<FSummry />} />
    </Routes>
  );
};

export default App;
