import React from "react";
import { useLocation } from "react-router-dom";

import GenericTemplate from "../GenericTemplate";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { InputTextField } from "../Parts/InputText";
import { InputDate } from "../Parts/InputDate";
import { InputSelect } from "../Parts/InputSelect";
import { ARCHITECTURE } from "../Parts/Word";
import { InputZipCode } from "../Parts/InputZipCode";

const A0 = () => {
  const location = useLocation();

  return (
    <GenericTemplate type={"特殊建築物等"} selectedIndex={location.pathname}>
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              台帳番号
            </Grid>
            <Grid item xs={2}>
              <InputSelect
                key_num={"a01_i1"}
                items={["A", "B", "C", "F", "G", "H"]}
              />
            </Grid>
            <Grid item xs={1} textAlign="center" sx={{ fontSize: 40 }}>
              -
            </Grid>
            <Grid item xs={7}>
              <InputTextField key_num={"a01_i2"} />
            </Grid>
            <Grid item xs={2}>
              施設名称
            </Grid>
            <Grid item xs={7}>
              <InputTextField key_num={"a01_ro1"} />
            </Grid>
            <Grid item xs={1}>
              棟数
            </Grid>
            <Grid item xs={2}>
              <InputSelect
                key_num={"a01_ro2"}
                items={[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ]}
              />
            </Grid>
            <Grid item xs={2}>
              報告者氏名
            </Grid>
            <Grid item xs={10}>
              <InputTextField key_num={"a01_ha"} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              調査者氏名
            </Grid>
            <Grid item xs={10}>
              {/* <InputTextField key_num={"a01_ni"} page={"a1"} /> */}
              <InputTextField key_num={"a01_ni"} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2} sx={{ mb: 3 }}>
              報告年月日
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputDate key_num1={"a01_ho_1"} placeholderText={""} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}>
              報告済証（ステッカー返却先）
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              郵便番号
            </Grid>
            <Grid item xs={10}>
              <InputZipCode
                key_num={"a02_i1"}
                target_key_num={"a02_i2"}
                type={ARCHITECTURE}
                label={"〒"}
                page={"a0"}
              />
            </Grid>
            <Grid item xs={2}>
              住所
            </Grid>
            <Grid item xs={10}>
              <InputTextField key_num={"a02_i2"} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2}>
              法人名
            </Grid>
            <Grid item xs={10}>
              <InputTextField key_num={"a02_ro1"} />
            </Grid>
            <Grid item xs={2} sx={{ mb: 3 }}>
              氏名
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputTextField key_num={"a02_ro2"} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2} sx={{ mb: 3 }}>
              報告先
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputTextField key_num={"a91_1"} page={"a9"} />
            </Grid>
          </Grid>
        </div>
      </Box>
    </GenericTemplate>
  );
};

export default React.memo(A0);
