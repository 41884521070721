import React from "react";

import Grid from "@mui/material/Grid";

export const InvestA10 = React.memo(() => {
  return (
    <Grid container sx={{ mt: 1, borderBottom: 1 }}>
      <Grid item xs={1} sx={{ height: "82px" }}>
        番号
      </Grid>
      <Grid item xs={6} sx={{ borderLeft: 1, height: "82px" }}>
        調査項目
      </Grid>
      <Grid item xs={5} sx={{}}>
        <Grid container alignItems="center" sx={{}}>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            対象外
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            指摘なし
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            要是正
          </Grid>
          <Grid item xs={2} sx={{ borderLeft: 1, height: "82px" }}>
            既存不適格
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: 1, height: "82px" }}>
            担当調査者番号
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const InvestE10 = React.memo(() => {
  return (
    <Grid container sx={{ mt: 1, borderBottom: 1 }}>
      <Grid item xs={1} sx={{ height: "82px" }}>
        番号
      </Grid>
      <Grid item xs={6} sx={{ borderLeft: 1, height: "82px" }}>
        検査項目等
      </Grid>
      <Grid item xs={5} sx={{}}>
        <Grid container alignItems="center" sx={{}}>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            対象外
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            指摘なし
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            要是正
          </Grid>
          <Grid item xs={2} sx={{ borderLeft: 1, height: "82px" }}>
            既存不適格
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: 1, height: "82px" }}>
            担当検査者番号
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const InvestF10 = React.memo(() => {
  return (
    <Grid container sx={{ mt: 1, borderBottom: 1 }}>
      <Grid item xs={1} sx={{ height: "82px" }}>
        番号
      </Grid>
      <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
        検査項目
      </Grid>
      <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
        検査事項
      </Grid>
      <Grid item xs={5} sx={{}}>
        <Grid container alignItems="center" sx={{}}>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            対象外
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            指摘なし
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1, height: "82px" }}>
            要是正
          </Grid>
          <Grid item xs={2} sx={{ borderLeft: 1, height: "82px" }}>
            既存不適格
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: 1, height: "82px" }}>
            担当検査者番号
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
