import React from "react";
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  InputTextFieldFireEquip,
  InputTextFieldMultiFireEquip,
} from "../Parts/InputText";
import { InputRadioFireEquip } from "../Parts/InputRadio";
import { InputCheakBoxFireEquip } from "../Parts/InputCheakBox";
import { InputSelectFireEquip } from "../Parts/InputSelect";

import GenericTemplate from "../GenericTemplate";

const F1 = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="第１面"
      type={"防火設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <F11 />
        <F12 />
        <F13 />
        <F14 />
      </Box>
    </GenericTemplate>
  );
};

export default F1;

export const F11 = React.memo(() => {
  return (
    <div>
      【１ 所有者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f11_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f11_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f11_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f11_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldFireEquip key_num={"f11_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const F12 = React.memo(() => {
  return (
    <div>
      【２ 管理者】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f12_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f12_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f12_ha"} />
        </Grid>
        <Grid item xs={2}>
          ニ　住所
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f12_ni"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ホ　電話番号　
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldFireEquip key_num={"f12_ho"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const F13 = React.memo(() => {
  return (
    <div>
      【3　報告対象建築物】
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          イ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f13_i"} />
        </Grid>
        <Grid item xs={2}>
          ロ　名称のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f13_ro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　名称
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f13_ha"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ニ　用途
        </Grid>
        <Grid item xs={5}>
          <InputSelectFireEquip
            key_num={"f13_ni"}
            items={[
              "(1) 学校(幼稚園、専修学校及び各種学校を除く)",
              "(2) 病院・診療所(患者の収容施設のあるものに限る)",
              "(3) 公会堂・集会場",
              "(4) 百貨店、マーケット、物品販売業を営む店舗",
              "(5) キャバレー、カフェー等",
              "(6) 旅館、ホテル、簡易宿所(小)",
              "(7) 旅館、ホテル、簡易宿所(大)",
              "(8) 劇場、映画館、演芸場",
              "(9) 児童福祉施設等(通所施設等を除く)",
              "(10) 観覧場",
              "(11) ボーリング場",
              "(12) 事務所等(建築主事のいない市町建築物に限る)",
              "(13) 共同住宅(サービス付き高齢者住宅に限る)等",
              "(14) 体育館(学校に附属しないもの)等",
              "(15) 展示場、待合",
            ]}
          />
        </Grid>
        <Grid item xs={5} sx={{ mb: 1 }}>
          <InputTextFieldFireEquip key_num={"f13_ni"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const F14 = React.memo(() => {
  return (
    <div>
      【4　検査による指摘の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"f14_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxFireEquip key_num={"f14_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 1 }}></Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldMultiFireEquip key_num={"f14_ro"} />
        </Grid>
      </Grid>
    </div>
  );
});
