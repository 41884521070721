import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import {
  InputTextFieldFireEquip,
  InputTextFieldMultiFireEquip,
} from "../Parts/InputText";
import { InputDateMonthFireEquip } from "../Parts/InputDate";
import { Invest0FireEquip } from "../Parts/Invest0";
import { InvestF10 } from "../Parts/Invest1";
import { CheckFormFireEquipment } from "../Parts/CheckForm";
import { bangou, ariari, arinashi } from "../Parts/investLine";
import { COPYDATA_FIREEQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";

const FInvestFireDoor = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="検査結果表（防火扉）"
      type={"防火設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <FInvest0 />
        <Invest0FireEquip type={"f40_1_"} />
        <InvestF10 />
        <FInvest11 />
        <FInvest13 />
      </Box>
    </GenericTemplate>
  );
};

export default FInvestFireDoor;

export const FInvest0 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_FIREEQUIPMENT,
      bulding,
      page,
      page_moto: "f41",
    });
  };

  if (location.pathname !== "/finvestfiredoor/f41/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#e47373" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

const FInvest11 = React.memo(() => {
  return (
    <>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          防火扉
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          設置場所の周囲状況
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          閉鎖の障害となる物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          扉、枠及び金物
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          扉の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          扉、枠及び金物の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          危害防止装置
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          連動機構
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          煙感知器、熱煙複合式感知器及び熱感知器
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          設置位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          感知の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          温度ヒューズ装置
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          連動制御器
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          スイッチ類及び表示灯の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          結線接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          接地の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          予備電源への切り替えの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          連動機構用予備電源
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          容量の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自動閉鎖装置
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          再ロック防止機構の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          総合的な作動の状況
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火扉の閉鎖の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火区画の形成の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormFireEquipment type={"1"} num={"1"} row={"17"} />
        </Grid>
      </Grid>
    </>
  );
});

// const FInvest12 = React.memo(() => {
//   return (
//     <>
//       <Grid
//         container
//         alignItems="center"
//         sx={{ height: "57px", borderBottom: 1 }}
//       >
//         <Grid item xs={12} sx={{}}>
//           2　上記以外の検査項目
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （１）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiFireEquip key_num={"f41_1_2_10"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormFireEquipment type={"1"} num={"2"} row={"1"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （２）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiFireEquip key_num={"f41_1_2_20"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormFireEquipment type={"1"} num={"2"} row={"2"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （３）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiFireEquip key_num={"f41_1_2_30"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormFireEquipment type={"1"} num={"2"} row={"3"} />
//         </Grid>
//       </Grid>
//     </>
//   );
// });

const FInvest13 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          特記事項
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          番号
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          検査項目
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          指摘の具体的内容等
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          改善策の具体的内容等
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          改善（予定）年月
        </Grid>
      </Grid>
      <EInvest131 low={1} />
      <EInvest131 low={2} />
      <EInvest131 low={3} />
      <EInvest131 low={4} />
      <EInvest131 low={5} />
    </>
  );
});

const EInvest131 = React.memo(({ low }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{}}>
        <Grid item xs={1} sx={{ ...bangou }}>
          <InputTextFieldFireEquip key_num={`f41_1_3_${low}1`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiFireEquip key_num={`f41_1_3_${low}2`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiFireEquip key_num={`f41_1_3_${low}3`} />
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          <InputTextFieldMultiFireEquip key_num={`f41_1_3_${low}4`} />
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          <InputDateMonthFireEquip key_num1={`f41_1_3_${low}5_1`} />
        </Grid>
      </Grid>
    </>
  );
});
