import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import TextField from "@mui/material/TextField";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
} from "./Word";

export const InputTextField = React.memo(
  ({ key_num, label = "", bulding, page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;

    try {
      value = useSelector(
        (state) => state["Architecture"][bulding][page][key_num]
      );
    } catch (error) {}

    const displyaLabel = label ? label : "";
    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_ARCHITECTURE,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);

export const InputTextFieldMulti = React.memo(
  ({ key_num, label = "", bulding, page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;

    try {
      value = useSelector(
        (state) => state["Architecture"][bulding][page][key_num]
      );
    } catch (error) {}

    const displyaLabel = label ? label : "";
    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_ARCHITECTURE,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        multiline
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);

export const InputTextFieldEquip = React.memo(
  ({ key_num, bulding, label = "", page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;
    try {
      value = useSelector(
        (state) => state["Equipment"][bulding][page][key_num]
      );
    } catch (error) {}

    const displyaLabel = label ? label : "";
    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_EQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);

export const InputTextFieldMultiEquip = React.memo(
  ({ key_num, bulding, label = "", page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;
    try {
      value = useSelector(
        (state) => state["Equipment"][bulding][page][key_num]
      );
    } catch (error) {}

    const dispatch = useDispatch();
    const displyaLabel = label ? label : "";
    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_EQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        multiline
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);

export const InputTextFieldFireEquip = React.memo(
  ({ key_num, bulding, label = "", page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;
    try {
      value = useSelector(
        (state) => state["FireEquipment"][bulding][page][key_num]
      );
    } catch (error) {}
    const displyaLabel = label ? label : "";
    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_FIREEQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);

export const InputTextFieldMultiFireEquip = React.memo(
  ({ key_num, bulding, label = "", page }) => {
    const params = useParams();
    if (!bulding) {
      bulding = params.b;
    }
    if (!page) {
      page = params.page;
    }

    let value;
    try {
      value = useSelector(
        (state) => state["FireEquipment"][bulding][page][key_num]
      );
    } catch (error) {}

    const dispatch = useDispatch();
    const displyaLabel = label ? label : "";
    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_FIREEQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };
    return (
      <TextField
        fullWidth
        multiline
        value={value}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);
