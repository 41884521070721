import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";

import ja from "date-fns/locale/ja";

import TextField from "@mui/material/TextField";
import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
} from "./Word";

import "./InputDateStyle.css";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ja", ja);

export const InputDate = React.memo(
  ({ key_num1, placeholderText = "改善予定年月日" }) => {
    const params = useParams();
    const page = params.page;
    const bulding = params.b;

    let value1 = useSelector(
      (state) => state["Architecture"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_ARCHITECTURE,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );

    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM/dd"
        calendarClassName="rasta-stripes"
        customInput={<ExampleCustomInput />}
      />
    );
  }
);

export const InputDateMonth = React.memo(
  ({ key_num1, placeholderText = "改善予定年月" }) => {
    const params = useParams();
    const page = params.page;
    const bulding = params.b;

    let value1 = useSelector(
      (state) => state["Architecture"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_ARCHITECTURE,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );
    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM"
        showMonthYearPicker
        customInput={<ExampleCustomInput />}
      />
    );
  }
);

export const InputDateEquip = React.memo(
  ({ key_num1, placeholderText = "改善予定年月日" }) => {
    const params = useParams();
    const bulding = params.b;
    const page = params.page;

    let value1 = useSelector(
      (state) => state["Equipment"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_EQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );
    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM/dd"
        customInput={<ExampleCustomInput />}
      />
    );
  }
);

export const InputDateMonthEquip = React.memo(
  ({ key_num1, placeholderText = "改善予定年月" }) => {
    const params = useParams();
    const bulding = params.b;
    const page = params.page;

    let value1 = useSelector(
      (state) => state["Equipment"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_EQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );
    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM"
        showMonthYearPicker
        customInput={<ExampleCustomInput />}
      />
    );
  }
);

export const InputDateFireEquip = React.memo(
  ({ key_num1, placeholderText = "改善予定年月日" }) => {
    const params = useParams();
    const bulding = params.b;
    const page = params.page;
    let value1 = useSelector(
      (state) => state["FireEquipment"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_FIREEQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );
    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM/dd"
        customInput={<ExampleCustomInput />}
      />
    );
  }
);

export const InputDateMonthFireEquip = React.memo(
  ({ key_num1, placeholderText = "改善予定年月" }) => {
    const params = useParams();
    const bulding = params.b;
    const page = params.page;

    let value1 = useSelector(
      (state) => state["FireEquipment"][bulding][page][key_num1]
    );

    const dispatch = useDispatch();

    const change = (key, value) => {
      dispatch({
        type: CHANGE_DATA_FIREEQUIPMENT,
        payload: { [key]: value },
        bulding,
        page,
      });
    };

    const ExampleCustomInput = forwardRef(
      ({ value, onClick, onChange }, ref) => (
        <TextField
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={onChange}
          label={placeholderText}
        />
      )
    );
    return (
      <DatePicker
        locale="ja"
        selected={value1 && new Date(value1)}
        onChange={(date) => {
          var formattedDate =
            date === null ? null : date.toLocaleDateString("ja-JP");
          change(key_num1, formattedDate);
        }}
        dateFormat="yyyy/MM"
        showMonthYearPicker
        customInput={<ExampleCustomInput />}
      />
    );
  }
);
