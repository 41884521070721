import React from "react";

import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import { Grid } from "@material-ui/core";
import Box from "@mui/material/Box";

import { InputTextFieldMulti } from "../Parts/InputText";
import { InputDateMonth } from "../Parts/InputDate";

import GenericTemplate from "../GenericTemplate";

const A4 = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="第４面　建築物等に係る不具合等の状況"
      type={"特殊建築物等"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <A411 />
      <A412 row={"1"} />
      <A412 row={"2"} />
      <A412 row={"3"} />
      <A412 row={"4"} />
      <A412 row={"5"} />
    </GenericTemplate>
  );
};

export default React.memo(A4);

export const A411 = React.memo(() => {
  return (
    <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={1}>
        不具合等を把握した年月
      </Grid>
      <Grid item xs={3}>
        不具合等の概要
      </Grid>
      <Grid item xs={3}>
        考えられる原因
      </Grid>
      <Grid item xs={1}>
        改善（予定）年月
      </Grid>
      <Grid item xs={4}>
        改善措置の概要等
      </Grid>
    </Grid>
  );
});

export const A412 = React.memo(({ row }) => {
  return (
    <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={1}>
        <InputDateMonth key_num1={`a4_${row}1_1`} placeholderText={"年月"} />
      </Grid>
      <Grid item xs={3}>
        <InputTextFieldMulti key_num={`a4_${row}2`} />
      </Grid>
      <Grid item xs={3}>
        <InputTextFieldMulti key_num={`a4_${row}3`} />
      </Grid>
      <Grid item xs={1}>
        <InputDateMonth key_num1={`a4_${row}4_1`} placeholderText={"年月"} />
      </Grid>
      <Grid item xs={4}>
        <InputTextFieldMulti key_num={`a4_${row}5`} />
      </Grid>
    </Grid>
  );
});
