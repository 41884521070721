import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import GenericTemplate from "../GenericTemplate";

import { InputTextField, InputTextFieldMulti } from "../Parts/InputText";
import { InputRadio } from "../Parts/InputRadio";
import { InputDateMonth } from "../Parts/InputDate";
import { Invest0 } from "../Parts/Invest0";
import { InvestA10 } from "../Parts/Invest1";
import { CheckForm } from "../Parts/CheckForm";
import { bangou, ariari, arinashi } from "../Parts/investLine";
import { COPYDATA_ARCHITECTURE } from "../Parts/Word";

const AInvest = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="調査結果表"
      type={"特殊建築物等"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <Copy />
        <Invest0 type={"a50_"} />
        <InvestA10 />
        <AInvest11 />
        <AInvest12 />
        <AInvest13 />
        <AInvest14 />
        <AInvest15 />
        <AInvest16 />
        <AInvest18 />
        <AInvest19 />
      </Box>
    </GenericTemplate>
  );
};

export default AInvest;

export const Copy = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_ARCHITECTURE,
      bulding,
      page,
      page_moto: "a5",
    });
  };
  if (location.pathname !== "/ainvest/a5/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#a1887f" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

export const AInvest11 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          1　敷地及び地盤
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={ariari}>
          地盤
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          地盤沈下等による不陸、傾斜等の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          敷地
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          敷地内の排水の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          敷地内の通路
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          敷地内の通路の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          有効幅員の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          敷地内の通路の支障物の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          塀
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          組積造の塀又は補強コンクリートブロック造の塀等の耐震対策の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          組積造の塀又は補強コンクリートブロック造の塀等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          擁壁
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          擁壁の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          擁壁の水抜きパイプの維持保全の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"1"} row={"9"} />
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest12 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ２　建築物の外部　
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          基礎
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          基礎の沈下等の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          基礎の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          土台（木造に限る。）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          土台の沈下等の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          土台の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          外壁
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          躯体等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          外壁、軒裏及び外壁の開口部で延焼のおそれのある部分の防火対策の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          木造の外壁躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          組積造の外壁躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          補強コンクリートブロック造の外壁躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄骨造の外壁躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１０）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の外壁躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          外装仕上げ材等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          タイル、石貼り等（乾式工法によるものを除く。）、モルタル等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          乾式工法によるタイル、石貼り等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          金属系パネル（帳壁を含む。）の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          コンクリート系パネル（帳壁を含む。）の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          窓サッシ等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          サッシ等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          はめ殺し窓のガラスの固定の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          外壁に緊結された広告板、空調室外機等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          機器本体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１８）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          支持部分等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"2"} row={"18"} />
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest13 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ３　屋上及び屋根　
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋上面
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋上面の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          屋上周り（屋上面を除く。）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          パラペットの立上り面の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          笠木モルタル等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          金属笠木の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排水溝（ドレーンを含む。）の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          屋根
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋根の防火対策の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋根の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          機器及び工作物（冷却塔設備、広告塔等）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          機器、工作物本体及び接合部の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          支持部分等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"3"} row={"9"} />
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest14 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ４　建築物の内部
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          防火区画
        </Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          令第112条第11項から第13項までに規定する区画の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          令第112条第1項、第4項、第5項又は第7項から第10項までの各項に規定する区画の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          令第112条第18項に規定する区画の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          防火区画の外周部
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          令第112条第16項に規定する外壁等及び同条第17項に規定する防火設備の処置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          令第112条第16項に規定する外壁等及び同条第17項に規定する防火設備の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          壁の室内に面する部分
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          躯体等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          木造の壁の室内に面する部分の躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          組積造の壁の室内に面する部分の躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          補強コンクリートブロック造の壁の室内に面する部分の躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄骨造の壁の室内に面する部分の躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１０）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の壁の室内に面する部分の躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          耐火構造の壁又は準耐火構造の壁（防火区画を構成する壁等に限る。）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          準耐火性能等の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          部材の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄骨の耐火被覆の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給水管、配電管その他の管又は風道の区画貫通部の充填等の処理の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          令第114条に規定する界壁、間仕切壁及び隔壁
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          令第114条に規定する界壁、間仕切壁及び隔壁の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１６）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          令第128条の5各項に規定する建築物の壁の室内に面する部分
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          室内に面する部分の仕上げの維持保全の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          床
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          躯体等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          木造の床躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄骨造の床躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１９）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          鉄筋コンクリート造及び鉄骨鉄筋コンクリート造の床躯体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２０）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          耐火構造の床又は準耐火構造の床（防火区画を構成する床に限る。）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          準耐火性能等の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          部材の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"21"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２２）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給水管、配電管その他の管又は風道の区画貫通部の充填等の処理の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"22"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          天井
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          令第128条の5各項に規定する建築物の天井の室内に面する部分
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          室内に面する部分の仕上げの維持保全の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"23"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          室内に面する部分の仕上げの劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"24"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２５）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          特定天井
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          特定天井の天井材の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"25"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２６）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          防火設備（防火扉、防火シャッターその他これらに類するものに限る。）又は戸
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          区画に対応した防火設備又は戸の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"26"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２７）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          居室から地上へ通じる主たる廊下、階段その他の通路に設置された防火設備又は戸におけるくぐり戸の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"27"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２８）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          昭和48年建設省告示第2563号第１第１号ロに規定する基準への適合の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"28"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２９）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火扉又は戸の開放方向
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"29"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３０）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          常閉防火設備等の本体と枠の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"30"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３１）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          常閉防火設備等の閉鎖又は作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"31"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３２）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          常閉防火設備等の閉鎖又は作動の障害となる物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"32"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３３）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          常閉防火扉等の固定の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"33"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３４）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          照明器具、懸垂物等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          照明器具、懸垂物等の落下防止対策の状況　
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"34"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３５）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火設備又は戸の閉鎖の障害となる照明器具、懸垂物等の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"35"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３６）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          警報設備
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          警報設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"36"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３７）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          警報設備の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"37"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３８）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          居室の採光及び換気
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          採光のための開口部の面積の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"38"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３９）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          採光の妨げとなる物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"39"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４０）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          換気のための開口部の面積の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"40"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４１）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          換気設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"41"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４２）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          換気設備の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"42"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４３）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          換気の妨げとなる物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"43"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          石綿等を添加した建築材料　
        </Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          吹付け石綿及び吹付けロックウールでその含有する石綿の重量が当該建築材料の重量の0.1パーセントを超えるもの（以下「吹付け石綿等」という。）の使用の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"44"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          吹付け石綿等の劣化の状況　
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"45"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          除去又は囲い込み若しくは封じ込めによる飛散防止措置の実施の状況　
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"46"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４７）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={5} sx={{ ...ariari }}>
          囲い込み又は封じ込めによる飛散防止措置の劣化及び損傷の状況　
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"4"} row={"47"} />
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest15 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ５　避難施設等
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          令第120条第２項に規定する通路
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          令第120条第２項に規定する通路の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          廊下
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          幅員の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          出入口
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          出入口の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋上広場
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋上広場の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          避難上有効なバルコニー
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          避難上有効なバルコニーの確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手すり等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１０）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          避難器具の操作性の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          階段
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          階段
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          直通階段の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          幅員の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手すりの設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          階段各部の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          屋内に設けられた避難階段
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          階段室の構造の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          屋外に設けられた避難階段
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋内と階段との間の防火区画の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          開放性の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１９）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特別避難階段
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          バルコニー又は付室の構造及び面積の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２０）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          付室等の排煙設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          付室等の排煙設備の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"21"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          付室等の外気に向かって開くことができる窓の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"22"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２３）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"23"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          排煙設備等
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          防煙壁
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防煙区画の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"24"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防煙壁の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"25"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          可動式防煙壁の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"26"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          排煙設備
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"27"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙設備の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"28"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２９）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の維持保全の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"29"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３０）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          その他の設備等
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          非常用の進入口等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          非常用の進入口等の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"30"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          非常用の進入口等の維持保全の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"31"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          非常用エレベーター
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          乗降ロビーの構造及び面積の確保の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"32"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          乗降ロビー等の排煙設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"33"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３４）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          乗降ロビー等の排煙設備の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"34"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３５）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          乗降ロビーの付室の外気に向かって開くことができる窓の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"35"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"36"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          非常用エレベーターの作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"37"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          非常用の照明装置
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          非常用の照明装置の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"38"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３９）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          非常用の照明装置の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"39"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４０）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          照明の妨げとなる物品の放置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"5"} row={"40"} />
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest16 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ６　その他
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          特殊な構造等
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          膜構造建築物の膜体、取付部材等
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          膜体及び取付部材の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          膜張力及びケーブル張力の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          免震構造建築物の免震層及び免震装置
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          免震装置の劣化及び損傷の状況（免震装置が可視状態にある場合に限る。）
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          上部構造の可動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （５）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          避雷設備
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          避雷針、避雷導線等の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （６）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          煙突
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          建築物に設ける煙突
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          煙突本体及び建築物との接合部の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （７）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          付帯金物の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （８）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          令第138条第１項第１号に掲げる煙突
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          煙突本体の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （９）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          付帯金物の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckForm num={"6"} row={"9"} />
        </Grid>
      </Grid>
    </>
  );
});

// export const AInvest17 = React.memo(() => {
//   return (
//     <>
//       <Grid
//         container
//         alignItems="center"
//         sx={{ height: "57px", borderBottom: 1 }}
//       >
//         <Grid item xs={12} sx={{}}>
//           ７　上記以外の調査項目
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （１）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMulti key_num={"a51_7_10"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckForm num={"7"} row={"1"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （２）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMulti key_num={"a51_7_20"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckForm num={"7"} row={"2"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （３）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMulti key_num={"a51_7_30"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckForm num={"7"} row={"3"} />
//         </Grid>
//       </Grid>
//     </>
//   );
// });

export const AInvest18 = React.memo(() => {
  return (
    <>
      <Grid container alignItems="center" sx={{ height: "77px" }}>
        <Grid item xs={12} sx={{ height: "57px" }}>
          その他の確認事項
        </Grid>
        <Grid item xs={12} sx={{ height: "20px" }}>
          法第12条第3項の規定による検査を要する防火設備の有無
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12}>
          <InputRadio key_num={"a51_8_11"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={5}>
                <InputTextField key_num={"a51_8_12"} label={"階"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </>
  );
});

export const AInvest19 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          特記事項
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          番号
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          調査項目
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          指摘の具体的内容等
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          改善策の具体的内容等
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          改善（予定）年月
        </Grid>
      </Grid>
      <AInvest191 low={1} />
      <AInvest191 low={2} />
      <AInvest191 low={3} />
      <AInvest191 low={4} />
      <AInvest191 low={5} />
    </>
  );
});

export const AInvest191 = React.memo(({ low }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{}}>
        <Grid item xs={1} sx={{ ...bangou }}>
          <InputTextField key_num={`a51_9_${low}1`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMulti key_num={`a51_9_${low}2`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMulti key_num={`a51_9_${low}3`} />
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          <InputTextFieldMulti key_num={`a51_9_${low}4`} />
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          <InputDateMonth key_num1={`a51_9_${low}5_1`} />
        </Grid>
      </Grid>
    </>
  );
});

// const Caution = React.memo(() => {
//   return (
//     <div>
//       <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
//         <Grid item xs={12}>
//           （注意）
//         </Grid>
//         <Grid item xs={12}>
//           ①　この書類は、特殊建築物等ごとに作成してください。
//         </Grid>
//         <Grid item xs={12}>
//           ②　記入欄が不足する場合は、枠を拡大、行を追加して記入するか、別紙に必要な事項を記入して添えてください。
//         </Grid>
//         <Grid item xs={12}>
//           ③　「当該調査に関与した調査者」欄は、建築基準法施行規則別記第36の２様式第一面３欄に記入した調査者について記入し、「調査者番号」欄に調査者を特定できる番号、記号等を記入してください。当該建築物の調査を行った調査者が１人の場合は、その他の調査者欄は削除して構いません。
//         </Grid>
//         <Grid item xs={12}>
//           ④　該当しない調査項目がある場合は、当該項目の「番号」欄から「担当調査者番号」欄までを取消線で抹消してください。
//         </Grid>
//         <Grid item xs={12}>
//           ⑤　「調査結果」欄は、別表（い）欄に掲げる各調査項目ごとに記入してください。
//         </Grid>
//         <Grid item xs={12}>
//           ⑥　「調査結果」欄のうち「要是正」欄は、別表（い）欄に掲げる調査項目について（は）欄に掲げる判定基準に該当する場合に○印を記入してください。
//         </Grid>
//         <Grid item xs={12}>
//           ⑦　「検査結果」欄のうち「指摘なし」欄は、⑥に該当しない場合に○印を記入してください。
//         </Grid>
//         <Grid item xs={12}>
//           ⑧　「既存不適格」欄は、「要是正」欄に○印を記入した場合で、建築基準法第３条第２項の規定の適用を受けているものであることが確認されたときは、○印を記入してください。
//         </Grid>
//       </Grid>
//       <Grid item xs={12}>
//         ⑨　「担当調査者番号」欄は、「調査に関与した調査者」欄で記入した番号、記号等を記入してください。ただし、当該建築物の調査を行った調査者が１人の場合は、記入しなくても構いません。
//       </Grid>
//       <Grid item xs={12}>
//         ⑩　７「上記以外の調査項目」欄は、第１ただし書の規定により特定行政庁が調査項目を追加したときに、特定行政庁が追加した調査項目を追加し、⑤から⑧に準じて調査結果等を記入してください。なお、これらの項目がない場合は、７は削除して構いません。
//       </Grid>
//       <Grid item xs={12}>
//         ⑪　「その他確認事項」は、法第12条第3項の規定による検査を要する随時閉鎖又は作動ができる防火設備の設置の有無を確認し、該当するチェックボックスに「レ」マークを入れてください。「有」の場合は、当該防火設備が設置されている階を記入してください。
//       </Grid>
//       <Grid item xs={12}>
//         ⑫　「特記事項」は、調査の結果、要是正の指摘があった場合のほか、指摘がない場合にあっても特記すべき事項がある場合に、該当する調査項目の番号、調査項目を記入し、「指摘の具体的内容等」欄に指摘又は特記すべき事項の具体的内容を記入するとともに、改善済みの場合及び改善策が明らかになっている場合は「改善策の具体的内容等」欄にその内容を記入し、改善した場合は「改善（予定）年月」欄に当該年月を記入し、改善予定年月が明らかになっている場合は「改善（予定）年月」欄に当該年月を（　）書きで記入してください。
//       </Grid>
//       <Grid item xs={12}>
//         ⑬　配置図及び各階平面図を別添１の様式に従い添付し、指摘(特記すべき事項を含む）のあった箇所や撮影した写真の位置等を明記してください。
//       </Grid>
//       <Grid item xs={12}>
//         ⑭　要是正とされた調査項目（既存不適格の場合を除く。）については、要是正とされた部分を撮影した写真を別添２の様式に従い添付してください。
//       </Grid>
//     </div>
//   );
// });
