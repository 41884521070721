export const CheackBoxKeys = [
  "e1.e14_i2",
  "e2.e21_ni1",
  "e2.e21_ni2",
  "e2.e21_ni3",
  "e2.e23_ha",
  "e2.e25_i1_1",
  "e2.e25_i2_1",
  "e2.e25_i3_1",
  "e2.e25_i4_1",
  "e2.e25_i5",
  "e2.e25_ro1_1",
  "e2.e25_ro2_1",
  "e2.e25_ro3_1",
  "e2.e25_ro4",
  "e2.e25_ha1_1",
  "e2.e25_ha2_1",
  "e2.e25_ha3_1",
  "e2.e25_ha4_1",
  "e2.e25_ha5",
  "e2.e26_i2",
  "e2.e29_i1_1",
  "e2.e29_i2_1",
  "e2.e29_i3_1",
  "e2.e29_i4_1",
  "e2.e29_ro1_1",
  "e2.e29_ro2_1",
  "e2.e29_ro3_1",
  "e2.e29_ro4_1",
  "e2.e29_ha1_1",
  "e2.e29_ha2_1",
  "e2.e29_ha3_1",
  "e2.e29_ha4_1",
  "e2.e29_ni1_1",
  "e2.e29_ni2_1",
  "e2.e29_ni3_1",
  "e2.e29_ni4_1",
  "e2.e29_ho1_1",
  "e2.e29_ho2_1",
  "e2.e29_ho3_1",
  "e2.e29_he1_1",
  "e2.e29_he2_1",
  "e2.e29_he3_1",
  "e2.e29_he4_1",
  "e2.e210_i2",
  "e2.e213_i1_1",
  "e2.e213_i2_1",
  "e2.e213_i3_1",
  "e2.e213_i4_1",
  "e2.e213_ro1_1",
  "e2.e213_ro2_1",
  "e2.e213_ro3_1",
  "e2.e213_ro4_1",
  "e2.e213_ro5_1",
  "e2.e214_i2",
  "e41.e41_1_1_12",
  "e41.e41_1_1_22",
  "e41.e41_1_1_32",
  "e41.e41_1_1_42",
  "e41.e41_1_1_52",
  "e41.e41_1_1_62",
  "e41.e41_1_1_72",
  "e41.e41_1_1_82",
  "e41.e41_1_1_92",
  "e41.e41_1_1_102",
  "e41.e41_1_1_112",
  "e41.e41_1_1_122",
  "e41.e41_1_1_132",
  "e41.e41_1_1_142",
  "e41.e41_1_1_152",
  "e41.e41_1_1_162",
  "e41.e41_1_1_172",
  "e41.e41_1_1_182",
  "e41.e41_1_1_192",
  "e41.e41_1_1_202",
  "e41.e41_1_1_212",
  "e41.e41_1_2_12",
  "e41.e41_1_2_22",
  "e41.e41_1_2_32",
  "e41.e41_1_2_42",
  "e41.e41_1_2_52",
  "e41.e41_1_2_62",
  "e41.e41_1_2_72",
  "e41.e41_1_2_82",
  "e41.e41_1_2_92",
  "e41.e41_1_2_102",
  "e41.e41_1_2_112",
  "e41.e41_1_2_122",
  "e41.e41_1_2_132",
  "e41.e41_1_3_12",
  "e41.e41_1_3_22",
  "e41.e41_1_3_32",
  "e41.e41_1_3_42",
  "e41.e41_1_3_52",
  "e41.e41_1_3_62",
  "e41.e41_1_3_72",
  "e41.e41_1_3_82",
  "e41.e41_1_3_92",
  "e41.e41_1_4_12",
  "e41.e41_1_4_22",
  "e41.e41_1_4_32",
  "e42.e41_2_1_12",
  "e42.e41_2_1_22",
  "e42.e41_2_1_32",
  "e42.e41_2_1_42",
  "e42.e41_2_1_52",
  "e42.e41_2_1_62",
  "e42.e41_2_1_72",
  "e42.e41_2_1_82",
  "e42.e41_2_1_92",
  "e42.e41_2_1_102",
  "e42.e41_2_1_112",
  "e42.e41_2_1_122",
  "e42.e41_2_1_132",
  "e42.e41_2_1_142",
  "e42.e41_2_1_152",
  "e42.e41_2_1_162",
  "e42.e41_2_1_172",
  "e42.e41_2_1_182",
  "e42.e41_2_1_192",
  "e42.e41_2_1_202",
  "e42.e41_2_1_212",
  "e42.e41_2_1_222",
  "e42.e41_2_1_232",
  "e42.e41_2_1_242",
  "e42.e41_2_1_252",
  "e42.e41_2_1_262",
  "e42.e41_2_1_272",
  "e42.e41_2_1_282",
  "e42.e41_2_1_292",
  "e42.e41_2_1_302",
  "e42.e41_2_1_312",
  "e42.e41_2_1_322",
  "e42.e41_2_1_332",
  "e42.e41_2_1_342",
  "e42.e41_2_1_352",
  "e42.e41_2_1_362",
  "e42.e41_2_1_372",
  "e42.e41_2_1_382",
  "e42.e41_2_1_392",
  "e42.e41_2_1_402",
  "e42.e41_2_1_412",
  "e42.e41_2_1_422",
  "e42.e41_2_1_432",
  "e42.e41_2_1_442",
  "e42.e41_2_1_452",
  "e42.e41_2_1_462",
  "e42.e41_2_1_472",
  "e42.e41_2_1_482",
  "e42.e41_2_1_492",
  "e42.e41_2_1_502",
  "e42.e41_2_1_512",
  "e42.e41_2_1_522",
  "e42.e41_2_1_532",
  "e42.e41_2_2_12",
  "e42.e41_2_2_22",
  "e42.e41_2_2_32",
  "e42.e41_2_2_42",
  "e42.e41_2_2_52",
  "e42.e41_2_2_62",
  "e42.e41_2_2_72",
  "e42.e41_2_2_82",
  "e42.e41_2_2_92",
  "e42.e41_2_2_102",
  "e42.e41_2_2_112",
  "e42.e41_2_2_122",
  "e42.e41_2_2_132",
  "e42.e41_2_2_142",
  "e42.e41_2_2_152",
  "e42.e41_2_2_162",
  "e42.e41_2_2_172",
  "e42.e41_2_2_182",
  "e42.e41_2_2_192",
  "e42.e41_2_2_202",
  "e42.e41_2_2_212",
  "e42.e41_2_2_222",
  "e42.e41_2_2_232",
  "e42.e41_2_2_242",
  "e42.e41_2_2_252",
  "e42.e41_2_2_262",
  "e42.e41_2_2_272",
  "e42.e41_2_2_282",
  "e42.e41_2_2_292",
  "e42.e41_2_2_302",
  "e42.e41_2_2_312",
  "e42.e41_2_2_322",
  "e42.e41_2_3_12",
  "e42.e41_2_3_22",
  "e42.e41_2_3_32",
  "e42.e41_2_3_42",
  "e42.e41_2_3_52",
  "e42.e41_2_3_62",
  "e42.e41_2_4_12",
  "e42.e41_2_4_22",
  "e42.e41_2_4_32",
  "e42.e41_2_4_42",
  "e42.e41_2_4_52",
  "e42.e41_2_4_62",
  "e42.e41_2_4_72",
  "e42.e41_2_4_82",
  "e42.e41_2_4_92",
  "e42.e41_2_4_102",
  "e42.e41_2_4_112",
  "e42.e41_2_4_122",
  "e42.e41_2_4_132",
  "e42.e41_2_4_142",
  "e42.e41_2_4_152",
  "e42.e41_2_4_162",
  "e42.e41_2_4_172",
  "e42.e41_2_4_182",
  "e42.e41_2_4_192",
  "e42.e41_2_4_202",
  "e42.e41_2_4_212",
  "e42.e41_2_4_222",
  "e42.e41_2_4_232",
  "e42.e41_2_4_242",
  "e42.e41_2_4_252",
  "e42.e41_2_4_262",
  "e42.e41_2_5_12",
  "e42.e41_2_5_22",
  "e42.e41_2_5_32",
  "e43.e41_3_1_12",
  "e43.e41_3_1_22",
  "e43.e41_3_2_12",
  "e43.e41_3_2_22",
  "e43.e41_3_2_32",
  "e43.e41_3_2_42",
  "e43.e41_3_3_12",
  "e43.e41_3_3_22",
  "e43.e41_3_3_32",
  "e43.e41_3_3_42",
  "e43.e41_3_3_52",
  "e43.e41_3_3_62",
  "e43.e41_3_4_12",
  "e43.e41_3_4_22",
  "e43.e41_3_5_12",
  "e43.e41_3_5_22",
  "e43.e41_3_5_32",
  "e43.e41_3_5_42",
  "e43.e41_3_5_52",
  "e43.e41_3_5_62",
  "e43.e41_3_5_72",
  "e43.e41_3_5_82",
  "e43.e41_3_6_12",
  "e43.e41_3_6_22",
  "e43.e41_3_6_32",
  "e43.e41_3_6_42",
  "e43.e41_3_6_52",
  "e43.e41_3_6_62",
  "e43.e41_3_6_72",
  "e43.e41_3_6_82",
  "e43.e41_3_6_92",
  "e43.e41_3_6_102",
  "e43.e41_3_6_112",
  "e43.e41_3_6_122",
  "e43.e41_3_6_132",
  "e43.e41_3_6_142",
  "e43.e41_3_6_152",
  "e43.e41_3_6_162",
  "e43.e41_3_6_172",
  "e43.e41_3_7_12",
  "e43.e41_3_7_22",
  "e43.e41_3_7_32",
];

export const DateKeys = [
  "e0.e01_ho_1",
  "e1.e14_ha2_1",
  "e2.e22_i_1",
  "e2.e22_ha1_1",
  "e2.e23_i1_1",
  "e2.e23_ro2_1",
  "e2.e26_ha2_1",
  "e2.e27_ha2_1",
  "e2.e210_ha2_1",
  "e2.e211_ha2_1",
  "e2.e214_ha2_1",
  "e2.e215_ha2_1",
  "e3.e3_1_11_1",
  "e3.e3_1_14_1",
  "e3.e3_1_21_1",
  "e3.e3_1_24_1",
  "e3.e3_1_34_1",
  "e3.e3_2_11_1",
  "e3.e3_2_14_1",
  "e3.e3_2_21_1",
  "e3.e3_2_24_1",
  "e3.e3_2_31_1",
  "e3.e3_2_34_1",
  "e3.e3_3_11_1",
  "e3.e3_3_14_1",
  "e3.e3_3_21_1",
  "e3.e3_3_24_1",
  "e3.e3_3_31_1",
  "e3.e3_3_34_1",
  "e41.e41_1_5_15_1",
  "e41.e41_1_5_25_1",
  "e41.e41_1_5_35_1",
  "e41.e41_1_5_45_1",
  "e41.e41_1_5_55_1",
  "e42.e41_2_6_15_1",
  "e42.e41_2_6_25_1",
  "e42.e41_2_6_35_1",
  "e42.e41_2_6_45_1",
  "e42.e41_2_6_55_1",
  "e43.e41_3_8_15_1",
  "e43.e41_3_8_25_1",
  "e43.e41_3_8_35_1",
  "e43.e41_3_8_45_1",
  "e43.e41_3_8_55_1",
];
