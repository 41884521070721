import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import {
  InputTextFieldEquip,
  InputTextFieldMultiEquip,
} from "../Parts/InputText";
import { InputDateMonthEquip } from "../Parts/InputDate";
import { Invest0Equip } from "../Parts/Invest0";
import { InvestE10 } from "../Parts/Invest1";
import { CheckFormEquipment } from "../Parts/CheckForm";
import { bangou, ariari, arinashi } from "../Parts/investLine";
import { COPYDATA_EQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";

const EInvestSmoke = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="検査結果表（排煙設備）"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <EInvest0 />
        <Invest0Equip type={"e40_2_"} />
        <InvestE10 />
        <EInvest11 />
        <EInvest12 />
        <EInvest13 />
        <EInvest14 />
        <EInvest16 />
      </Box>
    </GenericTemplate>
  );
};

export default EInvestSmoke;

export const EInvest0 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_EQUIPMENT,
      bulding,
      page,
      page_moto: "e42",
    });
  };

  if (location.pathname !== "/einvestsmoke/e42/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#81c783" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

const EInvest11 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          1　令第123条第3項第2号に規定する階段室又は付室、令第129条の13の3第13項に規定する昇降路又は乗降ロビー、令第126条の2第1項に規定
          する居室等
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          排煙機
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          排煙機の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙機の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道との接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各排煙出口の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙出口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋外に設置された煙排出口への雨水等の防止措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          排煙機の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の開放との連動起動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電源を必要とする排煙機の予備電源による作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙機の排煙風量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          排煙口
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械排煙設備の排煙口の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動開放装置の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動開放装置の操作方法の表示の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械排煙設備の排煙口の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動開放装置による開放の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の開放の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （18）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の排煙風量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （19）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （20）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          煙感知器による作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （21）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          排煙風道
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械排煙設備の排煙風道(隠蔽部分及び 埋設部分を除く。)
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"21"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （22）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"22"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （23）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"23"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （24）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防煙壁の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"24"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （25）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道と可燃物、電線等との離隔距離及び断熱の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"25"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （26）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          防火ダンパー(外壁の 開口部で延焼のおそ れのある部分に設け
          るものを除く。)
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火ダンパーの取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"26"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （27）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火ダンパーの作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"27"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （28）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火ダンパーの劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"28"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （29）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火ダンパーの点検口の有無及び大きさ並びに検査口の有無
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"29"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （30）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防火ダンパーの温度ヒューズ
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"30"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （31）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          壁及び床の防火区画貫通部の措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"31"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （32）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          特殊な構造の排煙設備
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設 備の排煙口及び給気 口の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口及び給気口の大きさ及び位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"32"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （33）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口及び給気口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"33"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （34）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口及び給気口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"34"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （35）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動開放装置の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"35"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （36）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動開放装置の操作方法の表示の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"36"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （37）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設備の排煙口の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の排煙風量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"37"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （38）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"38"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （39）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          煙感知器による作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"39"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （40）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設備の給気風道(隠蔽部分及び埋設部分を除く。)
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"40"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （41）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"41"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （42）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"42"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （43）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          防煙壁の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"43"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （44）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設備の給気送風機の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気送風機の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"44"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （45）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道との接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"45"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （46）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設備の給気送風機の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙口の開放と連動起動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"46"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （47）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"47"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （48）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電源を必要とする給気送風機の予備電源による作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"48"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （49）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気送風機の給気風量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"49"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （50）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"50"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （51）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          特殊な構造の排煙設備の給気送風機の吸込口
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          吸込口の設置位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"51"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （52）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          吸込口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"52"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （53）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋外に設置された吸込口への雨水等の防止措置の状況˚
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"1"} row={"53"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest12 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          2　令第123条第3項第2号に規定する階段室又は付室、令第129条の13の3第13項に規定する昇降路又は乗降ロビー
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          特別避難階段の階段室又は付室及び非常用エレベーターの昇降路又
          は乗降ロビーに設ける排煙口及び給気口
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙機、排煙口及び給気口の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          加圧防排煙設備
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          排煙風道(隠蔽部分及び埋設部分を除 く。)
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排煙風道の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気口の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の手動開放装置の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の手動開放装置の操作方法の表示の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気口の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の手動開放装置による開放の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の開放の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気風道(穏蔽部分及び埋設部分を除く。)
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気送風機の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気送風機の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気風道との接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気送風機の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気口の開放と連動起動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （18）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気送風機の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （19）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電源を必要とする給気送風機の予備電源による作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （20）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （21）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          給気送風機の吸込口
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          吸込口の設置位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"21"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （22）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          吸込口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"22"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （23）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          屋外に設置された吸込口への雨水等の防止措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"23"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （24）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          遮煙開口部の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          遮煙開口部の排出風速
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"24"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （25）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          空気逃し口の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気逃し口の大きさ及び位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"25"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （26）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気逃し口の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"26"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （27）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気逃し口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"27"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （28）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          空気逃し口の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気逃し口の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"28"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （29）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          圧力調整装置の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          圧力調整装置の大きさ及び位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"29"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （30）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          圧力調整装置の周囲の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"30"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （31）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          圧力調整装置の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"31"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （32）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          圧力調整装置の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          圧力調整装置の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"2"} row={"32"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest13 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          3　令第126条の2第1項に規定する居室等
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}>
          可動防煙壁
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動降下装置の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          手動降下装置による連動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          煙感知器による連動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          可動防煙壁の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={3} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          可動防煙壁の防煙区画
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"3"} row={"6"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest14 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          4　予備電源
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          自家用発電装置
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自家用発電装置等の状況
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電機室の防火区画等の貫通措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          発電機の発電容量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          発電機及び原動機の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          燃料油、潤滑油及び冷却水の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          始動用の空気槽の圧力
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          セル始動用蓄電池及び電気ケーブルの接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          燃料及び冷却水の漏洩の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          計器類及びランプ類の指示及び点灯の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電装置の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          自家用発電機室の給排気の状況(屋内に設置されている場合に限る。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          接地線の接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          絶縁抵抗
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自家用発電装置の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          電源の切替えの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          始動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          運転の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          排気の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          コンプレッサー、燃料ポンプ、冷却水ポンプ等の補機類の作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （18）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          直結エンジン
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          直結エンジンの外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          直結エンジンの設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （19）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          燃料油、潤滑油及び冷却水の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （20）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          セル始動用蓄電池及び電気ケーブルの接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （21）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          計器類及びランプ類の指示及び点灯の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"21"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （22）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気部及び排気管の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"22"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （23）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          Vベルト
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"23"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （24）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          接地線の接続の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"24"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （25）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          絶縁抵抗
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"25"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （26）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          直結エンジンの性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          始動及び停止並びに運転の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"2"} num={"4"} row={"26"} />
        </Grid>
      </Grid>
    </>
  );
});

// const EInvest15 = React.memo(() => {
//   return (
//     <>
//       <Grid
//         container
//         alignItems="center"
//         sx={{ height: "57px", borderBottom: 1 }}
//       >
//         <Grid item xs={12} sx={{}}>
//           5　上記以外の検査項目
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （１）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_2_5_10"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"2"} num={"5"} row={"1"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （２）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_2_5_20"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"2"} num={"5"} row={"2"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （３）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_2_5_30"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"2"} num={"5"} row={"3"} />
//         </Grid>
//       </Grid>
//     </>
//   );
// });

const EInvest16 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          特記事項
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          番号
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          検査項目
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          指摘の具体的内容等
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          改善策の具体的内容等
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          改善（予定）年月
        </Grid>
      </Grid>
      <EInvest161 low={1} />
      <EInvest161 low={2} />
      <EInvest161 low={3} />
      <EInvest161 low={4} />
      <EInvest161 low={5} />
    </>
  );
});

const EInvest161 = React.memo(({ low }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{}}>
        <Grid item xs={1} sx={{ ...bangou }}>
          <InputTextFieldEquip key_num={`e41_2_6_${low}1`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_2_6_${low}2`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_2_6_${low}3`} />
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_2_6_${low}4`} />
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          <InputDateMonthEquip key_num1={`e41_2_6_${low}5_1`} />
        </Grid>
      </Grid>
    </>
  );
});
