export const equipmentDataSet = {
  e0: {
    e01_i1: "",
    e01_i2: "",
    e01_ro1: "",
    e01_ro2: 1, //棟数
    e01_ha: "",
    e01_ni: "",
    e01_ho_1: null,

    e02_i1: "",
    e02_i2: "",
    e02_ro1: "",
    e02_ro2: "",
  },
  e1: {
    e11_i: "",
    e11_ro: "",
    e11_ha: "",
    e11_ni: "",
    e11_ho: "",

    e12_i: "",
    e12_ro: "",
    e12_ha: "",
    e12_ni: "",
    e12_ho: "",

    e13_i: "",
    e13_ro: "",
    e13_ha: "",
    e13_ni: "",

    e14_i1: "",
    e14_i2: false,
    e14_ro: "",
    e14_ha1: "",
    e14_ha2_1: null,

    e14_ni: "",
  },
  e2: {
    e21_i1: "",
    e21_i2: "",
    e21_ro: "",
    e21_ha: "",
    e21_ni1: false,
    e21_ni2: false,
    e21_ni3: false,

    e22_i1_1: null,
    e22_i2: "",
    e22_ro1: "",
    e22_ro2: "",
    e22_ha1_1: null,

    e22_ha2: "",
    e22_ni1: "",
    e22_ni2: "",

    e23_i_1: null,
    e23_ro1: "",
    e23_ro2_1: null,
    e23_ha: false,

    e24_ai1: "一級建築士",
    e24_ai2: "大臣登録",
    e24_ai3: "",
    e24_ai4: "",
    e24_aro: "",
    e24_aha: "",
    e24_ani1: "",
    e24_ani2: "一級建築士事務所",
    e24_ani3: "",
    e24_ani4: "",
    e24_aho: "",
    e24_ahe: "",
    e24_ato: "",
    e24_bi1: "一級建築士",
    e24_bi2: "大臣登録",
    e24_bi3: "",
    e24_bi4: "",
    e24_bro: "",
    e24_bha: "",
    e24_bni1: "",
    e24_bni2: "一級建築士事務所",
    e24_bni3: "",
    e24_bni4: "",
    e24_bho: "",
    e24_bhe: "",
    e24_bto: "",

    e25_i1_1: false,
    e25_i1_2: "",
    e25_i2_1: false,
    e25_i2_2: "",
    e25_i3_1: false,
    e25_i3_2: "",
    e25_i4_1: false,
    e25_i4_2: "",
    e25_i5: false,

    e25_ro1_1: false,
    e25_ro1_2: "",
    e25_ro2_1: false,
    e25_ro2_2: "",
    e25_ro3_1: false,
    e25_ro3_2: "",
    e25_ro4: false,

    e25_ha1_1: false,
    e25_ha1_2: "",
    e25_ha2_1: false,
    e25_ha2_2: "",
    e25_ha3_1: false,
    e25_ha3_2: "",
    e25_ha4_1: false,
    e25_ha4_2: "",
    e25_ha5: false,

    e25_ni: "",

    e26_i1: "",
    e26_i2: false,
    e26_ro: "",
    e26_ha1: "",
    e26_ha2_1: null,

    e27_i: "",
    e27_ro: "",
    e27_ha1: "",
    e27_ha2_1: null,

    e28_ai1: "一級建築士",
    e28_ai2: "大臣登録",
    e28_ai3: "",
    e28_ai4: "",
    e28_aro: "",
    e28_aha: "",
    e28_ani1: "",
    e28_ani2: "一級建築士事務所",
    e28_ani3: "",
    e28_ani4: "",
    e28_aho: "",
    e28_ahe: "",
    e28_ato: "",
    e28_bi1: "一級建築士",
    e28_bi2: "大臣登録",
    e28_bi3: "",
    e28_bi4: "",
    e28_bro: "",
    e28_bha: "",
    e28_bni1: "",
    e28_bni2: "一級建築士事務所",
    e28_bni3: "",
    e28_bni4: "",
    e28_bho: "",
    e28_bhe: "",
    e28_bto: "",

    e29_i1_1: false,
    e29_i1_2: "",
    e29_i2_1: false,
    e29_i2_2: "",
    e29_i3_1: false,
    e29_i4_1: false,
    e29_i4_2: "",
    e29_ro1_1: false,
    e29_ro1_2: "",
    e29_ro2_1: false,
    e29_ro2_2: "",
    e29_ro3_1: false,
    e29_ro3_2: "",
    e29_ro4_1: false,
    e29_ha1_1: false,
    e29_ha1_2: "",
    e29_ha2_1: false,
    e29_ha2_2: "",
    e29_ha3_1: false,
    e29_ha3_2: "",
    e29_ha4_1: false,
    e29_ni1_1: false,
    e29_ni1_2: "",
    e29_ni2_1: false,
    e29_ni2_2: "",
    e29_ni3_1: false,
    e29_ni3_2: "",
    e29_ni4_1: false,
    e29_ho1_1: false,
    e29_ho1_2: "",
    e29_ho2_1: false,
    e29_ho2_2: "",
    e29_ho3_1: false,
    e29_he1_1: false,
    e29_he2_1: false,
    e29_he3_1: false,
    e29_he4_1: false,
    e29_he4_2: "",

    e210_i1: "",
    e210_i2: false,
    e210_ro: "",
    e210_ha1: "",
    e210_ha2_1: null,

    e211_i: "",
    e211_ro: "",
    e211_ha1: "",
    e211_ha2_1: null,

    e212_ai1: "一級建築士",
    e212_ai2: "大臣登録",
    e212_ai3: "",
    e212_ai4: "",
    e212_aro: "",
    e212_aha: "",
    e212_ani1: "",
    e212_ani2: "一級建築士事務所",
    e212_ani3: "",
    e212_ani4: "",
    e212_aho: "",
    e212_ahe: "",
    e212_ato: "",
    e212_bi1: "一級建築士",
    e212_bi2: "大臣登録",
    e212_bi3: "",
    e212_bi4: "",
    e212_bro: "",
    e212_bha: "",
    e212_bni1: "",
    e212_bni2: "一級建築士事務所",
    e212_bni3: "",
    e212_bni4: "",
    e212_bho: "",
    e212_bhe: "",
    e212_bto: "",

    e213_i1_1: false,
    e213_i1_2: "",
    e213_i2_1: false,
    e213_i2_2: "",
    e213_i3_1: false,
    e213_i3_2: "",
    e213_i4_1: false,
    e213_i4_2: "",
    e213_ro1_1: false,
    e213_ro1_2: "",
    e213_ro1_3: "",
    e213_ro1_4: "",
    e213_ro2_1: false,
    e213_ro2_2: "",
    e213_ro2_3: "",
    e213_ro2_4: "",
    e213_ro3_1: false,
    e213_ro3_2: "",
    e213_ro3_3: "",
    e213_ro3_4: "",
    e213_ro4_1: false,
    e213_ro4_2: "",
    e213_ro4_3: "",
    e213_ro4_4: "",
    e213_ro5_1: false,
    e213_ro5_2: "",

    e214_i1: "",
    e214_i2: false,
    e214_ro: "",
    e214_ha1: "",
    e214_ha2_1: null,

    e215_i: "",
    e215_ro: "",
    e215_ha1: "",
    e215_ha2_1: null,

    e220_1: "",
  },
  e3: {
    e3_1_11_1: null,
    e3_1_12: "",
    e3_1_13: "",
    e3_1_14_1: null,
    e3_1_15: "",
    e3_1_21_1: null,
    e3_1_22: "",
    e3_1_23: "",
    e3_1_24_1: null,
    e3_1_25: "",
    e3_1_31_1: null,
    e3_1_32: "",
    e3_1_33: "",
    e3_1_34_1: null,
    e3_1_35: "",

    e3_2_11_1: null,
    e3_2_12: "",
    e3_2_13: "",
    e3_2_14_1: null,
    e3_2_15: "",
    e3_2_21_1: null,
    e3_2_22: "",
    e3_2_23: "",
    e3_2_24_1: null,
    e3_2_25: "",
    e3_2_31_1: null,
    e3_2_32: "",
    e3_2_33: "",
    e3_2_34_1: null,
    e3_2_35: "",

    e3_3_11_1: null,
    e3_3_12: "",
    e3_3_13: "",
    e3_3_14_1: null,
    e3_3_15: "",
    e3_3_21_1: null,
    e3_3_22: "",
    e3_3_23: "",
    e3_3_24_1: null,
    e3_3_25: "",
    e3_3_31_1: null,
    e3_3_32: "",
    e3_3_33: "",
    e3_3_34_1: null,
    e3_3_35: "",
  },
  e41: {
    e40_1_11: "",
    e40_1_12: "1",
    e40_1_21: "",
    e40_1_22: "",
    e40_1_31: "",
    e40_1_32: "",

    e41_1_1_11: "",
    e41_1_1_12: false,
    e41_1_1_13: "1",
    e41_1_1_21: "",
    e41_1_1_22: false,
    e41_1_1_23: "1",
    e41_1_1_31: "",
    e41_1_1_32: false,
    e41_1_1_33: "1",
    e41_1_1_41: "",
    e41_1_1_42: false,
    e41_1_1_43: "1",
    e41_1_1_51: "",
    e41_1_1_52: false,
    e41_1_1_53: "1",
    e41_1_1_61: "",
    e41_1_1_62: false,
    e41_1_1_63: "1",
    e41_1_1_71: "",
    e41_1_1_72: false,
    e41_1_1_73: "1",
    e41_1_1_81: "",
    e41_1_1_82: false,
    e41_1_1_83: "1",
    e41_1_1_91: "",
    e41_1_1_92: false,
    e41_1_1_93: "1",
    e41_1_1_101: "",
    e41_1_1_102: false,
    e41_1_1_103: "1",
    e41_1_1_111: "",
    e41_1_1_112: false,
    e41_1_1_113: "1",
    e41_1_1_121: "",
    e41_1_1_122: false,
    e41_1_1_123: "1",
    e41_1_1_131: "",
    e41_1_1_132: false,
    e41_1_1_133: "1",
    e41_1_1_141: "",
    e41_1_1_142: false,
    e41_1_1_143: "1",
    e41_1_1_151: "",
    e41_1_1_152: false,
    e41_1_1_153: "1",
    e41_1_1_161: "",
    e41_1_1_162: false,
    e41_1_1_163: "1",
    e41_1_1_171: "",
    e41_1_1_172: false,
    e41_1_1_173: "1",
    e41_1_1_181: "",
    e41_1_1_182: false,
    e41_1_1_183: "1",
    e41_1_1_191: "",
    e41_1_1_192: false,
    e41_1_1_193: "1",
    e41_1_1_201: "",
    e41_1_1_202: false,
    e41_1_1_203: "1",
    e41_1_1_211: "",
    e41_1_1_212: false,
    e41_1_1_213: "1",

    e41_1_2_11: "",
    e41_1_2_12: false,
    e41_1_2_13: "1",
    e41_1_2_21: "",
    e41_1_2_22: false,
    e41_1_2_23: "1",
    e41_1_2_31: "",
    e41_1_2_32: false,
    e41_1_2_33: "1",
    e41_1_2_41: "",
    e41_1_2_42: false,
    e41_1_2_43: "1",
    e41_1_2_51: "",
    e41_1_2_52: false,
    e41_1_2_53: "1",
    e41_1_2_61: "",
    e41_1_2_62: false,
    e41_1_2_63: "1",
    e41_1_2_71: "",
    e41_1_2_72: false,
    e41_1_2_73: "1",
    e41_1_2_81: "",
    e41_1_2_82: false,
    e41_1_2_83: "1",
    e41_1_2_91: "",
    e41_1_2_92: false,
    e41_1_2_93: "1",
    e41_1_2_101: "",
    e41_1_2_102: false,
    e41_1_2_103: "1",
    e41_1_2_111: "",
    e41_1_2_112: false,
    e41_1_2_113: "1",
    e41_1_2_121: "",
    e41_1_2_122: false,
    e41_1_2_123: "1",
    e41_1_2_131: "",
    e41_1_2_132: false,
    e41_1_2_133: "1",

    e41_1_3_11: "",
    e41_1_3_12: false,
    e41_1_3_13: "1",
    e41_1_3_21: "",
    e41_1_3_22: false,
    e41_1_3_23: "1",
    e41_1_3_31: "",
    e41_1_3_32: false,
    e41_1_3_33: "1",
    e41_1_3_41: "",
    e41_1_3_42: false,
    e41_1_3_43: "1",
    e41_1_3_51: "",
    e41_1_3_52: false,
    e41_1_3_53: "1",
    e41_1_3_61: "",
    e41_1_3_62: false,
    e41_1_3_63: "1",
    e41_1_3_71: "",
    e41_1_3_72: false,
    e41_1_3_73: "1",
    e41_1_3_81: "",
    e41_1_3_82: false,
    e41_1_3_83: "1",
    e41_1_3_91: "",
    e41_1_3_92: false,
    e41_1_3_93: "1",

    e41_1_5_11: "",
    e41_1_5_12: "",
    e41_1_5_13: "",
    e41_1_5_14: "",
    e41_1_5_15_1: null,

    e41_1_5_21: "",
    e41_1_5_22: "",
    e41_1_5_23: "",
    e41_1_5_24: "",
    e41_1_5_25_1: null,

    e41_1_5_31: "",
    e41_1_5_32: "",
    e41_1_5_33: "",
    e41_1_5_34: "",
    e41_1_5_35_1: null,

    e41_1_5_41: "",
    e41_1_5_42: "",
    e41_1_5_43: "",
    e41_1_5_44: "",
    e41_1_5_45_1: null,

    e41_1_5_51: "",
    e41_1_5_52: "",
    e41_1_5_53: "",
    e41_1_5_54: "",
    e41_1_5_55_1: null,
  },
  e42: {
    e40_2_11: "",
    e40_2_12: "1",
    e40_2_21: "",
    e40_2_22: "",
    e40_2_31: "",
    e40_2_32: "",

    e41_2_1_11: "",
    e41_2_1_12: false,
    e41_2_1_13: "1",
    e41_2_1_21: "",
    e41_2_1_22: false,
    e41_2_1_23: "1",
    e41_2_1_31: "",
    e41_2_1_32: false,
    e41_2_1_33: "1",
    e41_2_1_41: "",
    e41_2_1_42: false,
    e41_2_1_43: "1",
    e41_2_1_51: "",
    e41_2_1_52: false,
    e41_2_1_53: "1",
    e41_2_1_61: "",
    e41_2_1_62: false,
    e41_2_1_63: "1",
    e41_2_1_71: "",
    e41_2_1_72: false,
    e41_2_1_73: "1",
    e41_2_1_81: "",
    e41_2_1_82: false,
    e41_2_1_83: "1",
    e41_2_1_91: "",
    e41_2_1_92: false,
    e41_2_1_93: "1",
    e41_2_1_101: "",
    e41_2_1_102: false,
    e41_2_1_103: "1",
    e41_2_1_111: "",
    e41_2_1_112: false,
    e41_2_1_113: "1",
    e41_2_1_121: "",
    e41_2_1_122: false,
    e41_2_1_123: "1",
    e41_2_1_131: "",
    e41_2_1_132: false,
    e41_2_1_133: "1",
    e41_2_1_141: "",
    e41_2_1_142: false,
    e41_2_1_143: "1",
    e41_2_1_151: "",
    e41_2_1_152: false,
    e41_2_1_153: "1",
    e41_2_1_161: "",
    e41_2_1_162: false,
    e41_2_1_163: "1",
    e41_2_1_171: "",
    e41_2_1_172: false,
    e41_2_1_173: "1",
    e41_2_1_181: "",
    e41_2_1_182: false,
    e41_2_1_183: "1",
    e41_2_1_191: "",
    e41_2_1_192: false,
    e41_2_1_193: "1",
    e41_2_1_201: "",
    e41_2_1_202: false,
    e41_2_1_203: "1",
    e41_2_1_211: "",
    e41_2_1_212: false,
    e41_2_1_213: "1",
    e41_2_1_221: "",
    e41_2_1_222: false,
    e41_2_1_223: "1",
    e41_2_1_231: "",
    e41_2_1_232: false,
    e41_2_1_233: "1",
    e41_2_1_241: "",
    e41_2_1_242: false,
    e41_2_1_243: "1",
    e41_2_1_251: "",
    e41_2_1_252: false,
    e41_2_1_253: "1",
    e41_2_1_261: "",
    e41_2_1_262: false,
    e41_2_1_263: "1",
    e41_2_1_271: "",
    e41_2_1_272: false,
    e41_2_1_273: "1",
    e41_2_1_281: "",
    e41_2_1_282: false,
    e41_2_1_283: "1",
    e41_2_1_291: "",
    e41_2_1_292: false,
    e41_2_1_293: "1",
    e41_2_1_301: "",
    e41_2_1_302: false,
    e41_2_1_303: "1",
    e41_2_1_311: "",
    e41_2_1_312: false,
    e41_2_1_313: "1",
    e41_2_1_321: "",
    e41_2_1_322: false,
    e41_2_1_323: "1",
    e41_2_1_331: "",
    e41_2_1_332: false,
    e41_2_1_333: "1",
    e41_2_1_341: "",
    e41_2_1_342: false,
    e41_2_1_343: "1",
    e41_2_1_351: "",
    e41_2_1_352: false,
    e41_2_1_353: "1",
    e41_2_1_361: "",
    e41_2_1_362: false,
    e41_2_1_363: "1",
    e41_2_1_371: "",
    e41_2_1_372: false,
    e41_2_1_373: "1",
    e41_2_1_381: "",
    e41_2_1_382: false,
    e41_2_1_383: "1",
    e41_2_1_391: "",
    e41_2_1_392: false,
    e41_2_1_393: "1",
    e41_2_1_401: "",
    e41_2_1_402: false,
    e41_2_1_403: "1",
    e41_2_1_411: "",
    e41_2_1_412: false,
    e41_2_1_413: "1",
    e41_2_1_421: "",
    e41_2_1_422: false,
    e41_2_1_423: "1",
    e41_2_1_431: "",
    e41_2_1_432: false,
    e41_2_1_433: "1",
    e41_2_1_441: "",
    e41_2_1_442: false,
    e41_2_1_443: "1",
    e41_2_1_451: "",
    e41_2_1_452: false,
    e41_2_1_453: "1",
    e41_2_1_461: "",
    e41_2_1_462: false,
    e41_2_1_463: "1",
    e41_2_1_471: "",
    e41_2_1_472: false,
    e41_2_1_473: "1",
    e41_2_1_481: "",
    e41_2_1_482: false,
    e41_2_1_483: "1",
    e41_2_1_491: "",
    e41_2_1_492: false,
    e41_2_1_493: "1",
    e41_2_1_501: "",
    e41_2_1_502: false,
    e41_2_1_503: "1",
    e41_2_1_511: "",
    e41_2_1_512: false,
    e41_2_1_513: "1",
    e41_2_1_521: "",
    e41_2_1_522: false,
    e41_2_1_523: "1",
    e41_2_1_531: "",
    e41_2_1_532: false,
    e41_2_1_533: "1",

    e41_2_2_11: "",
    e41_2_2_12: false,
    e41_2_2_13: "1",
    e41_2_2_21: "",
    e41_2_2_22: false,
    e41_2_2_23: "1",
    e41_2_2_31: "",
    e41_2_2_32: false,
    e41_2_2_33: "1",
    e41_2_2_41: "",
    e41_2_2_42: false,
    e41_2_2_43: "1",
    e41_2_2_51: "",
    e41_2_2_52: false,
    e41_2_2_53: "1",
    e41_2_2_61: "",
    e41_2_2_62: false,
    e41_2_2_63: "1",
    e41_2_2_71: "",
    e41_2_2_72: false,
    e41_2_2_73: "1",
    e41_2_2_81: "",
    e41_2_2_82: false,
    e41_2_2_83: "1",
    e41_2_2_91: "",
    e41_2_2_92: false,
    e41_2_2_93: "1",
    e41_2_2_101: "",
    e41_2_2_102: false,
    e41_2_2_103: "1",
    e41_2_2_111: "",
    e41_2_2_112: false,
    e41_2_2_113: "1",
    e41_2_2_121: "",
    e41_2_2_122: false,
    e41_2_2_123: "1",
    e41_2_2_131: "",
    e41_2_2_132: false,
    e41_2_2_133: "1",
    e41_2_2_141: "",
    e41_2_2_142: false,
    e41_2_2_143: "1",
    e41_2_2_151: "",
    e41_2_2_152: false,
    e41_2_2_153: "1",
    e41_2_2_161: "",
    e41_2_2_162: false,
    e41_2_2_163: "1",
    e41_2_2_171: "",
    e41_2_2_172: false,
    e41_2_2_173: "1",
    e41_2_2_181: "",
    e41_2_2_182: false,
    e41_2_2_183: "1",
    e41_2_2_191: "",
    e41_2_2_192: false,
    e41_2_2_193: "1",
    e41_2_2_201: "",
    e41_2_2_202: false,
    e41_2_2_203: "1",
    e41_2_2_211: "",
    e41_2_2_212: false,
    e41_2_2_213: "1",
    e41_2_2_221: "",
    e41_2_2_222: false,
    e41_2_2_223: "1",
    e41_2_2_231: "",
    e41_2_2_232: false,
    e41_2_2_233: "1",
    e41_2_2_241: "",
    e41_2_2_242: false,
    e41_2_2_243: "1",
    e41_2_2_251: "",
    e41_2_2_252: false,
    e41_2_2_253: "1",
    e41_2_2_261: "",
    e41_2_2_262: false,
    e41_2_2_263: "1",
    e41_2_2_271: "",
    e41_2_2_272: false,
    e41_2_2_273: "1",
    e41_2_2_281: "",
    e41_2_2_282: false,
    e41_2_2_283: "1",
    e41_2_2_291: "",
    e41_2_2_292: false,
    e41_2_2_293: "1",
    e41_2_2_301: "",
    e41_2_2_302: false,
    e41_2_2_303: "1",
    e41_2_2_311: "",
    e41_2_2_312: false,
    e41_2_2_313: "1",
    e41_2_2_321: "",
    e41_2_2_322: false,
    e41_2_2_323: "1",

    e41_2_3_11: "",
    e41_2_3_12: false,
    e41_2_3_13: "1",
    e41_2_3_21: "",
    e41_2_3_22: false,
    e41_2_3_23: "1",
    e41_2_3_31: "",
    e41_2_3_32: false,
    e41_2_3_33: "1",
    e41_2_3_41: "",
    e41_2_3_42: false,
    e41_2_3_43: "1",
    e41_2_3_51: "",
    e41_2_3_52: false,
    e41_2_3_53: "1",
    e41_2_3_61: "",
    e41_2_3_62: false,
    e41_2_3_63: "1",

    e41_2_4_11: "",
    e41_2_4_12: false,
    e41_2_4_13: "1",
    e41_2_4_21: "",
    e41_2_4_22: false,
    e41_2_4_23: "1",
    e41_2_4_31: "",
    e41_2_4_32: false,
    e41_2_4_33: "1",
    e41_2_4_41: "",
    e41_2_4_42: false,
    e41_2_4_43: "1",
    e41_2_4_51: "",
    e41_2_4_52: false,
    e41_2_4_53: "1",
    e41_2_4_61: "",
    e41_2_4_62: false,
    e41_2_4_63: "1",
    e41_2_4_71: "",
    e41_2_4_72: false,
    e41_2_4_73: "1",
    e41_2_4_81: "",
    e41_2_4_82: false,
    e41_2_4_83: "1",
    e41_2_4_91: "",
    e41_2_4_92: false,
    e41_2_4_93: "1",
    e41_2_4_101: "",
    e41_2_4_102: false,
    e41_2_4_103: "1",
    e41_2_4_111: "",
    e41_2_4_112: false,
    e41_2_4_113: "1",
    e41_2_4_121: "",
    e41_2_4_122: false,
    e41_2_4_123: "1",
    e41_2_4_131: "",
    e41_2_4_132: false,
    e41_2_4_133: "1",
    e41_2_4_141: "",
    e41_2_4_142: false,
    e41_2_4_143: "1",
    e41_2_4_151: "",
    e41_2_4_152: false,
    e41_2_4_153: "1",
    e41_2_4_161: "",
    e41_2_4_162: false,
    e41_2_4_163: "1",
    e41_2_4_171: "",
    e41_2_4_172: false,
    e41_2_4_173: "1",
    e41_2_4_181: "",
    e41_2_4_182: false,
    e41_2_4_183: "1",
    e41_2_4_191: "",
    e41_2_4_192: false,
    e41_2_4_193: "1",
    e41_2_4_201: "",
    e41_2_4_202: false,
    e41_2_4_203: "1",
    e41_2_4_211: "",
    e41_2_4_212: false,
    e41_2_4_213: "1",
    e41_2_4_221: "",
    e41_2_4_222: false,
    e41_2_4_223: "1",
    e41_2_4_231: "",
    e41_2_4_232: false,
    e41_2_4_233: "1",
    e41_2_4_241: "",
    e41_2_4_242: false,
    e41_2_4_243: "1",
    e41_2_4_251: "",
    e41_2_4_252: false,
    e41_2_4_253: "1",
    e41_2_4_261: "",
    e41_2_4_262: false,
    e41_2_4_263: "1",

    e41_2_6_11: "",
    e41_2_6_12: "",
    e41_2_6_13: "",
    e41_2_6_14: "",
    e41_2_6_15_1: null,

    e41_2_6_21: "",
    e41_2_6_22: "",
    e41_2_6_23: "",
    e41_2_6_24: "",
    e41_2_6_25_1: null,

    e41_2_6_31: "",
    e41_2_6_32: "",
    e41_2_6_33: "",
    e41_2_6_34: "",
    e41_2_6_35_1: null,

    e41_2_6_41: "",
    e41_2_6_42: "",
    e41_2_6_43: "",
    e41_2_6_44: "",
    e41_2_6_45_1: null,

    e41_2_6_51: "",
    e41_2_6_52: "",
    e41_2_6_53: "",
    e41_2_6_54: "",
    e41_2_6_55_1: null,
  },
  e43: {
    e40_3_11: "",
    e40_3_12: "1",
    e40_3_21: "",
    e40_3_22: "",
    e40_3_31: "",
    e40_3_32: "",

    e41_3_1_11: "",
    e41_3_1_12: false,
    e41_3_1_13: "1",
    e41_3_1_21: "",
    e41_3_1_22: false,
    e41_3_1_23: "1",

    e41_3_2_11: "",
    e41_3_2_12: false,
    e41_3_2_13: "1",
    e41_3_2_21: "",
    e41_3_2_22: false,
    e41_3_2_23: "1",
    e41_3_2_31: "",
    e41_3_2_32: false,
    e41_3_2_33: "1",
    e41_3_2_41: "",
    e41_3_2_42: false,
    e41_3_2_43: "1",

    e41_3_3_11: "",
    e41_3_3_12: false,
    e41_3_3_13: "1",
    e41_3_3_21: "",
    e41_3_3_22: false,
    e41_3_3_23: "1",
    e41_3_3_31: "",
    e41_3_3_32: false,
    e41_3_3_33: "1",
    e41_3_3_41: "",
    e41_3_3_42: false,
    e41_3_3_43: "1",
    e41_3_3_51: "",
    e41_3_3_52: false,
    e41_3_3_53: "1",
    e41_3_3_61: "",
    e41_3_3_62: false,
    e41_3_3_63: "1",

    e41_3_4_11: "",
    e41_3_4_12: false,
    e41_3_4_13: "1",
    e41_3_4_21: "",
    e41_3_4_22: false,
    e41_3_4_23: "1",

    e41_3_5_11: "",
    e41_3_5_12: false,
    e41_3_5_13: "1",
    e41_3_5_21: "",
    e41_3_5_22: false,
    e41_3_5_23: "1",
    e41_3_5_31: "",
    e41_3_5_32: false,
    e41_3_5_33: "1",
    e41_3_5_41: "",
    e41_3_5_42: false,
    e41_3_5_43: "1",
    e41_3_5_51: "",
    e41_3_5_52: false,
    e41_3_5_53: "1",
    e41_3_5_61: "",
    e41_3_5_62: false,
    e41_3_5_63: "1",
    e41_3_5_71: "",
    e41_3_5_72: false,
    e41_3_5_73: "1",
    e41_3_5_81: "",
    e41_3_5_82: false,
    e41_3_5_83: "1",

    e41_3_6_11: "",
    e41_3_6_12: false,
    e41_3_6_13: "1",
    e41_3_6_21: "",
    e41_3_6_22: false,
    e41_3_6_23: "1",
    e41_3_6_31: "",
    e41_3_6_32: false,
    e41_3_6_33: "1",
    e41_3_6_41: "",
    e41_3_6_42: false,
    e41_3_6_43: "1",
    e41_3_6_51: "",
    e41_3_6_52: false,
    e41_3_6_53: "1",
    e41_3_6_61: "",
    e41_3_6_62: false,
    e41_3_6_63: "1",
    e41_3_6_71: "",
    e41_3_6_72: false,
    e41_3_6_73: "1",
    e41_3_6_81: "",
    e41_3_6_82: false,
    e41_3_6_83: "1",
    e41_3_6_91: "",
    e41_3_6_92: false,
    e41_3_6_93: "1",
    e41_3_6_101: "",
    e41_3_6_102: false,
    e41_3_6_103: "1",
    e41_3_6_111: "",
    e41_3_6_112: false,
    e41_3_6_113: "1",
    e41_3_6_121: "",
    e41_3_6_122: false,
    e41_3_6_123: "1",
    e41_3_6_131: "",
    e41_3_6_132: false,
    e41_3_6_133: "1",
    e41_3_6_141: "",
    e41_3_6_142: false,
    e41_3_6_143: "1",
    e41_3_6_151: "",
    e41_3_6_152: false,
    e41_3_6_153: "1",
    e41_3_6_161: "",
    e41_3_6_162: false,
    e41_3_6_163: "1",
    e41_3_6_171: "",
    e41_3_6_172: false,
    e41_3_6_173: "1",

    e41_3_8_11: "",
    e41_3_8_12: "",
    e41_3_8_13: "",
    e41_3_8_14: "",
    e41_3_8_15_1: null,

    e41_3_8_21: "",
    e41_3_8_22: "",
    e41_3_8_23: "",
    e41_3_8_24: "",
    e41_3_8_25_1: null,

    e41_3_8_31: "",
    e41_3_8_32: "",
    e41_3_8_33: "",
    e41_3_8_34: "",
    e41_3_8_35_1: null,

    e41_3_8_41: "",
    e41_3_8_42: "",
    e41_3_8_43: "",
    e41_3_8_44: "",
    e41_3_8_45_1: null,

    e41_3_8_51: "",
    e41_3_8_52: "",
    e41_3_8_53: "",
    e41_3_8_54: "",
    e41_3_8_55_1: null,
  },
  e9: {
    e91_1: "特定行政庁　静岡県知事", //提出先

    e92_1: "", //棟名
  },
  esummry: {
    esummry5_i: "",
    esummry5_ro: "",
    esummry5_ha: "",
    esummry5_ni1: "",
    esummry5_ni2_1: null,
    esummry5_ni3: "",
  },
};
