export const fireProtectionFacilityDataSet = {
  f0: {
    f01_i1: "",
    f01_i2: "",
    f01_ro1: "",
    f01_ro2: 1,
    f01_ha: "",
    f01_ni: "",
    f01_ho_1: null,

    f02_i1: "",
    f02_i2: "",
    f02_ro1: "",
    f02_ro2: "",
  },
  f1: {
    f11_i: "",
    f11_ro: "",
    f11_ha: "",
    f11_ni: "",
    f11_ho: "",

    f12_i: "",
    f12_ro: "",
    f12_ha: "",
    f12_ni: "",
    f12_ho: "",

    f13_i: "",
    f13_ro: "",
    f13_ha: "",
    f13_ni: "",

    f14_i1: "",
    f14_i2: false,
    f14_ro: "",
  },
  f2: {
    f21_i1: "",
    f21_i2: "",
    f21_ro: "",
    f21_ha: "",

    f22_i1_1: null,
    f22_i2: "",
    f22_ro1: "",
    f22_ro2: "",
    f22_ha1_1: null,

    f22_ha2: "",
    f22_ni1: "",
    f22_ni2: "",

    f23_i_1: null,
    f23_ro1: "",
    f23_ro2_1: null,
    f23_ha: false,

    f24_ai1: "一級建築士",
    f24_ai2: "大臣登録",
    f24_ai3: "",
    f24_ai4: "",
    f24_aro: "",
    f24_aha: "",
    f24_ani1: "",
    f24_ani2: "一級建築士事務所",
    f24_ani3: "",
    f24_ani4: "",
    f24_aho: "",
    f24_ahe: "",
    f24_ato: "",
    f24_bi1: "一級建築士",
    f24_bi2: "大臣登録",
    f24_bi3: "",
    f24_bi4: "",
    f24_bro: "",
    f24_bha: "",
    f24_bni1: "",
    f24_bni2: "一級建築士事務所",
    f24_bni3: "",
    f24_bni4: "",
    f24_bho: "",
    f24_bhe: "",
    f24_bto: "",

    f25_i1_1: false,
    f25_i1_2: "",
    f25_i2_1: false,
    f25_i2_2: "",
    f25_i3_1: false,
    f25_i4_1: false,
    f25_i4_2: "",
    f25_ro1_1: false,
    f25_ro1_2: "",
    f25_ro2_1: false,
    f25_ro2_2: "",
    f25_ro3_1: false,
    f25_ro3_2: "",
    f25_ro4_1: false,
    f25_ro4_2: "",
    f25_ro5_1: false,
    f25_ro5_2: "",

    f26_i1: "",
    f26_i2: false,
    f26_ro: "",
    f26_ha1: "",
    f26_ha2_1: null,

    f27_i: "",
    f27_ro: "",
    f27_ha1: "",
    f27_ha2_1: null,

    f28_1: "",
  },
  f3: {
    f3_1_11_1: null,
    f3_1_12: "",
    f3_1_13: "",
    f3_1_14_1: null,
    f3_1_15: "",
    f3_1_21_1: null,
    f3_1_22: "",
    f3_1_23: "",
    f3_1_24_1: null,
    f3_1_25: "",
    f3_1_31_1: "",
    f3_1_32: "",
    f3_1_33: "",
    f3_1_34_1: null,
    f3_1_35: "",
  },
  f41: {
    f40_1_11: "",
    f40_1_12: "1",
    f40_1_21: "",
    f40_1_22: "",
    f40_1_31: "",
    f40_1_32: "",

    f41_1_1_11: "",
    f41_1_1_12: false,
    f41_1_1_13: "1",
    f41_1_1_21: "",
    f41_1_1_22: false,
    f41_1_1_23: "1",
    f41_1_1_31: "",
    f41_1_1_32: false,
    f41_1_1_33: "1",
    f41_1_1_41: "",
    f41_1_1_42: false,
    f41_1_1_43: "1",
    f41_1_1_51: "",
    f41_1_1_52: false,
    f41_1_1_53: "1",
    f41_1_1_61: "",
    f41_1_1_62: false,
    f41_1_1_63: "1",
    f41_1_1_71: "",
    f41_1_1_72: false,
    f41_1_1_73: "1",
    f41_1_1_81: "",
    f41_1_1_82: false,
    f41_1_1_83: "1",
    f41_1_1_91: "",
    f41_1_1_92: false,
    f41_1_1_93: "1",
    f41_1_1_101: "",
    f41_1_1_102: false,
    f41_1_1_103: "1",
    f41_1_1_111: "",
    f41_1_1_112: false,
    f41_1_1_113: "1",
    f41_1_1_121: "",
    f41_1_1_122: false,
    f41_1_1_123: "1",
    f41_1_1_131: "",
    f41_1_1_132: false,
    f41_1_1_133: "1",
    f41_1_1_141: "",
    f41_1_1_142: false,
    f41_1_1_143: "1",
    f41_1_1_151: "",
    f41_1_1_152: false,
    f41_1_1_153: "1",
    f41_1_1_161: "",
    f41_1_1_162: false,
    f41_1_1_163: "1",
    f41_1_1_171: "",
    f41_1_1_172: false,
    f41_1_1_173: "1",

    f41_1_3_11: "",
    f41_1_3_12: "",
    f41_1_3_13: "",
    f41_1_3_14: "",
    f41_1_3_15_1: null,

    f41_1_3_21: "",
    f41_1_3_22: "",
    f41_1_3_23: "",
    f41_1_3_24: "",
    f41_1_3_25_1: null,

    f41_1_3_31: "",
    f41_1_3_32: "",
    f41_1_3_33: "",
    f41_1_3_34: "",
    f41_1_3_35_1: null,

    f41_1_3_41: "",
    f41_1_3_42: "",
    f41_1_3_43: "",
    f41_1_3_44: "",
    f41_1_3_45_1: null,

    f41_1_3_51: "",
    f41_1_3_52: "",
    f41_1_3_53: "",
    f41_1_3_54: "",
    f41_1_3_55_1: null,
  },
  f42: {
    f40_2_11: "",
    f40_2_12: "1",
    f40_2_21: "",
    f40_2_22: "",
    f40_2_31: "",
    f40_2_32: "",

    f41_2_1_11: "",
    f41_2_1_12: false,
    f41_2_1_13: "1",
    f41_2_1_21: "",
    f41_2_1_22: false,
    f41_2_1_23: "1",
    f41_2_1_31: "",
    f41_2_1_32: false,
    f41_2_1_33: "1",
    f41_2_1_41: "",
    f41_2_1_42: false,
    f41_2_1_43: "1",
    f41_2_1_51: "",
    f41_2_1_52: false,
    f41_2_1_53: "1",
    f41_2_1_61: "",
    f41_2_1_62: false,
    f41_2_1_63: "1",
    f41_2_1_71: "",
    f41_2_1_72: false,
    f41_2_1_73: "1",
    f41_2_1_81: "",
    f41_2_1_82: false,
    f41_2_1_83: "1",
    f41_2_1_91: "",
    f41_2_1_92: false,
    f41_2_1_93: "1",
    f41_2_1_101: "",
    f41_2_1_102: false,
    f41_2_1_103: "1",
    f41_2_1_111: "",
    f41_2_1_112: false,
    f41_2_1_113: "1",
    f41_2_1_121: "",
    f41_2_1_122: false,
    f41_2_1_123: "1",
    f41_2_1_131: "",
    f41_2_1_132: false,
    f41_2_1_133: "1",
    f41_2_1_141: "",
    f41_2_1_142: false,
    f41_2_1_143: "1",
    f41_2_1_151: "",
    f41_2_1_152: false,
    f41_2_1_153: "1",
    f41_2_1_161: "",
    f41_2_1_162: false,
    f41_2_1_163: "1",
    f41_2_1_171: "",
    f41_2_1_172: false,
    f41_2_1_173: "1",
    f41_2_1_181: "",
    f41_2_1_182: false,
    f41_2_1_183: "1",
    f41_2_1_191: "",
    f41_2_1_192: false,
    f41_2_1_193: "1",
    f41_2_1_201: "",
    f41_2_1_202: false,
    f41_2_1_203: "1",
    f41_2_1_211: "",
    f41_2_1_212: false,
    f41_2_1_213: "1",
    f41_2_1_221: "",
    f41_2_1_222: false,
    f41_2_1_223: "1",
    f41_2_1_231: "",
    f41_2_1_232: false,
    f41_2_1_233: "1",
    f41_2_1_241: "",
    f41_2_1_242: false,
    f41_2_1_243: "1",
    f41_2_1_251: "",
    f41_2_1_252: false,
    f41_2_1_253: "1",
    f41_2_1_261: "",
    f41_2_1_262: false,
    f41_2_1_263: "1",
    f41_2_1_271: "",
    f41_2_1_272: false,
    f41_2_1_273: "1",

    f41_2_3_11: "",
    f41_2_3_12: "",
    f41_2_3_13: "",
    f41_2_3_14: "",
    f41_2_3_15_1: null,

    f41_2_3_21: "",
    f41_2_3_22: "",
    f41_2_3_23: "",
    f41_2_3_24: "",
    f41_2_3_25_1: null,

    f41_2_3_31: "",
    f41_2_3_32: "",
    f41_2_3_33: "",
    f41_2_3_34: "",
    f41_2_3_35_1: null,

    f41_2_3_41: "",
    f41_2_3_42: "",
    f41_2_3_43: "",
    f41_2_3_44: "",
    f41_2_3_45_1: null,

    f41_2_3_51: "",
    f41_2_3_52: "",
    f41_2_3_53: "",
    f41_2_3_54: "",
    f41_2_3_55_1: null,
  },
  f43: {
    f40_3_11: "",
    f40_3_12: "1",
    f40_3_21: "",
    f40_3_22: "",
    f40_3_31: "",
    f40_3_32: "",

    f41_3_1_11: "",
    f41_3_1_12: false,
    f41_3_1_13: "1",
    f41_3_1_21: "",
    f41_3_1_22: false,
    f41_3_1_23: "1",
    f41_3_1_31: "",
    f41_3_1_32: false,
    f41_3_1_33: "1",
    f41_3_1_41: "",
    f41_3_1_42: false,
    f41_3_1_43: "1",
    f41_3_1_51: "",
    f41_3_1_52: false,
    f41_3_1_53: "1",
    f41_3_1_61: "",
    f41_3_1_62: false,
    f41_3_1_63: "1",
    f41_3_1_71: "",
    f41_3_1_72: false,
    f41_3_1_73: "1",
    f41_3_1_81: "",
    f41_3_1_82: false,
    f41_3_1_83: "1",
    f41_3_1_91: "",
    f41_3_1_92: false,
    f41_3_1_93: "1",
    f41_3_1_101: "",
    f41_3_1_102: false,
    f41_3_1_103: "1",
    f41_3_1_111: "",
    f41_3_1_112: false,
    f41_3_1_113: "1",
    f41_3_1_121: "",
    f41_3_1_122: false,
    f41_3_1_123: "1",
    f41_3_1_131: "",
    f41_3_1_132: false,
    f41_3_1_133: "1",
    f41_3_1_141: "",
    f41_3_1_142: false,
    f41_3_1_143: "1",
    f41_3_1_151: "",
    f41_3_1_152: false,
    f41_3_1_153: "1",
    f41_3_1_161: "",
    f41_3_1_162: false,
    f41_3_1_163: "1",
    f41_3_1_171: "",
    f41_3_1_172: false,
    f41_3_1_173: "1",
    f41_3_1_181: "",
    f41_3_1_182: false,
    f41_3_1_183: "1",
    f41_3_1_191: "",
    f41_3_1_192: false,
    f41_3_1_193: "1",
    f41_3_1_201: "",
    f41_3_1_202: false,
    f41_3_1_203: "1",
    f41_3_1_211: "",
    f41_3_1_212: false,
    f41_3_1_213: "1",
    f41_3_1_221: "",
    f41_3_1_222: false,
    f41_3_1_223: "1",
    f41_3_1_231: "",
    f41_3_1_232: false,
    f41_3_1_233: "1",

    f41_3_3_11: "",
    f41_3_3_12: "",
    f41_3_3_13: "",
    f41_3_3_14: "",
    f41_3_3_15_1: null,

    f41_3_3_21: "",
    f41_3_3_22: "",
    f41_3_3_23: "",
    f41_3_3_24: "",
    f41_3_3_25_1: null,

    f41_3_3_31: "",
    f41_3_3_32: "",
    f41_3_3_33: "",
    f41_3_3_34: "",
    f41_3_3_35_1: null,

    f41_3_3_41: "",
    f41_3_3_42: "",
    f41_3_3_43: "",
    f41_3_3_44: "",
    f41_3_3_45_1: null,

    f41_3_3_51: "",
    f41_3_3_52: "",
    f41_3_3_53: "",
    f41_3_3_54: "",
    f41_3_3_55_1: null,
  },
  f44: {
    f40_4_11: "",
    f40_4_12: "1",
    f40_4_21: "",
    f40_4_22: "",
    f40_4_31: "",
    f40_4_32: "",

    f41_4_1_11: "",
    f41_4_1_12: false,
    f41_4_1_13: "1",
    f41_4_1_21: "",
    f41_4_1_22: false,
    f41_4_1_23: "1",
    f41_4_1_31: "",
    f41_4_1_32: false,
    f41_4_1_33: "1",
    f41_4_1_41: "",
    f41_4_1_42: false,
    f41_4_1_43: "1",
    f41_4_1_51: "",
    f41_4_1_52: false,
    f41_4_1_53: "1",
    f41_4_1_61: "",
    f41_4_1_62: false,
    f41_4_1_63: "1",
    f41_4_1_71: "",
    f41_4_1_72: false,
    f41_4_1_73: "1",
    f41_4_1_81: "",
    f41_4_1_82: false,
    f41_4_1_83: "1",
    f41_4_1_91: "",
    f41_4_1_92: false,
    f41_4_1_93: "1",
    f41_4_1_101: "",
    f41_4_1_102: false,
    f41_4_1_103: "1",
    f41_4_1_111: "",
    f41_4_1_112: false,
    f41_4_1_113: "1",
    f41_4_1_121: "",
    f41_4_1_122: false,
    f41_4_1_123: "1",
    f41_4_1_131: "",
    f41_4_1_132: false,
    f41_4_1_133: "1",
    f41_4_1_141: "",
    f41_4_1_142: false,
    f41_4_1_143: "1",
    f41_4_1_151: "",
    f41_4_1_152: false,
    f41_4_1_153: "1",
    f41_4_1_161: "",
    f41_4_1_162: false,
    f41_4_1_163: "1",
    f41_4_1_171: "",
    f41_4_1_172: false,
    f41_4_1_173: "1",
    f41_4_1_181: "",
    f41_4_1_182: false,
    f41_4_1_183: "1",
    f41_4_1_191: "",
    f41_4_1_192: false,
    f41_4_1_193: "1",
    f41_4_1_201: "",
    f41_4_1_202: false,
    f41_4_1_203: "1",
    f41_4_1_211: "",
    f41_4_1_212: false,
    f41_4_1_213: "1",
    f41_4_1_221: "",
    f41_4_1_222: false,
    f41_4_1_223: "1",
    f41_4_1_231: "",
    f41_4_1_232: false,
    f41_4_1_233: "1",
    f41_4_1_241: "",
    f41_4_1_242: false,
    f41_4_1_243: "1",
    f41_4_1_251: "",
    f41_4_1_252: false,
    f41_4_1_253: "1",
    f41_4_1_261: "",
    f41_4_1_262: false,
    f41_4_1_263: "1",

    f41_4_3_11: "",
    f41_4_3_12: "",
    f41_4_3_13: "",
    f41_4_3_14: "",
    f41_4_3_15_1: null,

    f41_4_3_21: "",
    f41_4_3_22: "",
    f41_4_3_23: "",
    f41_4_3_24: "",
    f41_4_3_25_1: null,

    f41_4_3_31: "",
    f41_4_3_32: "",
    f41_4_3_33: "",
    f41_4_3_34: "",
    f41_4_3_35_1: null,

    f41_4_3_41: "",
    f41_4_3_42: "",
    f41_4_3_43: "",
    f41_4_3_44: "",
    f41_4_3_45_1: null,

    f41_4_3_51: "",
    f41_4_3_52: "",
    f41_4_3_53: "",
    f41_4_3_54: "",
    f41_4_3_55_1: null,
  },
  f9: {
    f91_1: "特定行政庁　静岡県知事", //提出先

    f92_1: "", //棟名
  },
  fsummry: {
    fsummry5_i: "",
    fsummry5_ro: "",
    fsummry5_ha: "",
    fsummry5_ni1: "",
    fsummry5_ni2_1: null,
    fsummry5_ni3: "",
  },
};
