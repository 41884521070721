import React from "react";
import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";

export const DisplayField = React.memo(
  ({ key_num = [], bulding, type, page, label = "" }) => {
    let val = "";
    key_num.forEach((element, index) => {
      let valElement = useSelector(
        (state) => state[type][bulding][page][element]
      );
      if (valElement !== "") {
        val = val + valElement + "\n";
      }
    });
    return (
      <TextField
        fullWidth
        multiline
        value={val}
        variant="filled"
        label={label ? label : ""}
        disabled={true}
      />
    );
  }
);
