import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { CSVLink } from "react-csv";
import { CSVReader } from "react-papaparse";
import { flatten, unflatten } from "flat";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Switch } from "@material-ui/core";

import PdfFireEquipment from "../PDF/PdfFireEquipment";
import {
  CHANGE_DATA_FIREEQUIPMENT,
  SET_DATA_FIREEQUIPMENT,
  RESET_DATA,
  COPYDATA_FIREEQUIPMENT,
} from "../Parts/Word";
import { CheackBoxKeys, DateKeys } from "../CsvData/CsvDataFireEquipment";
import { headersLabel_F } from "../../store";

import GenericTemplate from "../GenericTemplate";
import { fireProtectionFacilityDataSet } from "../../store/fireProtectionFacilityDataSet";

const FData = () => {
  const location = useLocation();

  const state = useSelector((state) => state["FireEquipment"]);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [data, setData] = useState([]);

  const [fileName, setFileName] = useState("");

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    for (let index = 2; index < 21; index++) {
      dispatch({
        type: COPYDATA_FIREEQUIPMENT,
        bulding: `B${index}`,
        page: "f0",
        page_moto: "f0",
      });
      dispatch({
        type: COPYDATA_FIREEQUIPMENT,
        bulding: `B${index}`,
        page: "f1",
        page_moto: "f1",
      });
    }
  }, [dispatch]);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  let buldingNumberEquipment = useSelector(
    (state) => state["FireEquipment"]["B1"]["f0"]["f01_ro2"]
  );

  if (typeof buldingNumberEquipment === "string") {
    dispatch({
      type: CHANGE_DATA_FIREEQUIPMENT,
      payload: { f01_ro2: Number(buldingNumberEquipment) },
      bulding: "B1",
      page: "f0",
    });
  }

  const headers = headersLabel_F;

  useEffect(() => {
    setFileName(
      "防火設備[" +
        state.B1.f0.f01_i1 +
        "-" +
        state.B1.f0.f01_i2 +
        "]" +
        state.B1.f0.f01_ro1 +
        "_報告書_結果表_概要書.csv"
    );
    let dataList = [];
    for (let i = 1; i < buldingNumberEquipment + 1; i++) {
      const obj = flatten(state[`B${i}`]);
      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          obj[key] = obj[key].toString().replace(/\r?\n/g, "・");
        }
      });
      dataList.push(obj);
      dataList[i - 1]["f0.f01_ro2"] = i;
    }
    setData(dataList);
  }, [buldingNumberEquipment, state]);

  const reset = () => {
    dispatch({ type: RESET_DATA });
  };

  const handleOnDrop = (dataIN) => {
    CheackBoxCheackFunc(dataIN);
    DateCheackFunc(dataIN);
    dataIN.forEach((data, index) => {
      dispatch({
        type: SET_DATA_FIREEQUIPMENT,
        payload: unflatten({
          ...flatten(fireProtectionFacilityDataSet),
          ...data.data,
        }),
        bulding: `B${index + 1}`,
      });
    });
    //棟数再セット
    dispatch({
      type: CHANGE_DATA_FIREEQUIPMENT,
      payload: { f01_ro2: dataIN.length },
      bulding: "B1",
      page: "f0",
    });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    reset();
    handleClose();
  };

  const CheackBoxCheackFunc = (dataIN) => {
    CheackBoxKeys.forEach((key) => {
      for (let index = 0; index < dataIN.length; index++) {
        if (dataIN[`${index}`].data[key] === "") {
          dataIN[`${index}`].data[key] = false;
        }
        if (dataIN[`${index}`].data[key] === "true") {
          dataIN[`${index}`].data[key] = true;
        }
      }
    });
  };

  const DateCheackFunc = (dataIN) => {
    DateKeys.forEach((key) => {
      for (let index = 0; index < dataIN.length; index++) {
        if (dataIN[`${index}`].data[key] === "") {
          dataIN[`${index}`].data[key] = null;
        }
      }
    });
  };

  return (
    <GenericTemplate type={"防火設備"} selectedIndex={location.pathname}>
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            CSVファイルの読込
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              config={{ header: true }}
            >
              <span>
                CSVファイルをここにドロップまたはここをクリックして選択
              </span>
            </CSVReader>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            申請書及び概要書PDFファイルの作成（複数棟の場合少し時間がかかります）
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Switch checked={checked} onChange={switchHandler} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            {checked && <PdfFireEquipment style={{ color: "#ffffff" }} />}
          </Grid>
          <Grid item xs={12}>
            CSVファイルの出力
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            <CSVLink
              data={data}
              headers={headers}
              filename={fileName}
              uFEFF={false}
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              <Button
                fullWidth
                variant="contained"
                style={{ background: "#a1887f" }}
              >
                CSVファイル出力
              </Button>
            </CSVLink>
          </Grid>

          <Grid item xs={12}>
            入力データのクリア
          </Grid>
          <Grid item xs={12} sx={{ mb: 10 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleClickOpen}
              style={{ background: "#FF0000" }}
            >
              入力クリア
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"入力データをクリアしますか？"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  CSVファイルへ出力しておけば、次回の入力手間が省けます。
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button onClick={handleClear} autoFocus>
                  クリア
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default React.memo(FData);
