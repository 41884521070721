import React from "react";

import Grid from "@mui/material/Grid";

import {
  InputTextField,
  InputTextFieldEquip,
  InputTextFieldFireEquip,
} from "../Parts/InputText";

export const Invest0 = React.memo(({ type }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ height: "100%", borderLeft: 1 }}>
          氏名
        </Grid>
        <Grid item xs={2} sx={{ height: "100%", borderLeft: 1 }}>
          調査者番号
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "100%" }}>
          代表となる調査者
        </Grid>
        <Grid item xs={7} sx={{ height: "100%", borderLeft: 1 }}>
          <InputTextField key_num={`${type}11`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "100%", borderLeft: 1 }}>
          <InputTextField key_num={`${type}12`} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{ height: "114px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "57px" }}>
          その他の調査者
        </Grid>
        <Grid item xs={7} sx={{ borderLeft: 1, height: "57px" }}>
          <InputTextField key_num={`${type}21`} />
        </Grid>
        <Grid item xs={2} sx={{ borderLeft: 1, height: "57px" }}>
          <InputTextField key_num={`${type}22`} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ borderLeft: 1, height: "57px" }}>
          <InputTextField key_num={`${type}31`} />
        </Grid>
        <Grid item xs={2} sx={{ borderLeft: 1, height: "57px" }}>
          <InputTextField key_num={`${type}32`} />
        </Grid>
      </Grid>
    </>
  );
});

export const Invest0Equip = React.memo(({ type }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          氏名
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          検査者番号
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "57px" }}>
          代表となる検査者
        </Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}11`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}12`} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{ height: "114px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "57px" }}>
          その他の検査者
        </Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}21`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}22`} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}31`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldEquip key_num={`${type}32`} />
        </Grid>
      </Grid>
    </>
  );
});

export const Invest0FireEquip = React.memo(({ type }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          氏名
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          検査者番号
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justify="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "57px" }}>
          代表となる検査者
        </Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}11`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}12`} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{ height: "114px", borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ height: "57px" }}>
          その他の検査者
        </Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}21`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}22`} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={7} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}31`} />
        </Grid>
        <Grid item xs={2} sx={{ height: "57px", borderLeft: 1 }}>
          <InputTextFieldFireEquip key_num={`${type}32`} />
        </Grid>
      </Grid>
    </>
  );
});
