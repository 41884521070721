export const CheackBoxKeys = [
  "f1.f14_i2",
  "f2.f23_ha",
  "f2.f25_i1_1",
  "f2.f25_i2_1",
  "f2.f25_i3_1",
  "f2.f25_i4_1",
  "f2.f25_ro1_1",
  "f2.f25_ro2_1",
  "f2.f25_ro3_1",
  "f2.f25_ro4_1",
  "f2.f25_ro5_1",
  "f2.f26_i2",
  "f41.f41_1_1_12",
  "f41.f41_1_1_22",
  "f41.f41_1_1_32",
  "f41.f41_1_1_42",
  "f41.f41_1_1_52",
  "f41.f41_1_1_62",
  "f41.f41_1_1_72",
  "f41.f41_1_1_82",
  "f41.f41_1_1_92",
  "f41.f41_1_1_102",
  "f41.f41_1_1_112",
  "f41.f41_1_1_122",
  "f41.f41_1_1_132",
  "f41.f41_1_1_142",
  "f41.f41_1_1_152",
  "f41.f41_1_1_162",
  "f41.f41_1_1_172",
  "f41.f41_1_2_12",
  "f41.f41_1_2_22",
  "f41.f41_1_2_32",
  "f42.f41_2_1_12",
  "f42.f41_2_1_22",
  "f42.f41_2_1_32",
  "f42.f41_2_1_42",
  "f42.f41_2_1_52",
  "f42.f41_2_1_62",
  "f42.f41_2_1_72",
  "f42.f41_2_1_82",
  "f42.f41_2_1_92",
  "f42.f41_2_1_102",
  "f42.f41_2_1_112",
  "f42.f41_2_1_122",
  "f42.f41_2_1_132",
  "f42.f41_2_1_142",
  "f42.f41_2_1_152",
  "f42.f41_2_1_162",
  "f42.f41_2_1_172",
  "f42.f41_2_1_182",
  "f42.f41_2_1_192",
  "f42.f41_2_1_202",
  "f42.f41_2_1_212",
  "f42.f41_2_1_222",
  "f42.f41_2_1_232",
  "f42.f41_2_1_242",
  "f42.f41_2_1_252",
  "f42.f41_2_1_262",
  "f42.f41_2_1_272",
  "f42.f41_2_2_12",
  "f42.f41_2_2_22",
  "f42.f41_2_2_32",
  "f43.f41_3_1_12",
  "f43.f41_3_1_22",
  "f43.f41_3_1_32",
  "f43.f41_3_1_42",
  "f43.f41_3_1_52",
  "f43.f41_3_1_62",
  "f43.f41_3_1_72",
  "f43.f41_3_1_82",
  "f43.f41_3_1_92",
  "f43.f41_3_1_102",
  "f43.f41_3_1_112",
  "f43.f41_3_1_122",
  "f43.f41_3_1_132",
  "f43.f41_3_1_142",
  "f43.f41_3_1_152",
  "f43.f41_3_1_162",
  "f43.f41_3_1_172",
  "f43.f41_3_1_182",
  "f43.f41_3_1_192",
  "f43.f41_3_1_202",
  "f43.f41_3_1_212",
  "f43.f41_3_1_222",
  "f43.f41_3_1_232",
  "f43.f41_3_2_12",
  "f43.f41_3_2_22",
  "f43.f41_3_2_32",
  "f44.f41_4_1_12",
  "f44.f41_4_1_22",
  "f44.f41_4_1_32",
  "f44.f41_4_1_42",
  "f44.f41_4_1_52",
  "f44.f41_4_1_62",
  "f44.f41_4_1_72",
  "f44.f41_4_1_82",
  "f44.f41_4_1_92",
  "f44.f41_4_1_102",
  "f44.f41_4_1_112",
  "f44.f41_4_1_122",
  "f44.f41_4_1_132",
  "f44.f41_4_1_142",
  "f44.f41_4_1_152",
  "f44.f41_4_1_162",
  "f44.f41_4_1_172",
  "f44.f41_4_1_182",
  "f44.f41_4_1_192",
  "f44.f41_4_1_202",
  "f44.f41_4_1_212",
  "f44.f41_4_1_222",
  "f44.f41_4_1_232",
  "f44.f41_4_1_242",
  "f44.f41_4_1_252",
  "f44.f41_4_1_262",
  "f44.f41_4_2_12",
  "f44.f41_4_2_22",
  "f44.f41_4_2_32",
];

export const DateKeys = [
  "f0.f01_ho_1",
  "f2.f22_i1_1",
  "f2.f22_ha1_1",
  "f2.f23_i_1",
  "f2.f23_ro2_1",
  "f2.f26_ha2_1",
  "f2.f27_ha2_1",
  "f3.f3_1_11_1",
  "f3.f3_1_14_1",
  "f3.f3_1_21_1",
  "f3.f3_1_24_1",
  "f3.f3_1_34_1",
  "f41.f41_1_3_15_1",
  "f41.f41_1_3_25_1",
  "f41.f41_1_3_35_1",
  "f41.f41_1_3_45_1",
  "f41.f41_1_3_55_1",
  "f42.f41_2_3_15_1",
  "f42.f41_2_3_25_1",
  "f42.f41_2_3_35_1",
  "f42.f41_2_3_45_1",
  "f42.f41_2_3_55_1",
  "f43.f41_3_3_15_1",
  "f43.f41_3_3_25_1",
  "f43.f41_3_3_35_1",
  "f43.f41_3_3_45_1",
  "f43.f41_3_3_55_1",
  "f44.f41_4_3_15_1",
  "f44.f41_4_3_25_1",
  "f44.f41_4_3_35_1",
  "f44.f41_4_3_45_1",
  "f44.f41_4_3_55_1",
];
