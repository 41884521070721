import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import GenericTemplate from "../GenericTemplate";

import { InputTextField, InputTextFieldMulti } from "../Parts/InputText";
import { InputRadio } from "../Parts/InputRadio";
import { InputCheakBox } from "../Parts/InputCheakBox";
import { InputDate } from "../Parts/InputDate";
import { COPYDATA_ARCHITECTURE } from "../Parts/Word";

const A2 = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="第２面　建築物及びその敷地に関する事項"
      type={"特殊建築物等"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <A20 />
        <A21 />
        <A22 />
        <A23 />
        <A24 />
        <A25 />
        <A26 />
        <A27 />
      </Box>
    </GenericTemplate>
  );
};

export default React.memo(A2);

export const A20 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_ARCHITECTURE,
      bulding,
      page,
      page_moto: "a2",
    });
  };

  if (location.pathname !== "/a2/a2/B1") {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#a1887f" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextField key_num={"a92_1"} page={"a9"} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextField key_num={"a92_1"} page={"a9"} />
          </Grid>
        </Grid>
      </>
    );
  }
});

export const A21 = React.memo(() => {
  return (
    <div>
      【1　敷地の位置】
      <InputRadio key_num={"a21_i1"}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={2}>
            イ　防火地域等
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              value="防火地域"
              control={<Radio />}
              label="防火地域"
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              value="準防火地域"
              control={<Radio />}
              label="準防火地域"
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </Grid>
              <Grid item xs={10}>
                <InputTextField key_num={"a21_i2"} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              value="指定なし"
              control={<Radio />}
              label="指定なし"
            />
          </Grid>
        </Grid>
      </InputRadio>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 1 }}>
          ロ　用途地域
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextField key_num={"a21_ro"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const A22 = React.memo(() => {
  return (
    <div>
      【２　建築物及びその敷地の概要】
      <InputRadio key_num={"a22_i1"}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={2}>
            イ　構造
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="鉄筋コンクリート造"
              control={<Radio />}
              label="鉄筋コンクリート造"
            />
          </Grid>
          <Grid item xs={7}>
            <FormControlLabel
              value="鉄筋鉄骨コンクリート造"
              control={<Radio />}
              label="鉄筋鉄骨コンクリート造"
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="鉄骨造"
              control={<Radio />}
              label="鉄骨造"
            />
          </Grid>
          <Grid item xs={7}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="その他"
                  control={<Radio />}
                  label="その他"
                />
              </Grid>
              <Grid item xs={9}>
                <InputTextField key_num={"a22_i2"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </InputRadio>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　階数
        </Grid>
        <Grid item xs={2} textAlign="right">
          地上
        </Grid>
        <Grid item xs={2}>
          <InputTextField key_num={"a22_ro1"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
        <Grid item xs={2} textAlign="right">
          地下
        </Grid>
        <Grid item xs={2}>
          <InputTextField key_num={"a22_ro2"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　敷地面積
        </Grid>
        <Grid item xs={8}>
          <InputTextField key_num={"a22_ha"} />
        </Grid>
        <Grid item xs={2}>
          ㎡
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　建築面積
        </Grid>
        <Grid item xs={8}>
          <InputTextField key_num={"a22_ni"} />
        </Grid>
        <Grid item xs={2}>
          ㎡
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ホ　延べ面積
        </Grid>
        <Grid item xs={8} sx={{ mb: 3 }}>
          <InputTextField key_num={"a22_ho"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 3 }}>
          ㎡
        </Grid>
      </Grid>
    </div>
  );
});

export const A23 = React.memo(() => {
  return (
    <div>
      【3　階別用途別床面積】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={3}>
          イ　階別用途別
        </Grid>
        <Grid item xs={2}>
          【階】
        </Grid>
        <Grid item xs={2}>
          【用途】
        </Grid>
        <Grid item xs={2}>
          【床面積】
        </Grid>
        <Grid item xs={3}>
          【コメント】
        </Grid>
        <Grid item xs={3}></Grid>
        <A231 a={"i"} b={"1"} />
        <Grid item xs={3}></Grid>
        <A231 a={"i"} b={"2"} />
        <Grid item xs={3}></Grid>
        <A231 a={"i"} b={"3"} />
        <Grid item xs={3}></Grid>
        <A231 a={"i"} b={"4"} />
        <Grid item xs={3}></Grid>
        <A231 a={"i"} b={"5"} />
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={3}>
          ロ　用途別
        </Grid>
        <A232 a={"ro"} b={"1"} />
      </Grid>
    </div>
  );
});

export const A231 = React.memo(({ a, b }) => {
  return (
    <>
      <Grid item xs={2}>
        <InputTextField
          key_num={`a23_${a}${b}1`}
          label={"○階の○の部分のみ記入"}
        />
      </Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}21`} />
      </Grid>
      <Grid item xs={2}>
        <InputTextField
          key_num={`a23_${a}${b}31`}
          label={"○㎡の○の部分のみ記入"}
        />
      </Grid>
      <Grid item xs={3}>
        <InputTextField key_num={`a23_${a}${b}41`} />
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}22`} />
      </Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}32`} />
      </Grid>
      <Grid item xs={3}>
        <InputTextField key_num={`a23_${a}${b}42`} />
      </Grid>
      <Grid item xs={5} sx={{ mb: 3 }}></Grid>
      <Grid item xs={2} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}23`} />
      </Grid>
      <Grid item xs={2} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}33`} />
      </Grid>
      <Grid item xs={3} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}43`} />
      </Grid>
    </>
  );
});

export const A232 = React.memo(({ a, b }) => {
  return (
    <>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}21`} />
      </Grid>
      <Grid item xs={2}>
        <InputTextField
          key_num={`a23_${a}${b}31`}
          label={"○㎡の○の部分のみ記入"}
        />
      </Grid>
      <Grid item xs={3}>
        <InputTextField key_num={`a23_${a}${b}41`} />
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}22`} />
      </Grid>
      <Grid item xs={2}>
        <InputTextField key_num={`a23_${a}${b}32`} />
      </Grid>
      <Grid item xs={3}>
        <InputTextField key_num={`a23_${a}${b}42`} />
      </Grid>
      <Grid item xs={5} sx={{ mb: 3 }}></Grid>
      <Grid item xs={2} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}23`} />
      </Grid>
      <Grid item xs={2} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}33`} />
      </Grid>
      <Grid item xs={3} sx={{ mb: 3 }}>
        <InputTextField key_num={`a23_${a}${b}43`} />
      </Grid>
    </>
  );
});

export const A24 = React.memo(() => {
  return (
    <div>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={4}>
          【4　性能検証法等の適用】
        </Grid>
        <Grid item xs={4}>
          <InputCheakBox key_num={"a24_1"} />
          耐火性能検証法
        </Grid>
        <Grid item xs={4}>
          <InputCheakBox key_num={"a24_2"} />
          防火区画検証法
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <InputCheakBox key_num={"a24_31"} />
              区画避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextField
                key_num={"a24_32"}
                label={"◯階の◯の部分のみ記入"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <InputCheakBox key_num={"a24_41"} />
              階避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextField
                key_num={"a24_42"}
                label={"◯階の◯の部分のみ記入"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ mb: 1 }}></Grid>
        <Grid item xs={4} sx={{ mb: 1 }}>
          <InputCheakBox key_num={"a24_5"} />
          全館避難安全検証法
        </Grid>
        <Grid item xs={4} sx={{ mb: 1 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <InputCheakBox key_num={"a24_61"} />
              その他
            </Grid>
            <Grid item xs={8} sx={{ mb: 1 }}>
              <InputTextField key_num={"a24_62"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export const A25 = React.memo(() => {
  return (
    <div>
      【5 増築、改築、用途変更等の経過】
      <A251 a={"1"} />
      <A251 a={"2"} />
      <A251 a={"3"} />
      <A251 a={"4"} mb={3} borderBottom={1} />
    </div>
  );
});

export const A251 = React.memo(({ a, mb = 1, borderBottom = 0 }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom }}
      >
        <Grid item xs={4} sx={{ mb }}>
          <InputDate
            key_num1={`a25_${a}1_1`}
            placeholderText={"増築等の年月日"}
          />
        </Grid>
        <Grid item xs={8} sx={{ mb }}>
          <InputTextField key_num={`a25_${a}2`} />
        </Grid>
      </Grid>
    </>
  );
});

export const A26 = React.memo(() => {
  return (
    <div>
      【6　関連図書の整備状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          【イ 確認に要した図書】
        </Grid>
        <Grid item xs={8}>
          <InputRadio key_num={"a26_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputCheakBox key_num={"a26_i2"} />
                各階平面図あり
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          【ロ 確認済証】
        </Grid>
        <Grid item xs={8}>
          <InputRadio key_num={"a26_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          交付番号
        </Grid>
        <Grid item xs={3}>
          <InputDate key_num1={"a26_ro2_1"} placeholderText={"交付年月日"} />
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            key_num={"a26_ro3"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          交付者
        </Grid>
        <Grid item xs={6}>
          <InputRadio key_num={"a26_ro4"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextField key_num={"a26_ro5"} />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          【ハ 完了検査に要した図書】
        </Grid>
        <Grid item xs={8}>
          <InputRadio key_num={"a26_ha"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          【ニ 検査済証】
        </Grid>
        <Grid item xs={8}>
          <InputRadio key_num={"a26_ni1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={8}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          交付番号
        </Grid>
        <Grid item xs={3}>
          <InputDate key_num1={"a26_ni2_1"} placeholderText={"交付年月日"} />
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            key_num={"a26_ni3"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          交付者
        </Grid>
        <Grid item xs={6}>
          <InputRadio key_num={"a26_ni4"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextField key_num={"a26_ni5"} />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          【ホ 維持保全に関する準則又は計画】
        </Grid>
        <Grid item xs={8}>
          <InputRadio key_num={"a26_ho"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={8}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={4} sx={{ mb: 1 }}>
          【ヘ 前回の調査に関する書類の写し】
        </Grid>
        <Grid item xs={8} sx={{ mb: 1 }}>
          <InputRadio key_num={"a26_he"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </div>
  );
});

export const A27 = React.memo(() => {
  return (
    <div>
      【7 備考】
      <InputTextFieldMulti key_num={"a27_1"} />
    </div>
  );
});
