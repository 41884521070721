export const architectureDataSet = {
  a0: {
    a01_i1: "",
    a01_i2: "",
    a01_ro1: "",
    a01_ro2: 1,
    a01_ha: "",
    a01_ni: "",
    a01_ho_1: null,

    a02_i1: "",
    a02_i2: "",
    a02_ro1: "",
    a02_ro2: "",
  },
  a1: {
    a11_i: "",
    a11_ro: "",
    a11_ha: "",
    a11_ni: "",
    a11_ho: "",

    a12_i: "",
    a12_ro: "",
    a12_ha: "",
    a12_ni: "",
    a12_ho: "",

    a13_ai1: "一級建築士",
    a13_ai2: "大臣登録",
    a13_ai3: "",
    a13_ai4: "",
    a13_aro: "",
    a13_aha: "",
    a13_ani1: "",
    a13_ani2: "一級建築士事務所",
    a13_ani3: "",
    a13_ani4: "",
    a13_aho: "",
    a13_ahe: "",
    a13_ato: "",
    a13_bi1: "一級建築士",
    a13_bi2: "大臣登録",
    a13_bi3: "",
    a13_bi4: "",
    a13_bro: "",
    a13_bha: "",
    a13_bni1: "",
    a13_bni2: "一級建築士事務所",
    a13_bni3: "",
    a13_bni4: "",
    a13_bho: "",
    a13_bhe: "",
    a13_bto: "",

    a14_i: "",
    a14_ro: "",
    a14_ha: "",
    a14_ni: "",

    a15_i1: "",
    a15_i2: false,
    a15_ro: "",
    a15_ha1: "",
    a15_ha2_1: null,

    a15_ni: "",
  },
  a2: {
    a21_i1: "防火地域",
    a21_i2: "",
    a21_ro: "",

    a22_i1: "鉄筋コンクリート造",
    a22_i2: "",
    a22_ro1: "",
    a22_ro2: "",
    a22_ha: "",
    a22_ni: "",
    a22_ho: "",

    a23_i11: "",
    a23_i121: "",
    a23_i122: "",
    a23_i123: "",
    a23_i131: "",
    a23_i132: "",
    a23_i133: "",
    a23_i141: "",
    a23_i142: "",
    a23_i143: "",

    a23_i21: "",
    a23_i221: "",
    a23_i222: "",
    a23_i223: "",
    a23_i231: "",
    a23_i232: "",
    a23_i233: "",
    a23_i241: "",
    a23_i242: "",
    a23_i243: "",

    a23_i31: "",
    a23_i321: "",
    a23_i322: "",
    a23_i323: "",
    a23_i331: "",
    a23_i332: "",
    a23_i333: "",
    a23_i341: "",
    a23_i342: "",
    a23_i343: "",

    a23_i41: "",
    a23_i421: "",
    a23_i422: "",
    a23_i423: "",
    a23_i431: "",
    a23_i432: "",
    a23_i433: "",
    a23_i441: "",
    a23_i442: "",
    a23_i443: "",

    a23_i51: "",
    a23_i521: "",
    a23_i522: "",
    a23_i523: "",
    a23_i531: "",
    a23_i532: "",
    a23_i533: "",
    a23_i541: "",
    a23_i542: "",
    a23_i543: "",

    a23_ro121: "",
    a23_ro122: "",
    a23_ro123: "",
    a23_ro131: "",
    a23_ro132: "",
    a23_ro133: "",
    a23_ro141: "",
    a23_ro142: "",
    a23_ro143: "",

    a24_1: false,
    a24_2: false,
    a24_31: false,
    a24_32: "",
    a24_41: false,
    a24_42: "",
    a24_5: false,
    a24_61: false,
    a24_62: "",

    a25_11_1: null,
    a25_12: "",
    a25_21_1: null,
    a25_22: "",
    a25_31_1: null,
    a25_32: "",
    a25_41_1: null,
    a25_42: "",

    a26_i1: "",
    a26_i2: false,
    a26_ro1: "",
    a26_ro2_1: null,
    a26_ro3: "",
    a26_ro4: "",
    a26_ro5: "",
    a26_ha: "",
    a26_ni1: "",
    a26_ni2_1: null,
    a26_ni3: "",
    a26_ni4: "",
    a26_ni5: "",
    a26_ho: "",
    a26_he: "",

    a27_1: "",
  },
  a3: {
    a31_i_1: null,
    a31_ro1: "",
    a31_ro2_1: null,
    a31_ha1: "",
    a31_ha2_1: null,
    a31_ni1: "",
    a31_ni2_1: null,
    a31_ho1: "",
    a31_ho2_1: null,

    a32_1i1: "",
    a32_1i2: false,
    a32_1ro: "",
    a32_1ha1: "",
    a32_1ha2_1: null,

    a32_2i1: "",
    a32_2i2: false,
    a32_2ro: "",
    a32_2ha1: "",
    a32_2ha2_1: null,

    a32_3i1: "",
    a32_3i2: false,
    a32_3ro: "",
    a32_3ha1: "",
    a32_3ha2_1: null,

    a32_4i1: "",
    a32_4i2: false,
    a32_4ro: "",
    a32_4ha1: "",
    a32_4ha2_1: null,

    a32_5i1: "",
    a32_5i2: false,
    a32_5ro: "",
    a32_5ha1: "",
    a32_5ha2_1: null,

    a32_6i1: "",
    a32_6i2: false,
    a32_6ro: "",
    a32_6ha1: "",
    a32_6ha2_1: null,

    a33_i1: false,
    a33_i2: "",
    a33_i3: false,
    a33_i4: "",
    a33_i5: false,
    a33_ro1: "",
    a33_ro2_1: null,

    a34_i1: "",
    a34_i2_1: null,

    a34_ro1: "",
    a34_ro2_1: null,

    a35_i: "",
    a35_ro: "",
    a35_ha1: "",
    a35_ha2_1: null,

    a36_1: "",
  },
  a4: {
    a4_11_1: null,
    a4_12: "",
    a4_13: "",
    a4_14_1: null,
    a4_15: "",
    a4_21_1: null,
    a4_22: "",
    a4_23: "",
    a4_24_1: null,
    a4_25: "",
    a4_31_1: null,
    a4_32: "",
    a4_33: "",
    a4_34_1: null,
    a4_35: "",
    a4_41_1: null,
    a4_42: "",
    a4_43: "",
    a4_44_1: null,
    a4_45: "",
    a4_51_1: null,
    a4_52: "",
    a4_53: "",
    a4_54_1: null,
    a4_55: "",
  },
  a5: {
    a50_11: "",
    a50_12: "1",
    a50_21: "",
    a50_22: "",
    a50_31: "",
    a50_32: "",

    a51_1_11: "",
    a51_1_12: false,
    a51_1_13: "1",
    a51_1_21: "",
    a51_1_22: false,
    a51_1_23: "1",
    a51_1_31: "",
    a51_1_32: false,
    a51_1_33: "1",
    a51_1_41: "",
    a51_1_42: false,
    a51_1_43: "1",
    a51_1_51: "",
    a51_1_52: false,
    a51_1_53: "1",
    a51_1_61: "",
    a51_1_62: false,
    a51_1_63: "1",
    a51_1_71: "",
    a51_1_72: false,
    a51_1_73: "1",
    a51_1_81: "",
    a51_1_82: false,
    a51_1_83: "1",
    a51_1_91: "",
    a51_1_92: false,
    a51_1_93: "1",

    a51_2_11: "",
    a51_2_12: false,
    a51_2_13: "1",
    a51_2_21: "",
    a51_2_22: false,
    a51_2_23: "1",
    a51_2_31: "",
    a51_2_32: false,
    a51_2_33: "1",
    a51_2_41: "",
    a51_2_42: false,
    a51_2_43: "1",
    a51_2_51: "",
    a51_2_52: false,
    a51_2_53: "1",
    a51_2_61: "",
    a51_2_62: false,
    a51_2_63: "1",
    a51_2_71: "",
    a51_2_72: false,
    a51_2_73: "1",
    a51_2_81: "",
    a51_2_82: false,
    a51_2_83: "1",
    a51_2_91: "",
    a51_2_92: false,
    a51_2_93: "1",
    a51_2_101: "",
    a51_2_102: false,
    a51_2_103: "1",
    a51_2_111: "",
    a51_2_112: false,
    a51_2_113: "1",
    a51_2_121: "",
    a51_2_122: false,
    a51_2_123: "1",
    a51_2_131: "",
    a51_2_132: false,
    a51_2_133: "1",
    a51_2_141: "",
    a51_2_142: false,
    a51_2_143: "1",
    a51_2_151: "",
    a51_2_152: false,
    a51_2_153: "1",
    a51_2_161: "",
    a51_2_162: false,
    a51_2_163: "1",
    a51_2_171: "",
    a51_2_172: false,
    a51_2_173: "1",
    a51_2_181: "",
    a51_2_182: false,
    a51_2_183: "1",

    a51_3_11: "",
    a51_3_12: false,
    a51_3_13: "1",
    a51_3_21: "",
    a51_3_22: false,
    a51_3_23: "1",
    a51_3_31: "",
    a51_3_32: false,
    a51_3_33: "1",
    a51_3_41: "",
    a51_3_42: false,
    a51_3_43: "1",
    a51_3_51: "",
    a51_3_52: false,
    a51_3_53: "1",
    a51_3_61: "",
    a51_3_62: false,
    a51_3_63: "1",
    a51_3_71: "",
    a51_3_72: false,
    a51_3_73: "1",
    a51_3_81: "",
    a51_3_82: false,
    a51_3_83: "1",
    a51_3_91: "",
    a51_3_92: false,
    a51_3_93: "1",

    a51_4_11: "",
    a51_4_12: false,
    a51_4_13: "1",
    a51_4_21: "",
    a51_4_22: false,
    a51_4_23: "1",
    a51_4_31: "",
    a51_4_32: false,
    a51_4_33: "1",
    a51_4_41: "",
    a51_4_42: false,
    a51_4_43: "1",
    a51_4_51: "",
    a51_4_52: false,
    a51_4_53: "1",
    a51_4_61: "",
    a51_4_62: false,
    a51_4_63: "1",
    a51_4_71: "",
    a51_4_72: false,
    a51_4_73: "1",
    a51_4_81: "",
    a51_4_82: false,
    a51_4_83: "1",
    a51_4_91: "",
    a51_4_92: false,
    a51_4_93: "1",
    a51_4_101: "",
    a51_4_102: false,
    a51_4_103: "1",
    a51_4_111: "",
    a51_4_112: false,
    a51_4_113: "1",
    a51_4_121: "",
    a51_4_122: false,
    a51_4_123: "1",
    a51_4_131: "",
    a51_4_132: false,
    a51_4_133: "1",
    a51_4_141: "",
    a51_4_142: false,
    a51_4_143: "1",
    a51_4_151: "",
    a51_4_152: false,
    a51_4_153: "1",
    a51_4_161: "",
    a51_4_162: false,
    a51_4_163: "1",
    a51_4_171: "",
    a51_4_172: false,
    a51_4_173: "1",
    a51_4_181: "",
    a51_4_182: false,
    a51_4_183: "1",
    a51_4_191: "",
    a51_4_192: false,
    a51_4_193: "1",
    a51_4_201: "",
    a51_4_202: false,
    a51_4_203: "1",
    a51_4_211: "",
    a51_4_212: false,
    a51_4_213: "1",
    a51_4_221: "",
    a51_4_222: false,
    a51_4_223: "1",
    a51_4_231: "",
    a51_4_232: false,
    a51_4_233: "1",
    a51_4_241: "",
    a51_4_242: false,
    a51_4_243: "1",
    a51_4_251: "",
    a51_4_252: false,
    a51_4_253: "1",
    a51_4_261: "",
    a51_4_262: false,
    a51_4_263: "1",
    a51_4_271: "",
    a51_4_272: false,
    a51_4_273: "1",
    a51_4_281: "",
    a51_4_282: false,
    a51_4_283: "1",
    a51_4_291: "",
    a51_4_292: false,
    a51_4_293: "1",
    a51_4_301: "",
    a51_4_302: false,
    a51_4_303: "1",
    a51_4_311: "",
    a51_4_312: false,
    a51_4_313: "1",
    a51_4_321: "",
    a51_4_322: false,
    a51_4_323: "1",
    a51_4_331: "",
    a51_4_332: false,
    a51_4_333: "1",
    a51_4_341: "",
    a51_4_342: false,
    a51_4_343: "1",
    a51_4_351: "",
    a51_4_352: false,
    a51_4_353: "1",
    a51_4_361: "",
    a51_4_362: false,
    a51_4_363: "1",
    a51_4_371: "",
    a51_4_372: false,
    a51_4_373: "1",
    a51_4_381: "",
    a51_4_382: false,
    a51_4_383: "1",
    a51_4_391: "",
    a51_4_392: false,
    a51_4_393: "1",
    a51_4_401: "",
    a51_4_402: false,
    a51_4_403: "1",
    a51_4_411: "",
    a51_4_412: false,
    a51_4_413: "1",
    a51_4_421: "",
    a51_4_422: false,
    a51_4_423: "1",
    a51_4_431: "",
    a51_4_432: false,
    a51_4_433: "1",
    a51_4_441: "",
    a51_4_442: false,
    a51_4_443: "1",
    a51_4_451: "",
    a51_4_452: false,
    a51_4_453: "1",
    a51_4_461: "",
    a51_4_462: false,
    a51_4_463: "1",
    a51_4_471: "",
    a51_4_472: false,
    a51_4_473: "1",

    a51_5_11: "",
    a51_5_12: false,
    a51_5_13: "1",
    a51_5_21: "",
    a51_5_22: false,
    a51_5_23: "1",
    a51_5_31: "",
    a51_5_32: false,
    a51_5_33: "1",
    a51_5_41: "",
    a51_5_42: false,
    a51_5_43: "1",
    a51_5_51: "",
    a51_5_52: false,
    a51_5_53: "1",
    a51_5_61: "",
    a51_5_62: false,
    a51_5_63: "1",
    a51_5_71: "",
    a51_5_72: false,
    a51_5_73: "1",
    a51_5_81: "",
    a51_5_82: false,
    a51_5_83: "1",
    a51_5_91: "",
    a51_5_92: false,
    a51_5_93: "1",
    a51_5_101: "",
    a51_5_102: false,
    a51_5_103: "1",
    a51_5_111: "",
    a51_5_112: false,
    a51_5_113: "1",
    a51_5_121: "",
    a51_5_122: false,
    a51_5_123: "1",
    a51_5_131: "",
    a51_5_132: false,
    a51_5_133: "1",
    a51_5_141: "",
    a51_5_142: false,
    a51_5_143: "1",
    a51_5_151: "",
    a51_5_152: false,
    a51_5_153: "1",
    a51_5_161: "",
    a51_5_162: false,
    a51_5_163: "1",
    a51_5_171: "",
    a51_5_172: false,
    a51_5_173: "1",
    a51_5_181: "",
    a51_5_182: false,
    a51_5_183: "1",
    a51_5_191: "",
    a51_5_192: false,
    a51_5_193: "1",
    a51_5_201: "",
    a51_5_202: false,
    a51_5_203: "1",
    a51_5_211: "",
    a51_5_212: false,
    a51_5_213: "1",
    a51_5_221: "",
    a51_5_222: false,
    a51_5_223: "1",
    a51_5_231: "",
    a51_5_232: false,
    a51_5_233: "1",
    a51_5_241: "",
    a51_5_242: false,
    a51_5_243: "1",
    a51_5_251: "",
    a51_5_252: false,
    a51_5_253: "1",
    a51_5_261: "",
    a51_5_262: false,
    a51_5_263: "1",
    a51_5_271: "",
    a51_5_272: false,
    a51_5_273: "1",
    a51_5_281: "",
    a51_5_282: false,
    a51_5_283: "1",
    a51_5_291: "",
    a51_5_292: false,
    a51_5_293: "1",
    a51_5_301: "",
    a51_5_302: false,
    a51_5_303: "1",
    a51_5_311: "",
    a51_5_312: false,
    a51_5_313: "1",
    a51_5_321: "",
    a51_5_322: false,
    a51_5_323: "1",
    a51_5_331: "",
    a51_5_332: false,
    a51_5_333: "1",
    a51_5_341: "",
    a51_5_342: false,
    a51_5_343: "1",
    a51_5_351: "",
    a51_5_352: false,
    a51_5_353: "1",
    a51_5_361: "",
    a51_5_362: false,
    a51_5_363: "1",
    a51_5_371: "",
    a51_5_372: false,
    a51_5_373: "1",
    a51_5_381: "",
    a51_5_382: false,
    a51_5_383: "1",
    a51_5_391: "",
    a51_5_392: false,
    a51_5_393: "1",
    a51_5_401: "",
    a51_5_402: false,
    a51_5_403: "1",

    a51_6_11: "",
    a51_6_12: false,
    a51_6_13: "1",
    a51_6_21: "",
    a51_6_22: false,
    a51_6_23: "1",
    a51_6_31: "",
    a51_6_32: false,
    a51_6_33: "1",
    a51_6_41: "",
    a51_6_42: false,
    a51_6_43: "1",
    a51_6_51: "",
    a51_6_52: false,
    a51_6_53: "1",
    a51_6_61: "",
    a51_6_62: false,
    a51_6_63: "1",
    a51_6_71: "",
    a51_6_72: false,
    a51_6_73: "1",
    a51_6_81: "",
    a51_6_82: false,
    a51_6_83: "1",
    a51_6_91: "",
    a51_6_92: false,
    a51_6_93: "1",

    a51_8_11: "",
    a51_8_12: "",

    a51_9_11: "",
    a51_9_12: "",
    a51_9_13: "",
    a51_9_14: "",
    a51_9_15_1: null,

    a51_9_21: "",
    a51_9_22: "",
    a51_9_23: "",
    a51_9_24: "",
    a51_9_25_1: null,

    a51_9_31: "",
    a51_9_32: "",
    a51_9_33: "",
    a51_9_34: "",
    a51_9_35_1: null,

    a51_9_41: "",
    a51_9_42: "",
    a51_9_43: "",
    a51_9_44: "",
    a51_9_45_1: null,

    a51_9_51: "",
    a51_9_52: "",
    a51_9_53: "",
    a51_9_54: "",
    a51_9_55_1: null,
  },
  a9: {
    a91_1: "特定行政庁　静岡県知事", //提出先

    a92_1: "", //棟名
  },
};
