import React, { useEffect } from "react";
import { usePostalJp } from "use-postal-jp";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";

import {
  CHANGE_DATA_ARCHITECTURE,
  CHANGE_DATA_EQUIPMENT,
  CHANGE_DATA_FIREEQUIPMENT,
  ARCHITECTURE,
  EQUIPMENT,
  FIREEQUIPMENT,
} from "./Word";

export const InputZipCode = React.memo(
  ({ key_num, target_key_num, type, label, page }) => {
    const zipCode = useSelector((state) => state[type]["B1"][page][key_num]);

    const addressCode = useSelector(
      (state) => state[type]["B1"][page][target_key_num]
    );

    const [address] = usePostalJp(zipCode, zipCode.length >= 7);

    const dispatch = useDispatch();

    useEffect(() => {
      if (address && addressCode === "") {
        switch (type) {
          case ARCHITECTURE:
            dispatch({
              type: CHANGE_DATA_ARCHITECTURE,
              payload: { a02_i2: `${address.address1}${address.address2}` },
              bulding: "B1",
              page,
            });
            break;
          case EQUIPMENT:
            dispatch({
              type: CHANGE_DATA_EQUIPMENT,
              payload: { e02_i2: `${address.address1}${address.address2}` },
              bulding: "B1",
              page,
            });
            break;
          case FIREEQUIPMENT:
            dispatch({
              type: CHANGE_DATA_FIREEQUIPMENT,
              payload: { f02_i2: `${address.address1}${address.address2}` },
              bulding: "B1",
              page,
            });
            break;
          default:
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const displyaLabel = label ? label : "";

    const change = (key, value) => {
      switch (type) {
        case ARCHITECTURE:
          dispatch({
            type: CHANGE_DATA_ARCHITECTURE,
            payload: { [key]: value },
            bulding: "B1",
            page,
          });
          break;
        case EQUIPMENT:
          dispatch({
            type: CHANGE_DATA_EQUIPMENT,
            payload: { [key]: value },
            bulding: "B1",
            page,
          });
          break;
        case FIREEQUIPMENT:
          dispatch({
            type: CHANGE_DATA_FIREEQUIPMENT,
            payload: { [key]: value },
            bulding: "B1",
            page,
          });
          break;
        default:
      }
    };

    return (
      <TextField
        fullWidth
        value={zipCode}
        variant="filled"
        onChange={(e) => {
          change(key_num, e.target.value);
        }}
        label={displyaLabel}
      />
    );
  }
);
