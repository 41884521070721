import React from "react";

import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  InputTextField,
  InputTextFieldEquip,
  InputTextFieldFireEquip,
} from "../Parts/InputText";
import {
  InputRadio,
  InputRadioEquip,
  InputRadioFireEquip,
} from "../Parts/InputRadio";
import {
  InputCheakBox,
  InputCheakBoxEquip,
  InputCheakBoxFireEquip,
} from "../Parts/InputCheakBox";

export const CheckForm = React.memo(({ num, row }) => {
  return (
    <>
      <InputRadio key_num={`a51_${num}_${row}1`}>
        <Grid container alignItems="center" sx={{}}>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="対象外"
              control={<Radio />}
              label="対象外"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="指摘なし"
              control={<Radio />}
              label="指摘なし"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="要是正"
              control={<Radio />}
              label="要是正"
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputCheakBox key_num={`a51_${num}_${row}2`} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputTextField key_num={`a51_${num}_${row}3`} />
          </Grid>
        </Grid>
      </InputRadio>
    </>
  );
});

export const CheckFormEquipment = React.memo(({ type, num, row }) => {
  return (
    <>
      <InputRadioEquip key_num={`e41_${type}_${num}_${row}1`}>
        <Grid container alignItems="center" sx={{}}>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="対象外"
              control={<Radio />}
              label="対象外"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="指摘なし"
              control={<Radio />}
              label="指摘なし"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="要是正"
              control={<Radio />}
              label="要是正"
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputCheakBoxEquip key_num={`e41_${type}_${num}_${row}2`} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputTextFieldEquip key_num={`e41_${type}_${num}_${row}3`} />
          </Grid>
        </Grid>
      </InputRadioEquip>
    </>
  );
});

export const CheckFormFireEquipment = React.memo(({ type, num, row }) => {
  return (
    <>
      <InputRadioFireEquip key_num={`f41_${type}_${num}_${row}1`}>
        <Grid container alignItems="center" sx={{}}>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="対象外"
              control={<Radio />}
              label="対象外"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="指摘なし"
              control={<Radio />}
              label="指摘なし"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <FormControlLabel
              value="要是正"
              control={<Radio />}
              label="要是正"
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputCheakBoxFireEquip key_num={`f41_${type}_${num}_${row}2`} />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ borderLeft: 1, height: "57px", borderBottom: 1 }}
          >
            <InputTextFieldFireEquip key_num={`f41_${type}_${num}_${row}3`} />
          </Grid>
        </Grid>
      </InputRadioFireEquip>
    </>
  );
});
