export const CheackBoxKeys = [
  "a1.a15_i2",
  "a2.a24_1",
  "a2.a24_2",
  "a2.a24_31",
  "a2.a24_41",
  "a2.a24_5",
  "a2.a24_61",
  "a2.a26_i2",
  "a3.a32_1i2",
  "a3.a32_2i2",
  "a3.a32_3i2",
  "a3.a32_4i2",
  "a3.a32_5i2",
  "a3.a32_6i2",
  "a3.a33_i1",
  "a3.a33_i3",
  "a3.a33_i5",
  "a5.a51_1_12",
  "a5.a51_1_22",
  "a5.a51_1_32",
  "a5.a51_1_42",
  "a5.a51_1_52",
  "a5.a51_1_62",
  "a5.a51_1_72",
  "a5.a51_1_82",
  "a5.a51_1_92",
  "a5.a51_2_12",
  "a5.a51_2_22",
  "a5.a51_2_32",
  "a5.a51_2_42",
  "a5.a51_2_52",
  "a5.a51_2_62",
  "a5.a51_2_72",
  "a5.a51_2_82",
  "a5.a51_2_92",
  "a5.a51_2_102",
  "a5.a51_2_112",
  "a5.a51_2_122",
  "a5.a51_2_132",
  "a5.a51_2_142",
  "a5.a51_2_152",
  "a5.a51_2_162",
  "a5.a51_2_172",
  "a5.a51_2_182",
  "a5.a51_3_12",
  "a5.a51_3_22",
  "a5.a51_3_32",
  "a5.a51_3_42",
  "a5.a51_3_52",
  "a5.a51_3_62",
  "a5.a51_3_72",
  "a5.a51_3_82",
  "a5.a51_3_92",
  "a5.a51_4_12",
  "a5.a51_4_22",
  "a5.a51_4_32",
  "a5.a51_4_42",
  "a5.a51_4_52",
  "a5.a51_4_62",
  "a5.a51_4_72",
  "a5.a51_4_82",
  "a5.a51_4_92",
  "a5.a51_4_102",
  "a5.a51_4_112",
  "a5.a51_4_122",
  "a5.a51_4_132",
  "a5.a51_4_142",
  "a5.a51_4_152",
  "a5.a51_4_162",
  "a5.a51_4_172",
  "a5.a51_4_182",
  "a5.a51_4_192",
  "a5.a51_4_202",
  "a5.a51_4_212",
  "a5.a51_4_222",
  "a5.a51_4_232",
  "a5.a51_4_242",
  "a5.a51_4_252",
  "a5.a51_4_262",
  "a5.a51_4_272",
  "a5.a51_4_282",
  "a5.a51_4_292",
  "a5.a51_4_302",
  "a5.a51_4_312",
  "a5.a51_4_322",
  "a5.a51_4_332",
  "a5.a51_4_342",
  "a5.a51_4_352",
  "a5.a51_4_362",
  "a5.a51_4_372",
  "a5.a51_4_382",
  "a5.a51_4_392",
  "a5.a51_4_402",
  "a5.a51_4_412",
  "a5.a51_4_422",
  "a5.a51_4_432",
  "a5.a51_4_442",
  "a5.a51_4_452",
  "a5.a51_4_462",
  "a5.a51_4_472",
  "a5.a51_5_12",
  "a5.a51_5_22",
  "a5.a51_5_32",
  "a5.a51_5_42",
  "a5.a51_5_52",
  "a5.a51_5_62",
  "a5.a51_5_72",
  "a5.a51_5_82",
  "a5.a51_5_92",
  "a5.a51_5_102",
  "a5.a51_5_112",
  "a5.a51_5_122",
  "a5.a51_5_132",
  "a5.a51_5_142",
  "a5.a51_5_152",
  "a5.a51_5_162",
  "a5.a51_5_172",
  "a5.a51_5_182",
  "a5.a51_5_192",
  "a5.a51_5_202",
  "a5.a51_5_212",
  "a5.a51_5_222",
  "a5.a51_5_232",
  "a5.a51_5_242",
  "a5.a51_5_252",
  "a5.a51_5_262",
  "a5.a51_5_272",
  "a5.a51_5_282",
  "a5.a51_5_292",
  "a5.a51_5_302",
  "a5.a51_5_312",
  "a5.a51_5_322",
  "a5.a51_5_332",
  "a5.a51_5_342",
  "a5.a51_5_352",
  "a5.a51_5_362",
  "a5.a51_5_372",
  "a5.a51_5_382",
  "a5.a51_5_392",
  "a5.a51_5_402",
  "a5.a51_6_12",
  "a5.a51_6_22",
  "a5.a51_6_32",
  "a5.a51_6_42",
  "a5.a51_6_52",
  "a5.a51_6_62",
  "a5.a51_6_72",
  "a5.a51_6_82",
  "a5.a51_6_92",
  "a5.a51_7_12",
  "a5.a51_7_22",
  "a5.a51_7_32",
];

export const DateKeys = [
  "a0.a01_ho_1",
  "a1.a15_ha2_1",
  "a2.a25_11_1",
  "a2.a25_21_1",
  "a2.a25_31_1",
  "a2.a25_41_1",
  "a2.a26_ro2_1",
  "a2.a26_ni2_1",
  "a3.a31_i_1",
  "a3.a31_ro2_1",
  "a3.a31_ha2_1",
  "a3.a31_ni2_1",
  "a3.a31_ho2_1",
  "a3.a32_1ha2_1",
  "a3.a32_2ha2_1",
  "a3.a32_3ha2_1",
  "a3.a32_4ha2_1",
  "a3.a32_5ha2_1",
  "a3.a32_6ha2_1",
  "a3.a33_ro2_1",
  "a3.a34_i2_1",
  "a3.a34_ro2_1",
  "a3.a35_ha2_1",
  "a4.a4_11_1",
  "a4.a4_14_1",
  "a4.a4_21_1",
  "a4.a4_24_1",
  "a4.a4_31_1",
  "a4.a4_34_1",
  "a4.a4_41_1",
  "a4.a4_44_1",
  "a4.a4_51_1",
  "a4.a4_54_1",
  "a5.a51_9_15_1",
  "a5.a51_9_25_1",
  "a5.a51_9_35_1",
  "a5.a51_9_45_1",
  "a5.a51_9_55_1",
];
