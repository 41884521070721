import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import GenericTemplate from "../GenericTemplate";

import { InputTextField, InputTextFieldMulti } from "../Parts/InputText";
import { InputRadio } from "../Parts/InputRadio";
import { InputCheakBox } from "../Parts/InputCheakBox";
import { InputDate, InputDateMonth } from "../Parts/InputDate";
import { COPYDATA_ARCHITECTURE } from "../Parts/Word";

const A3 = () => {
  const location = useLocation();
  return (
    <GenericTemplate
      title="第３面　調査等の概要"
      type={"特殊建築物等"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <A30 />
      <A31 />
      <A32 />
      <A33 />
      <A34 />
      <A35 />
      <A36 />
    </GenericTemplate>
  );
};

export default React.memo(A3);

export const A30 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_ARCHITECTURE,
      bulding,
      page,
      page_moto: "a3",
    });
  };
  if (location.pathname !== "/a3/a3/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#a1887f" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

export const A31 = React.memo(() => {
  return (
    <div>
      【１　調査及び検査の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【イ 今回の調査】
        </Grid>
        <Grid item xs={10}>
          <InputDate key_num1={"a31_i_1"} placeholderText={"年月日"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【ロ 前回の調査】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a31_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDate key_num1={"a31_ro2_1"} placeholderText={"年月日"} />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【ハ 建築設備の検査】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a31_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDate key_num1={"a31_ha2_1"} placeholderText={"年月日"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【ニ 昇降機等の検査】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a31_ni1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDate key_num1={"a31_ni2_1"} placeholderText={"年月日"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 1 }}>
          【ホ 防火設備の検査】
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputRadio key_num={"a31_ho1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDate key_num1={"a31_ho2_1"} placeholderText={"年月日"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </div>
  );
});

export const A32 = React.memo(() => {
  return (
    <div>
      【２　調査の状況】
      <div>
        （敷地及び地盤）
        <A321 row={1} />
        （建築物の外部）
        <A321 row={2} />
        （屋上及び屋根）
        <A321 row={3} />
        （建築物の内部）
        <A321 row={4} />
        （避難施設等）
        <A321 row={5} />
        （その他）
        <A321 row={6} borderBottom={1} />
      </div>
    </div>
  );
});

export const A321 = React.memo(({ row, borderBottom = 0 }) => {
  return (
    <>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【イ 指摘の内容】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={`a32_${row}i1`}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBox key_num={`a32_${row}i2`} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          【ロ 指摘の概要】
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMulti key_num={`a32_${row}ro`} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom }}
      >
        <Grid item xs={2} sx={{ mb: 2 }}>
          【ハ 改善予定の有無】
        </Grid>
        <Grid item xs={10} sx={{ mb: 2 }}>
          <InputRadio key_num={`a32_${row}ha1`}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} sx={{ mb: 2 }}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={4} sx={{ mb: 2 }}>
                <InputDateMonth key_num1={`a32_${row}ha2_1`} />
              </Grid>
              <Grid item xs={6} sx={{ mb: 2 }}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </>
  );
});

export const A33 = React.memo(() => {
  return (
    <div>
      【３　石綿を添加した建築材料の調査状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 2 }}>
          【イ 該当建築材料の有無】
        </Grid>
        <Grid item xs={4} sx={{ mb: 2 }}>
          <InputCheakBox key_num={"a33_i1"} />
          有（飛散防止措置無）
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldMulti key_num={"a33_i2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 2 }}></Grid>
        <Grid item xs={4} sx={{ mb: 2 }}>
          <InputCheakBox key_num={"a33_i3"} />
          有（飛散防止措置有）
        </Grid>
        <Grid item xs={6}>
          <InputTextField key_num={"a33_i4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 2 }}></Grid>
        <Grid item xs={4} sx={{ mb: 2 }}>
          <InputCheakBox key_num={"a33_i5"} />無
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          【ロ 措置予定の有無】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a33_ro1"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonth key_num1={"a33_ro2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </div>
  );
});

export const A34 = React.memo(() => {
  return (
    <div>
      【４　耐震診断及び耐震改修の調査状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={3} sx={{ mb: 2 }}>
          【イ 耐震診断の実施の有無】
        </Grid>
        <Grid item xs={9}>
          <InputRadio key_num={"a34_i1"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
              <Grid item xs={4}>
                <InputDateMonth
                  key_num1={"a34_i2_1"}
                  placeholderText={"実施予定年月"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ mb: 2 }}>
          【ロ 耐震改修の実施の有無】
        </Grid>
        <Grid item xs={9}>
          <InputRadio key_num={"a34_ro1"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
              <Grid item xs={4}>
                <InputDateMonth
                  key_num1={"a34_ro2_1"}
                  placeholderText={"実施予定年月"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="対象外"
                  control={<Radio />}
                  label="対象外"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </div>
  );
});

export const A35 = React.memo(() => {
  return (
    <div>
      【５　建築物等に係る不具合等の状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 2 }}>
          【イ 不具合等】
        </Grid>
        <Grid item xs={10} sx={{ mb: 2 }}>
          <InputRadio key_num={"a35_i"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={10}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 2 }}>
          【ロ　不具合等の記録】
        </Grid>
        <Grid item xs={10} sx={{ mb: 2 }}>
          <InputRadio key_num={"a35_ro"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={10}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 2 }}>
          【ハ　改善の状況】
        </Grid>
        <Grid item xs={10}>
          <InputRadio key_num={"a35_ha1"}>
            <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
              <Grid item xs={2}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDateMonth key_num1={"a35_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
            </Grid>
          </InputRadio>
        </Grid>
      </Grid>
    </div>
  );
});

export const A36 = React.memo(() => {
  return (
    <div>
      【 備考】
      <InputTextFieldMulti key_num={"a36_1"} />
    </div>
  );
});
