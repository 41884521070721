import React from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { InputTextFieldFireEquip } from "../Parts/InputText";
import { InputSelectFireEquip } from "../Parts/InputSelect";
import { InputDateFireEquip } from "../Parts/InputDate";
import { FIREEQUIPMENT } from "../Parts/Word";
import { InputZipCode } from "../Parts/InputZipCode";

import GenericTemplate from "../GenericTemplate";

const F0 = () => {
  const location = useLocation();
  return (
    <GenericTemplate type={"防火設備"} selectedIndex={location.pathname}>
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              台帳番号
            </Grid>
            <Grid item xs={2}>
              <InputSelectFireEquip
                key_num={"f01_i1"}
                items={["A", "B", "C", "F", "G", "H"]}
              />
            </Grid>
            <Grid item xs={1} textAlign="center" sx={{ fontSize: 40 }}>
              -
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldFireEquip key_num={"f01_i2"} />
            </Grid>
            <Grid item xs={2}>
              施設名称
            </Grid>
            <Grid item xs={7}>
              <InputTextFieldFireEquip key_num={"f01_ro1"} />
            </Grid>
            <Grid item xs={1}>
              棟数
            </Grid>
            <Grid item xs={2}>
              <InputSelectFireEquip
                key_num={"f01_ro2"}
                items={[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20,
                ]}
              />
            </Grid>
            <Grid item xs={2}>
              報告者氏名
            </Grid>
            <Grid item xs={10}>
              <InputTextFieldFireEquip key_num={"f01_ha"} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              検査者氏名
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputTextFieldFireEquip key_num={"f01_ni"} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2} sx={{ mb: 3 }}>
              報告年月日
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputDateFireEquip
                key_num1={"f01_ho_1"}
                placeholderText={"報告年月日"}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}>
              報告済証（ステッカー返却先）
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={2}>
              郵便番号
            </Grid>
            <Grid item xs={10}>
              <InputZipCode
                key_num={"f02_i1"}
                target_key_num={"f02_i2"}
                type={FIREEQUIPMENT}
                label={"〒"}
                page={"f0"}
              />
            </Grid>
            <Grid item xs={2}>
              住所
            </Grid>
            <Grid item xs={10}>
              <InputTextFieldFireEquip key_num={"f02_i2"} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2}>
              法人名
            </Grid>
            <Grid item xs={10}>
              <InputTextFieldFireEquip key_num={"f02_ro1"} />
            </Grid>
            <Grid item xs={2} sx={{ mb: 3 }}>
              氏名
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputTextFieldFireEquip key_num={"f02_ro2"} />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={12}></Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ mb: 1, borderBottom: 1 }}
          >
            <Grid item xs={2} sx={{ mb: 3 }}>
              報告先
            </Grid>
            <Grid item xs={10} sx={{ mb: 3 }}>
              <InputTextFieldFireEquip key_num={"f91_1"} page={"f9"} />
            </Grid>
          </Grid>
        </div>
      </Box>
    </GenericTemplate>
  );
};

export default React.memo(F0);
