import React, { useState } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";

import GenericTemplate from "./GenericTemplate";

const tiers = [
  {
    title: "特殊建築物",
    subheader: "　",
    description: ["敷地及び地盤", "建築物の外部", "屋上及び屋根など"],
    buttonText: "報告書作成",
    buttonVariant: "outlined",
    href: "/a0/a0/B1",
    type: "特殊建築物等",
  },
  {
    title: "建築設備",
    subheader: "（昇降機を除く。）",
    description: ["換気設備", "排煙設備", "非常用の照明装置"],
    buttonText: "報告書作成",
    buttonVariant: "outlined",
    href: "/e0/e0/B1",
    type: "建築設備",
  },
  {
    title: "防火設備",
    subheader: "　",
    description: ["防火扉", "防火シャッター", "耐火クロススクリーン", ""],
    buttonText: "報告書作成",
    buttonVariant: "outlined",
    href: "/f0/f0/B1",
    type: "防火設備",
  },
];

const TopPage = () => {
  const [type, setType] = useState("");
  return (
    <GenericTemplate type={type}>
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />

        {/* Hero unit */}
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 1, pb: 6 }}
        >
          {/* <Typography variant="h6" align="center" color="red" component="p">
            お知らせ(2022.10.14)
          </Typography>
          <Typography variant="h6" align="left" color="red" component="p">
            ・棟名が入力できるようになりました。
          </Typography>
          <Typography variant="h6" align="left" color="red" component="p">
            ・提出先宛名が変更できるようになりました。
          </Typography> */}
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            定期報告書作成
          </Typography>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            支援サイト
          </Typography>
          <Typography
            variant="h5"
            align="left"
            color="text.secondary"
            component="p"
          >
            静岡県電子申請により建築物定期報告書を提出する際の報告書作成を支援するものです。
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      onClick={() => {
                        setType(tier.type);
                      }}
                      component={Link}
                      to={tier.href}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </GenericTemplate>
  );
};

export default TopPage;
