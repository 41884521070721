import React from "react";
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { InputTextFieldMultiFireEquip } from "../Parts/InputText";
import { InputDateMonthFireEquip } from "../Parts/InputDate";

import GenericTemplate from "../GenericTemplate";

const F3 = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="第3面　防火設備に係る不具合の状況"
      type={"防火設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      ></Box>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}></Grid>
      <F311 />
      <F312 num={"1"} row={"1"} />
      <F312 num={"1"} row={"2"} />
      <F312 num={"1"} row={"3"} mb={3} />
    </GenericTemplate>
  );
};

export default React.memo(F3);

const F311 = React.memo(() => {
  return (
    <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={1}>
        不具合等を把握した年月
      </Grid>
      <Grid item xs={3}>
        不具合等の概要
      </Grid>
      <Grid item xs={3}>
        考えられる原因
      </Grid>
      <Grid item xs={1}>
        改善（予定）年月
      </Grid>
      <Grid item xs={4}>
        改善措置の概要等
      </Grid>
    </Grid>
  );
});

const F312 = React.memo(({ num, row, mb = 1, borderBottom = 0 }) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      sx={{ mb: 1, borderBottom }}
    >
      <Grid item xs={1} sx={{ mb }}>
        <InputDateMonthFireEquip
          key_num1={`f3_${num}_${row}1_1`}
          placeholderText={"年月"}
        />
      </Grid>
      <Grid item xs={3} sx={{ mb }}>
        <InputTextFieldMultiFireEquip key_num={`f3_${num}_${row}2`} />
      </Grid>
      <Grid item xs={3} sx={{ mb }}>
        <InputTextFieldMultiFireEquip key_num={`f3_${num}_${row}3`} />
      </Grid>
      <Grid item xs={1} sx={{ mb }}>
        <InputDateMonthFireEquip
          key_num1={`f3_${num}_${row}4_1`}
          placeholderText={"年月"}
        />
      </Grid>
      <Grid item xs={4} sx={{ mb }}>
        <InputTextFieldMultiFireEquip key_num={`f3_${num}_${row}5`} />
      </Grid>
    </Grid>
  );
});
