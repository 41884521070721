import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import {
  InputTextFieldEquip,
  InputTextFieldMultiEquip,
} from "../Parts/InputText";
import { InputDateMonthEquip } from "../Parts/InputDate";
import { Invest0Equip } from "../Parts/Invest0";
import { InvestE10 } from "../Parts/Invest1";
import { CheckFormEquipment } from "../Parts/CheckForm";
import { bangou, ariari, arinashi } from "../Parts/investLine";
import { COPYDATA_EQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";

const EInvestVentilation = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="検査結果表（換気設備）"
      type={"建築設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <EInvest0 />
        <Invest0Equip type={"e40_1_"} />
        <InvestE10 />
        <EInvest11 />
        <EInvest12 />
        <EInvest13 />
        <EInvest15 />
      </Box>
    </GenericTemplate>
  );
};

export default EInvestVentilation;

export const EInvest0 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_EQUIPMENT,
      bulding,
      page,
      page_moto: "e41",
    });
  };

  if (location.pathname !== "/einvestventilation/e41/B1") {
    return (
      <>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#81c783" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
});

const EInvest11 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          1　法第28条第2項又は第3項に基づき換気設備が設けられた居室（換気設備を設けるべき調理室等を除く。）
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          機械換気設備
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械換気設備(中央管理方式の空気調和設備を含む。）の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気機の外気取り入れ口並びに直接外気に開放された給気口及び排気口への雨水等の防止措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気機の外気取り入れ口及び排気機の排気口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の給気口及び排気口の設置位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の給気口及び排気口の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          風道の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          風道の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          給気機又は排気機の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          換気扇による換気の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械換気設備（中央 管理方式の空気調和 設備を含む。）の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の換気量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          中央管理室における制御及び作動状態の監視の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}>
          中央管理方式の空気調和設備
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          空気調和設備の主要機器及び配管の外観
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気調和設備の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気調和設備及び配管の劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気調和設備の運転の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"13"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （14）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          空気ろ過器の点検口
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"14"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （15）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          冷却塔と建築物の他の部分との離隔距離
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"15"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （16）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          空気調和設備の性能
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の温度
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"16"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （17）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の相対湿度
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"17"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （18）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の浮遊粉じん量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"18"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （19）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の一酸化炭素含有率
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"19"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （20）
        </Grid>
        <Grid item xs={1} sx={{ ...arinashi }}></Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の二酸化炭素含有率
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"20"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （21）
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}></Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          各居室の気流
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"1"} row={"21"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest12 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          2　換気設備を設けるべき調理室等
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          自然換気設備及び機械換気設備
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          排気筒、排気フード及び煙突の材質
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （2）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          排気筒、排気フード及び煙突の取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （3）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          給気口、給気筒、排気口、排気筒、排気フード及び煙突の大きさ
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （4）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          給気口、排気口及び排気フードの位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          給気口、給気筒、排気口、排気筒、排気フード及び煙突の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          排気筒及び煙突の断熱の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          排気筒及び煙突と可燃物、電線等との離隔距離
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          煙突等への防火ダンパー、風道等の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}>
          自然換気設備
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          煙突の先端の立ち上がりの状況(密閉型燃焼器具の煙突を除く。)
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"9"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （10）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          機械換気設備
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          煙突に連結した排気筒及び半密閉式瞬間湯沸器等の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"10"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （11）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          換気扇による換気の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"11"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （12）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          給気機又は排気機の設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"12"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （13）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          機械換気設備の換気量
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"2"} row={"13"} />
        </Grid>
      </Grid>
    </>
  );
});

const EInvest13 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{}}>
          ３　法第28条第2項又は第3項に基づき換気設備が設けられた居室等
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （１）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}>
          防火ダン パー等(外壁の開口部で延焼のおそれのある部分に設けるものを除
          く。)
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの設置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"1"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （２）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの取付けの状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"2"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （３）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの作動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"3"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （４）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの劣化及び損傷の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"4"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （5）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの点検口の有無及び大きさ並びに検査口の有無
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"5"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （6）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          防火ダンパーの温度ヒューズ
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"6"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （7）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          壁及び床の防火区画貫通措置部の措置の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"7"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （8）
        </Grid>
        <Grid item xs={2} sx={{ ...arinashi }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          連動型防火ダンパーの煙感知器、熱煙複合式感知器及び熱感知器の位置
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"8"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          （9）
        </Grid>
        <Grid item xs={2} sx={{ ...ariari }}></Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          連動型防火ダンパーの煙感知器、熱煙複合式感知器及び熱感知器との連動の状況
        </Grid>
        <Grid item xs={5} sx={{}}>
          <CheckFormEquipment type={"1"} num={"3"} row={"9"} />
        </Grid>
      </Grid>
    </>
  );
});

// const EInvest14 = React.memo(() => {
//   return (
//     <>
//       <Grid
//         container
//         alignItems="center"
//         sx={{ height: "57px", borderBottom: 1 }}
//       >
//         <Grid item xs={12} sx={{}}>
//           4　上記以外の検査項目
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （１）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_1_4_10"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"1"} num={"4"} row={"1"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （２）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_1_4_20"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"1"} num={"4"} row={"2"} />
//         </Grid>
//       </Grid>
//       <Grid container alignItems="center" sx={{}}>
//         <Grid item xs={1} sx={{ ...bangou, height: "57px" }}>
//           （３）
//         </Grid>
//         <Grid item xs={6} sx={{ ...ariari }}>
//           <InputTextFieldMultiEquip key_num={"e41_1_4_30"} />
//         </Grid>
//         <Grid item xs={5} sx={{}}>
//           <CheckFormEquipment type={"1"} num={"4"} row={"3"} />
//         </Grid>
//       </Grid>
//     </>
//   );
// });

const EInvest15 = React.memo(() => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{ height: "57px", borderBottom: 1 }}
      >
        <Grid item xs={12} sx={{ height: "57px", borderBottom: 1 }}>
          特記事項
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{ height: "57px" }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          番号
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          検査項目
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          指摘の具体的内容等
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          改善策の具体的内容等
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          改善（予定）年月
        </Grid>
      </Grid>
      <EInvest151 low={1} />
      <EInvest151 low={2} />
      <EInvest151 low={3} />
      <EInvest151 low={4} />
      <EInvest151 low={5} />
    </>
  );
});

const EInvest151 = React.memo(({ low }) => {
  return (
    <>
      <Grid container alignItems="center" sx={{ borderBottom: 1 }}>
        <Grid item xs={1} sx={{ ...bangou }}>
          <InputTextFieldEquip key_num={`e41_1_5_${low}1`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_1_5_${low}2`} />
        </Grid>
        <Grid item xs={3} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_1_5_${low}3`} />
        </Grid>
        <Grid item xs={4} sx={{ ...ariari }}>
          <InputTextFieldMultiEquip key_num={`e41_1_5_${low}4`} />
        </Grid>
        <Grid item xs={1} sx={{ ...ariari }}>
          <InputDateMonthEquip key_num1={`e41_1_5_${low}5_1`} />
        </Grid>
      </Grid>
    </>
  );
});
