export const CHANGE_DATA_ARCHITECTURE = "change_data_architecture";

export const CHANGE_DATA_EQUIPMENT = "change_data_equipment";

export const CHANGE_DATA_FIREEQUIPMENT = "change_data_fireEquipment";

export const SET_DATA = "set_data";

export const SET_DATA_EQUIPMENT = "set_data_equipment";

export const SET_DATA_FIREEQUIPMENT = "set_data_fireEquipment";

export const RESET_DATA = "reset_data";

export const SET_LOCALSTORAGE_DATA = "set_localstorage_data";

export const ARCHITECTURE = "Architecture";

export const EQUIPMENT = "Equipment";

export const FIREEQUIPMENT = "FireEquipment";

export const COPYDATA_ARCHITECTURE = "CopyData_Architecture";

export const COPYDATA_EQUIPMENT = "CopyData_Equipment";

export const COPYDATA_FIREEQUIPMENT = "CopyData_FireEquipment";
