import { architectureDataSet } from "./architectureDataSet";
import { equipmentDataSet } from "./equipmentDataSet";
import { fireProtectionFacilityDataSet } from "./fireProtectionFacilityDataSet";

export const initialState = {
  Architecture: {
    B1: architectureDataSet,
    B2: architectureDataSet,
    B3: architectureDataSet,
    B4: architectureDataSet,
    B5: architectureDataSet,
    B6: architectureDataSet,
    B7: architectureDataSet,
    B8: architectureDataSet,
    B9: architectureDataSet,
    B10: architectureDataSet,
    B11: architectureDataSet,
    B12: architectureDataSet,
    B13: architectureDataSet,
    B14: architectureDataSet,
    B15: architectureDataSet,
    B16: architectureDataSet,
    B17: architectureDataSet,
    B18: architectureDataSet,
    B19: architectureDataSet,
    B20: architectureDataSet,
  },
  Equipment: {
    B1: equipmentDataSet,
    B2: equipmentDataSet,
    B3: equipmentDataSet,
    B4: equipmentDataSet,
    B5: equipmentDataSet,
    B6: equipmentDataSet,
    B7: equipmentDataSet,
    B8: equipmentDataSet,
    B9: equipmentDataSet,
    B10: equipmentDataSet,
    B11: equipmentDataSet,
    B12: equipmentDataSet,
    B13: equipmentDataSet,
    B14: equipmentDataSet,
    B15: equipmentDataSet,
    B16: equipmentDataSet,
    B17: equipmentDataSet,
    B18: equipmentDataSet,
    B19: equipmentDataSet,
    B20: equipmentDataSet,
  },
  FireEquipment: {
    B1: fireProtectionFacilityDataSet,
    B2: fireProtectionFacilityDataSet,
    B3: fireProtectionFacilityDataSet,
    B4: fireProtectionFacilityDataSet,
    B5: fireProtectionFacilityDataSet,
    B6: fireProtectionFacilityDataSet,
    B7: fireProtectionFacilityDataSet,
    B8: fireProtectionFacilityDataSet,
    B9: fireProtectionFacilityDataSet,
    B10: fireProtectionFacilityDataSet,
    B11: fireProtectionFacilityDataSet,
    B12: fireProtectionFacilityDataSet,
    B13: fireProtectionFacilityDataSet,
    B14: fireProtectionFacilityDataSet,
    B15: fireProtectionFacilityDataSet,
    B16: fireProtectionFacilityDataSet,
    B17: fireProtectionFacilityDataSet,
    B18: fireProtectionFacilityDataSet,
    B19: fireProtectionFacilityDataSet,
    B20: fireProtectionFacilityDataSet,
  },
};
