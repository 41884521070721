import React from "react";
import { useSelector } from "react-redux";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  Svg,
  Line,
} from "@react-pdf/renderer";

import Button from "@mui/material/Button";

import {
  createTableHeaderA4,
  createTableRowA4,
  tableStyleA4,
} from "../Parts/TableA4";
import {
  createTableHeader_20_20_40_20,
  createTableHeader_head_E,
  createTableRow_5_95,
  createTableRow_5_12_22_41_5_5_5_5,
  createTableRow_5_12_22_41_5_5_5_5_color,
  createTableRow_5_25_50_5_5_5_5,
  createTableRow_5_12_63_5_5_5_5,
  createTableRow_5_40_35_5_5_5_5,
  createTableRow_100,
  createTableRow_5_25_30_30_10,
  createTableRow_5_25_30_30_10_header,
  tableStyle_2,
  tableStyle_18,
} from "../Parts/CreateTable";

import fontRegular from "../fonts/Nasu-Regular.ttf"; //ttfファイル参照
import fontBold from "../fonts/Nasu-Bold.ttf"; //ttfファイル参照

const PdfEquipment = () => {
  const state = useSelector((state) => state["Equipment"]["B1"]);

  const states = useSelector((state) => state["Equipment"]);

  const buldingNumberEquipment = state["e0"]["e01_ro2"];

  const CheackInvestVentilation = state["e2"]["e21_ni1"];
  const CheackInvestSmoke = state["e2"]["e21_ni2"];
  const CheackInvestLight = state["e2"]["e21_ni3"];

  Font.registerHyphenationCallback((word) =>
    word.length === 1 ? [word] : Array.from(word).map((char) => char)
  );

  let pointList = [];

  for (let index = 1; index < buldingNumberEquipment + 1; index++) {
    !!states[`B${index}`].e26_ro &&
      pointList.push(
        `【${index}棟】(換気設備)` + states[`B${index}`].e26_ro + "\n"
      );
    !!states[`B${index}`].e210_ro &&
      pointList.push(
        `【${index}棟】(排煙設備)` + states[`B${index}`].e210_ro + "\n"
      );
    !!states[`B${index}`].e214_ro &&
      pointList.push(
        `【${index}棟】(非常用の照明)` + states[`B${index}`].e214_ro + "\n"
      );
  }

  const Page1 = ({ state, state0, state9 }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1}>
          <Text style={styles.text1}>
            第三十六号の六様式（第六条、第六条の二の二関係）（Ａ４）
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>定期検査報告書</Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（建築設備（昇降機を除く。））</Text>
        </View>
        <View style={styles.section_6}>
          {!!state0.e01_i1 && (
            <Text style={styles.text1}>
              台帳番号 {state0.e01_i1}-{state0.e01_i2}
            </Text>
          )}
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（第一面）</Text>
        </View>
        <View style={styles.section_3}>
          <Text style={styles.text1}>
            建築基準法第12条第3項の規定により、定期検査の結果を報告します。この報告書に記載の事項は、事実に相違あり
          </Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>ません。</Text>
        </View>
        <View style={styles.section_4}>
          {!!state9.e91_1 && <Text style={styles.text1}>{state9.e91_1}様</Text>}
        </View>
        <View style={styles.section_5}>
          {!!state0.e01_ho_1 && (
            <Text style={styles.text1}>
              {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                era: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              }).format(new Date(state0.e01_ho_1))}
            </Text>
          )}
        </View>
        <View style={styles.section_5}>
          {!!state0.e01_ha && (
            <Text style={styles.text1}>報告者氏名 {state0.e01_ha}</Text>
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_5}>
          {!!state0.e01_ni && (
            <Text style={styles.text1}>検査者氏名 {state0.e01_ni}</Text>
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.所有者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】
            </Text>
            {!!state.e11_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.e11_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.e11_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.e11_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ホ．電話番号】　
            </Text>
            {!!state.e11_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.管理者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】　
            </Text>
            {!!state.e12_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.e12_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.e12_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.e12_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．電話番号】</Text>
            {!!state.e12_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.報告対象建築物】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
            {!!state.e13_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．名称のフリガナ】
            </Text>
            {!!state.e13_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
            {!!state.e13_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
            {!!state.e13_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.検査による指摘の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．指摘の内容】
            </Text>
            {!!state.e14_i1 && <Text style={styles.text1}>{state.e14_i1}</Text>}
            {!!state.e14_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．指摘の概要】　
            </Text>
            {!!state.e14_ro && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.e14_ro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善予定の有無】
            </Text>
            {!!state.e14_ha1 && (
              <Text style={styles.text1}>{state.e14_ha1}</Text>
            )}
            {!!state.e14_ha2_1 && (
              <Text style={styles.text1}>{`(${state.e14_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．その他特記事項】
            </Text>
            {!!state.e14_ni && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.e14_ni}
              </Text>
            )}
          </View>
        </View>
      </Page>
    );
  };

  const Page2 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text
              style={styles.text1}
            >{`(第二面) 第${bNumber}棟 ${state9.e92_1}`}</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>建築設備の状況等</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.建築物の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．階数】</Text>
              {!!state.e21_i1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地上{state.e21_i1}階
                </Text>
              )}
              {!!state.e21_i2 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地下{state.e21_i2}階
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．建築面積】
              </Text>
              {!!state.e21_ro && (
                <Text style={styles.text1}>{state.e21_ro}㎡</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．延べ面積】
              </Text>
              {!!state.e21_ha && (
                <Text style={styles.text1}>{state.e21_ha}㎡</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．検査対象建築設備】
              </Text>
              {!!state.e21_ni1 && <Text style={styles.text1}>換気設備</Text>}
              {!!state.e21_ni2 && <Text style={styles.text1}>　排煙設備</Text>}
              {!!state.e21_ni3 && (
                <Text style={styles.text1}>　非常用の照明装置</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.確認済証交付年月日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．確認済証交付年月日】
              </Text>
              {!!state.e22_i1_1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.e22_i1_1))}
                </Text>
              )}
              {!!state.e22_i2 && (
                <Text style={[styles.text1, { width: 300 }]}>
                  {`第${state.e22_i2}号`}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．確認済証交付者】
              </Text>
              {!!state.e22_ro1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {state.e22_ro1}
                </Text>
              )}
              {!!state.e22_ro2 && (
                <Text
                  style={[styles.text1, { width: 300 }]}
                >{`（${state.e22_ro2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ハ．検査済証交付年月日】
              </Text>
              {!!state.e22_ha1_1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.e22_ha1_1))}
                </Text>
              )}
              {!!state.e22_ha2 && (
                <Text style={[styles.text1, { width: 300 }]}>
                  {`第${state.e22_ha2}号`}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．検査済証交付者】
              </Text>
              {!!state.e22_ni1 && (
                <Text style={[styles.text1, { width: 95 }]}>
                  {state.e22_ni1}
                </Text>
              )}
              {!!state.e22_ni2 && (
                <Text
                  style={[styles.text1, { width: 300 }]}
                >{`（${state.e22_ni2}）`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【3.検査日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【イ．今回の検査】
              </Text>
              {!!state.e23_i_1 && (
                <Text style={styles.text1}>{`${state.e23_i_1}実施`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ロ．前回の検査】
              </Text>
              {!!state.e23_ro1 && (
                <Text style={styles.text1}>{state.e23_ro1}</Text>
              )}
              {!!state.e23_ro2_1 && (
                <Text style={styles.text1}>{`（${state.e23_ro2_1}報告）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ハ．前回の検査に関する書類の写し】
              </Text>
              {!!state.e23_ha && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e23_ha}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【4.換気設備の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e24_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_ai1}
                </Text>
              )}
              {!!state.e24_ai2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_ai2}
                </Text>
              )}
              {!!state.e24_ai3 && (
                <Text style={styles.text1}>{`第${state.e24_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e24_ai4 && (
                <Text style={styles.text1}>{`第${state.e24_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e24_aro && (
                <Text style={styles.text1}>{state.e24_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e24_aha && (
                <Text style={styles.text1}>{state.e24_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e24_ani1 && (
                <Text style={styles.text1}>{state.e24_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e24_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_ani2}
                </Text>
              )}
              {!!state.e24_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e24_ani3}知事登録
                </Text>
              )}
              {!!state.e24_ani4 && (
                <Text style={styles.text1}>{`第${state.e24_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e24_aho && (
                <Text style={styles.text1}>{state.e24_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e24_ahe && (
                <Text style={styles.text1}>{state.e24_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e24_ato && (
                <Text style={styles.text1}>{state.e24_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e24_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_bi1}
                </Text>
              )}
              {!!state.e24_bi2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_bi2}
                </Text>
              )}
              {!!state.e24_bi3 && (
                <Text style={styles.text1}>{`第${state.e24_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e24_bi4 && (
                <Text style={styles.text1}>{`第${state.e24_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e24_bro && (
                <Text style={styles.text1}>{state.e24_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e24_bha && (
                <Text style={styles.text1}>{state.e24_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e24_bni1 && (
                <Text style={styles.text1}>{state.e24_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e24_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_bni2}
                </Text>
              )}
              {!!state.e24_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e24_bni3}知事登録
                </Text>
              )}
              {!!state.e24_bni4 && (
                <Text style={styles.text1}>{`第${state.e24_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e24_bho && (
                <Text style={styles.text1}>{state.e24_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e24_bhe && (
                <Text style={styles.text1}>{state.e24_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e24_bto && (
                <Text style={styles.text1}>{state.e24_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【5.換気設備の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．無窓居室】
              </Text>
              {!!state.e25_i1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_i1_2 && (
                <Text style={styles.text1}>{`（${state.e25_i1_2}）`}</Text>
              )}

              {!!state.e25_i2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_i2_2 && (
                <Text style={styles.text1}>{`（${state.e25_i2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_i3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_i3_2 && (
                <Text style={styles.text1}>{`（${state.e25_i3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_i4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_i4_2 && (
                <Text style={styles.text1}>{`（${state.e25_i4_2}）`}</Text>
              )}
              {!!state.e25_i5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．火気使用室】
              </Text>
              {!!state.e25_ro1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_ro1_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro1_2}）`}</Text>
              )}

              {!!state.e25_ro2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_ro2_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ro3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ro4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_ro4_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro4_2}）`}</Text>
              )}
              {!!state.e25_ro5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>【ハ．居室等】</Text>
              {!!state.e25_ha1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_ha1_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha1_2}）`}</Text>
              )}

              {!!state.e25_ha2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_ha2_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ha3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_ha3_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ha4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_ha4_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha4_2}）`}</Text>
              )}
              {!!state.e25_ha5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．防火ダンバーの有無】
              </Text>
              {!!state.e25_ni && (
                <Text style={styles.text1}>{state.e25_ni}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【6.換気設備の検査の状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】
              </Text>
              {!!state.e26_i1 && (
                <Text style={styles.text1}>{state.e26_i1}</Text>
              )}
              {!!state.e26_i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】　
              </Text>
              {!!state.e26_ro && (
                <Text style={[styles.text1, { maxWidth: 415 }]}>
                  {state.e26_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.e26_ha1 && (
                <Text style={styles.text1}>{state.e26_ha1}</Text>
              )}
              {!!state.e26_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e26_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【7.換気設備の不具合の発生状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
              {!!state.e27_i && <Text style={styles.text1}>{state.e27_i}</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．不具合記録】
              </Text>
              {!!state.e27_ro && (
                <Text style={styles.text1}>{state.e27_ro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善の状況】
              </Text>
              {!!state.e27_ha1 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e27_ha1}
                </Text>
              )}
              {!!state.e27_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e27_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【8.排煙設備の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e28_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_ai1}
                </Text>
              )}
              {!!state.e28_ai2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_ai2}
                </Text>
              )}
              {!!state.e28_ai3 && (
                <Text style={styles.text1}>{`第${state.e28_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e28_ai4 && (
                <Text style={styles.text1}>{`第${state.e28_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e28_aro && (
                <Text style={styles.text1}>{state.e28_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e28_aha && (
                <Text style={styles.text1}>{state.e28_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e28_ani1 && (
                <Text style={styles.text1}>{state.e28_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e28_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_ani2}
                </Text>
              )}
              {!!state.e28_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e28_ani3}知事登録
                </Text>
              )}
              {!!state.e28_ani4 && (
                <Text style={styles.text1}>{`第${state.e28_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e28_aho && (
                <Text style={styles.text1}>{state.e28_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e28_ahe && (
                <Text style={styles.text1}>{state.e28_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e28_ato && (
                <Text style={styles.text1}>{state.e28_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e28_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_bi1}
                </Text>
              )}
              {!!state.e28_bi2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_bi2}
                </Text>
              )}
              {!!state.e28_bi3 && (
                <Text style={styles.text1}>{`第${state.e28_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e28_bi4 && (
                <Text style={styles.text1}>{`第${state.e28_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e28_bro && (
                <Text style={styles.text1}>{state.e28_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e28_bha && (
                <Text style={styles.text1}>{state.e28_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e28_bni1 && (
                <Text style={styles.text1}>{state.e28_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e28_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_bni2}
                </Text>
              )}
              {!!state.e28_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e28_bni3}知事登録
                </Text>
              )}
              {!!state.e28_bni4 && (
                <Text style={styles.text1}>{`第${state.e28_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e28_bho && (
                <Text style={styles.text1}>{state.e28_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e28_bhe && (
                <Text style={styles.text1}>{state.e28_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e28_bto && (
                <Text style={styles.text1}>{state.e28_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【9.排煙設備の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 250 }]}>
                【イ．避難安全検証法等の適用】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i1_1 && (
                <Text style={styles.text1}>区画避難安全検証法</Text>
              )}
              {!!state.e29_i1_2 && (
                <Text style={styles.text1}>{`（${state.e29_i1_2}階）`}</Text>
              )}

              {!!state.e29_i2_1 && (
                <Text style={styles.text1}>階避難安全検証法</Text>
              )}
              {!!state.e29_i2_2 && (
                <Text style={styles.text1}>{`（${state.e29_i2_2}階）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i3_1 && (
                <Text style={styles.text1}>全館避難安全検証法</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i4_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e29_i4_2 && (
                <Text
                  style={[styles.text1, { width: 445 }]}
                >{`(${state.e29_i4_2})`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 250 }]}>
                【ロ．特別避難階段の階段室又は付室】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ro1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ro1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro1_2}区画）`}</Text>
              )}
              {!!state.e29_ro2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ro2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro2_2}区画）`}</Text>
              )}
              {!!state.e29_ro3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro3_2}区画）`}</Text>
              )}
              {!!state.e29_ro4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 350 }]}>
                【ハ．非常用エレベーターの昇降路又は乗降ロビー】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ha1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ha1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ha1_2}区画）`}</Text>
              )}
              {!!state.e29_ha2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ha2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ha2_2}区画）`}</Text>
              )}
              {!!state.e29_ro3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro3_2}区画）`}</Text>
              )}
              {!!state.e29_ro4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 350 }]}>
                【ニ．非常用エレベーターの昇降路又は乗降ロビーの用に供する付室】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ni1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ni1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni1_2}区画）`}</Text>
              )}
              {!!state.e29_ni2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ni2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni2_2}区画）`}</Text>
              )}
              {!!state.e29_ni3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ni3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni3_2}区画）`}</Text>
              )}
              {!!state.e29_ni4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ホ．居室等】</Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ho1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ho1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ho1_2}区画）`}</Text>
              )}
              {!!state.e29_ho2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ho2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ho2_2}区画）`}</Text>
              )}
              {!!state.e29_ho3_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【へ．予備電源】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_he1_1 && (
                <Text style={styles.text1}>{`蓄電池　`}</Text>
              )}
              {!!state.e29_he2_1 && (
                <Text style={styles.text1}>{`自家用発電装置　`}</Text>
              )}
              {!!state.e29_he3_1 && (
                <Text style={styles.text1}>{`直結エンジン　`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_he4_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e29_he4_2 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  {`(${state.e29_he4_2})`}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【10.排煙設備の検査の状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】
              </Text>
              {!!state.e210_i1 && (
                <Text style={styles.text1}>{state.e210_i1}</Text>
              )}
              {!!state.e210_i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】　
              </Text>
              {!!state.e210_ro && (
                <Text style={[styles.text1, { maxWidth: 415 }]}>
                  {state.e210_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.e210_ha1 && (
                <Text style={styles.text1}>{state.e210_ha1}</Text>
              )}
              {!!state.e210_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e210_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【11.排煙設備の不具合の発生状況】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
              {!!state.e211_i && (
                <Text style={styles.text1}>{state.e211_i}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．不具合記録】
              </Text>
              {!!state.e211_ro && (
                <Text style={styles.text1}>{state.e211_ro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善の状況】
              </Text>
              {!!state.e211_ha1 && (
                <Text style={styles.text1}>{state.e211_ha1}</Text>
              )}
              {!!state.e211_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e211_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【12.非常用の照明装置の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e212_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_ai1}
                </Text>
              )}
              {!!state.e212_ai2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_ai2}
                </Text>
              )}
              {!!state.e212_ai3 && (
                <Text style={styles.text1}>{`第${state.e212_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e212_ai4 && (
                <Text style={styles.text1}>{`第${state.e212_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e212_aro && (
                <Text style={styles.text1}>{state.e212_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e212_aha && (
                <Text style={styles.text1}>{state.e212_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e212_ani1 && (
                <Text style={styles.text1}>{state.e212_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e212_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_ani2}
                </Text>
              )}
              {!!state.e212_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e212_ani3}知事登録
                </Text>
              )}
              {!!state.e212_ani4 && (
                <Text style={styles.text1}>{`第${state.e212_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e212_aho && (
                <Text style={styles.text1}>{state.e212_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e212_ahe && (
                <Text style={styles.text1}>{state.e212_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e212_ato && (
                <Text style={styles.text1}>{state.e212_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e212_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_bi1}
                </Text>
              )}
              {!!state.e212_bi2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_bi2}
                </Text>
              )}
              {!!state.e212_bi3 && (
                <Text style={styles.text1}>{`第${state.e212_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建物設備検査員</Text>
              {!!state.e212_bi4 && (
                <Text style={styles.text1}>{`第${state.e212_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e212_bro && (
                <Text style={styles.text1}>{state.e212_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e212_bha && (
                <Text style={styles.text1}>{state.e212_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e212_bni1 && (
                <Text style={styles.text1}>{state.e212_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e212_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_bni2}
                </Text>
              )}
              {!!state.e212_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e212_bni3}知事登録
                </Text>
              )}
              {!!state.e212_bni4 && (
                <Text style={styles.text1}>{`第${state.e212_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e212_bho && (
                <Text style={styles.text1}>{state.e212_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e212_bhe && (
                <Text style={styles.text1}>{state.e212_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e212_bto && (
                <Text style={styles.text1}>{state.e212_bto}</Text>
              )}
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 250 }]}>
              【13.非常用の照明装置の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．照明器具】
              </Text>
              {!!state.e213_i1_1 && <Text style={styles.text1}>白熱灯</Text>}
              {!!state.e213_i1_2 && (
                <Text style={styles.text1}>{`（${state.e213_i1_2}灯）`}</Text>
              )}
              {!!state.e213_i2_1 && <Text style={styles.text1}>蛍光灯</Text>}
              {!!state.e213_i2_2 && (
                <Text style={styles.text1}>{`（${state.e213_i2_2}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_i3_1 && <Text style={styles.text1}>LEDランプ</Text>}
              {!!state.e213_i3_2 && (
                <Text style={styles.text1}>{`（${state.e213_i3_2}灯）`}</Text>
              )}
              {!!state.e213_i4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e213_i4_2 && (
                <Text style={styles.text1}>{`（${state.e213_i4_2}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．予備電源】
              </Text>
              {!!state.e213_ro1_1 && (
                <Text style={styles.text1}>蓄電池（内蔵形）</Text>
              )}
              {!!state.e213_ro1_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro1_2}灯）`}</Text>
              )}
              {!!state.e213_ro1_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro1_3}灯）`}</Text>
              )}
              {!!state.e213_ro1_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro1_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro2_1 && (
                <Text style={styles.text1}>蓄電池（別置形）</Text>
              )}
              {!!state.e213_ro2_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro2_2}灯）`}</Text>
              )}
              {!!state.e213_ro2_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro2_3}灯）`}</Text>
              )}
              {!!state.e213_ro2_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro2_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro3_1 && (
                <Text style={styles.text1}>自家用発電装置</Text>
              )}
              {!!state.e213_ro3_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro3_2}灯）`}</Text>
              )}
              {!!state.e213_ro3_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro3_3}灯）`}</Text>
              )}
              {!!state.e213_ro3_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro3_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro4_1 && (
                <Text style={styles.text1}>
                  蓄電池（別置形）・自家発電装置併用
                </Text>
              )}
              {!!state.e213_ro4_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro4_2}灯）`}</Text>
              )}
              {!!state.e213_ro4_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro4_3}灯）`}</Text>
              )}
              {!!state.e213_ro4_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro4_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro5_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e213_ro5_2 && (
                <Text
                  style={[styles.text1, { width: 365 }]}
                >{`(${state.e213_ro5_2})`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>
              【14.非常用の照明装置の検査の状況】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．指摘の内容】
              </Text>
              {!!state.e214_i1 && (
                <Text style={styles.text1}>{state.e214_i1}</Text>
              )}
              {!!state.e214_i2 && (
                <Text style={styles.text1}>（既存不適格）</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．指摘の概要】　
              </Text>
              {!!state.e214_ro && (
                <Text style={[styles.text1, { maxWidth: 415 }]}>
                  {state.e214_ro}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善予定の有無】
              </Text>
              {!!state.e214_ha1 && (
                <Text style={styles.text1}>{state.e214_ha1}</Text>
              )}
              {!!state.e214_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e214_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>
              【15.非常用の照明装置の不具合の発生状況】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
              {!!state.e215_i && (
                <Text style={styles.text1}>{state.e215_i}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．不具合記録】
              </Text>
              {!!state.e215_ro && (
                <Text style={styles.text1}>{state.e215_ro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．改善の状況】
              </Text>
              {!!state.e215_ha1 && (
                <Text style={styles.text1}>{state.e215_ha1}</Text>
              )}
              {!!state.e215_ha2_1 && (
                <Text style={styles.text1}>{`(${state.e215_ha2_1.slice(
                  0,
                  -2
                )}月に改善予定)`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【20.備考】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.e220_1 && (
                <Text style={[styles.text1, { width: 545 }]}>
                  {state.e220_1}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
        </Page>
      </>
    );
  };

  const Page3 = ({ state }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1_1}>
          <Text style={styles.text1}>（第三面）</Text>
        </View>
        <View style={styles.section_4}>
          <Text style={styles.text1}>建築設備に係る不具合等の状況</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.換気設備】</Text>
        </View>
        <View style={tableStyleA4}>
          {createTableHeaderA4()}
          {createTableRowA4(
            state.e3_1_11_1,
            state.e3_1_12,
            state.e3_1_13,
            state.e3_1_14_1,
            state.e3_1_15
          )}
          {createTableRowA4(
            state.e3_1_21_1,
            state.e3_1_22,
            state.e3_1_23,
            state.e3_1_24_1,
            state.e3_1_25
          )}
          {createTableRowA4(
            state.e3_1_31_1,
            state.e3_1_32,
            state.e3_1_33,
            state.e3_1_34_1,
            state.e3_1_35
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.排煙設備】</Text>
        </View>
        <View style={tableStyleA4}>
          {createTableHeaderA4()}
          {createTableRowA4(
            state.e3_2_11_1,
            state.e3_2_12,
            state.e3_2_13,
            state.e3_2_14_1,
            state.e3_2_15
          )}
          {createTableRowA4(
            state.e3_2_21_1,
            state.e3_2_22,
            state.e3_2_23,
            state.e3_2_24_1,
            state.e3_2_25
          )}
          {createTableRowA4(
            state.e3_2_31_1,
            state.e3_2_32,
            state.e3_2_33,
            state.e3_2_34_1,
            state.e3_2_35
          )}
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.非常用の照明装置】</Text>
        </View>
        <View style={tableStyleA4}>
          {createTableHeaderA4()}
          {createTableRowA4(
            state.e3_3_11_1,
            state.e3_3_12,
            state.e3_3_13,
            state.e3_3_14_1,
            state.e3_3_15
          )}
          {createTableRowA4(
            state.e3_3_21_1,
            state.e3_3_22,
            state.e3_3_23,
            state.e3_3_24_1,
            state.e3_3_25
          )}
          {createTableRowA4(
            state.e3_3_31_1,
            state.e3_3_32,
            state.e3_3_33,
            state.e3_3_34_1,
            state.e3_3_35
          )}
        </View>
      </Page>
    );
  };

  const PageInvestVentilation = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第一号（A４)　</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text
              style={styles.text1}
            >{`(換気設備) 第${bNumber}棟 ${state9.e92_1}`}</Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_20_20_40_20(
              state.e40_1_11,
              state.e40_1_12,
              state.e40_1_21,
              state.e40_1_22,
              state.e40_1_31,
              state.e40_1_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_E()}
            {createTableRow_5_95(
              "1",
              "法第28条第2項又は第3項に基づき換気設備が設けられた居室（換気設備を設けるべき調理室等を除く。）"
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `機械換気設備`,
              `機械換気設備(中央管理方式の
            空気調和設備を含む)の外観`,
              `給気機の外気取り入れ口並びに直接外気に開放された給気口
          及び排気口への雨水等の防止措置の状況`,
              state.e41_1_1_11,
              state.e41_1_1_12,
              state.e41_1_1_13,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              "",
              "",
              "給気機の外気取り入れ口及び排気機の排気口の取付けの状況",
              state.e41_1_1_21,
              state.e41_1_1_22,
              state.e41_1_1_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `各居室の給気口及び排気口の設置位置`,
              state.e41_1_1_31,
              state.e41_1_1_32,
              state.e41_1_1_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `各居室の給気口及び排気口の取付けの状況`,
              state.e41_1_1_41,
              state.e41_1_1_42,
              state.e41_1_1_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `風道の取付けの状況`,
              state.e41_1_1_51,
              state.e41_1_1_52,
              state.e41_1_1_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              ``,
              `風道の材質`,
              state.e41_1_1_61,
              state.e41_1_1_62,
              state.e41_1_1_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `給気機又は排気機の設置の状況`,
              state.e41_1_1_71,
              state.e41_1_1_72,
              state.e41_1_1_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `換気扇による換気の状況`,
              state.e41_1_1_81,
              state.e41_1_1_82,
              state.e41_1_1_83,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(9)",
              ``,
              `機械換気設備(中央管理方式の`,
              `各居室の換気量`,
              state.e41_1_1_91,
              state.e41_1_1_92,
              state.e41_1_1_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(10)",
              ``,
              `空気調和設備を含む)の性能`,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_1_1_101,
              state.e41_1_1_102,
              state.e41_1_1_103
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              `中央管理方式の`,
              `空気調和設備の主要機器及び`,
              `空気調和設備の設置の状況`,
              state.e41_1_1_111,
              state.e41_1_1_112,
              state.e41_1_1_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              `空気調和設備`,
              `配管の外観`,
              `空気調和設備及び配管の劣化及び損傷の状況`,
              state.e41_1_1_121,
              state.e41_1_1_122,
              state.e41_1_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              ``,
              `空気調和設備の運転の状況`,
              state.e41_1_1_131,
              state.e41_1_1_132,
              state.e41_1_1_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `空気ろ過器の点検口`,
              state.e41_1_1_141,
              state.e41_1_1_142,
              state.e41_1_1_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `冷却塔と建築物の他の部分との離隔距離`,
              state.e41_1_1_151,
              state.e41_1_1_152,
              state.e41_1_1_153,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(16)",
              ``,
              `空気調和設備の性能`,
              `各居室の温度`,
              state.e41_1_1_161,
              state.e41_1_1_162,
              state.e41_1_1_163,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(17)",
              ``,
              ``,
              `各居室の相対湿度`,
              state.e41_1_1_171,
              state.e41_1_1_172,
              state.e41_1_1_173,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(18)",
              ``,
              ``,
              `各居室の浮遊粉じん量`,
              state.e41_1_1_181,
              state.e41_1_1_182,
              state.e41_1_1_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(19)",
              ``,
              ``,
              `各居室の一酸化炭素含有率`,
              state.e41_1_1_191,
              state.e41_1_1_192,
              state.e41_1_1_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(20)",
              ``,
              ``,
              `各居室の二酸化炭素含有率`,
              state.e41_1_1_201,
              state.e41_1_1_202,
              state.e41_1_1_203,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(21)",
              ``,
              ``,
              `各居室の気流`,
              state.e41_1_1_211,
              state.e41_1_1_212,
              state.e41_1_1_213
            )}
            {createTableRow_5_95("2", "換気設備を設けるべき調理室等")}
            {createTableRow_5_25_50_5_5_5_5(
              "(1)",
              `自然換気設備及び機械換気設備`,
              `排気筒、排気フード及び煙突の材質`,
              state.e41_1_2_11,
              state.e41_1_2_12,
              state.e41_1_2_13,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(2)",
              ``,
              `排気筒、排気フード及び煙突の取付けの状況`,
              state.e41_1_2_21,
              state.e41_1_2_22,
              state.e41_1_2_23,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(3)",
              ``,
              `給気口、給気筒、排気口、排気筒、排気フード及び煙突の大きさ`,
              state.e41_1_2_31,
              state.e41_1_2_32,
              state.e41_1_2_33,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(4)",
              ``,
              `給気口、排気口及び排気フードの位置`,
              state.e41_1_2_41,
              state.e41_1_2_42,
              state.e41_1_2_43,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(5)",
              ``,
              `給気口、給気筒、排気口、排気筒、排気フード及び煙突の設置の状況`,
              state.e41_1_2_51,
              state.e41_1_2_52,
              state.e41_1_2_53,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(6)",
              ``,
              `排気筒及び煙突の断熱の状況`,
              state.e41_1_2_61,
              state.e41_1_2_62,
              state.e41_1_2_63,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(7)",
              ``,
              `排気筒及び煙突と可燃物、電線等との離隔距離`,
              state.e41_1_2_71,
              state.e41_1_2_72,
              state.e41_1_2_73,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(8)",
              ``,
              `煙突等への防火ダンパー、風道等の設置の状況`,
              state.e41_1_2_81,
              state.e41_1_2_82,
              state.e41_1_2_83
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(9)",
              `自然換気設備`,
              `煙突の先端の立ち上がりの状況（密閉型燃焼器具の煙突を除く。）`,
              state.e41_1_2_91,
              state.e41_1_2_92,
              state.e41_1_2_93
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(10)",
              `機械換気設備`,
              `煙突に連結した排気筒及び半密閉式瞬間湯沸器等の設置の状況`,
              state.e41_1_2_101,
              state.e41_1_2_102,
              state.e41_1_2_103,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(11)",
              ``,
              `換気扇による換気の状況`,
              state.e41_1_2_111,
              state.e41_1_2_112,
              state.e41_1_2_113,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(12)",
              ``,
              `給気機又は排気機の設置の状況`,
              state.e41_1_2_121,
              state.e41_1_2_122,
              state.e41_1_2_123,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(13)",
              ``,
              `機械換気設備の換気量`,
              state.e41_1_2_131,
              state.e41_1_2_132,
              state.e41_1_2_133
            )}
            {createTableRow_5_95(
              "3",
              "法第28条第2項又は第3項に基づき換気設備が設けられた居室等"
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(1)",
              `防火ダンパー等 (外壁の開口部で`,
              `防火ダンパーの設置の状況`,
              state.e41_1_3_11,
              state.e41_1_3_12,
              state.e41_1_3_13,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(2)",
              `延焼のおそれのある部分に`,
              `防火ダンパーの取付けの状況`,
              state.e41_1_3_21,
              state.e41_1_3_22,
              state.e41_1_3_23,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(3)",
              `設けるものを除く。)`,
              `防火ダンパーの作動の状況`,
              state.e41_1_3_31,
              state.e41_1_3_32,
              state.e41_1_3_33,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(4)",
              ``,
              `防火ダンパーの劣化及び損傷の状況`,
              state.e41_1_3_41,
              state.e41_1_3_42,
              state.e41_1_3_43,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(5)",
              ``,
              `防火ダンパーの点検口の有無及び大きさ並びに検査口の有無`,
              state.e41_1_3_51,
              state.e41_1_3_52,
              state.e41_1_3_53,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(6)",
              ``,
              `防火ダンパーの温度ヒューズ`,
              state.e41_1_3_61,
              state.e41_1_3_62,
              state.e41_1_3_63,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(7)",
              ``,
              `壁及び床の防火区画貫通措置部の措置の状況`,
              state.e41_1_3_71,
              state.e41_1_3_72,
              state.e41_1_3_73,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(8)",
              ``,
              `連動型防火ダンパーの煙感知器、熱煙複合式感知器及び熱感知器の位置`,
              state.e41_1_3_81,
              state.e41_1_3_82,
              state.e41_1_3_83,
              0
            )}
            {createTableRow_5_25_50_5_5_5_5(
              "(9)",
              ``,
              `連動型防火ダンパーの煙感知器、熱煙複合式感知器及び熱感知器
          との連動の状況`,
              state.e41_1_3_91,
              state.e41_1_3_92,
              state.e41_1_3_93
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_1_5_11,
              state.e41_1_5_12,
              state.e41_1_5_13,
              state.e41_1_5_14,
              state.e41_1_5_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_1_5_21,
              state.e41_1_5_22,
              state.e41_1_5_23,
              state.e41_1_5_24,
              state.e41_1_5_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_1_5_31,
              state.e41_1_5_32,
              state.e41_1_5_33,
              state.e41_1_5_34,
              state.e41_1_5_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_1_5_41,
              state.e41_1_5_42,
              state.e41_1_5_43,
              state.e41_1_5_44,
              state.e41_1_5_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_1_5_51,
              state.e41_1_5_52,
              state.e41_1_5_53,
              state.e41_1_5_54,
              state.e41_1_5_55_1
            )}
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text8}>
              ※塗りつぶしの項目は３年以内に１回検査を行う
            </Text>
          </View>
        </Page>
      </>
    );
  };

  const PageInvestSmoke = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第二号（A４)　</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text
              style={styles.text1}
            >{`(排煙設備) 第${bNumber}棟 ${state9.e92_1}`}</Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_20_20_40_20(
              state.e40_2_11,
              state.e40_2_12,
              state.e40_2_21,
              state.e40_2_22,
              state.e40_2_31,
              state.e40_2_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_E()}
            {createTableRow_5_95(
              "1",
              `令第123条第３項第２号に規定する階段室又は付室、令第129条の13の３第１３項に規定する昇降路又は
          乗降ロビー、令第126条の２第１項に規定する居室等`
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `排煙機`,
              `排煙機の外観`,
              `排煙機の設置の状況`,
              state.e41_2_1_11,
              state.e41_2_1_12,
              state.e41_2_1_13,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              "",
              "",
              "排煙風道との接続の状況",
              state.e41_2_1_21,
              state.e41_2_1_22,
              state.e41_2_1_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `排煙出口の設置の状況`,
              state.e41_2_1_31,
              state.e41_2_1_32,
              state.e41_2_1_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `排煙出口の周囲の状況`,
              state.e41_2_1_41,
              state.e41_2_1_42,
              state.e41_2_1_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `屋外に設置された煙排出口への雨水等の防止措置の状況`,
              state.e41_2_1_51,
              state.e41_2_1_52,
              state.e41_2_1_53,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              `排煙機の性能`,
              `排煙口の開放との連動起動の状況`,
              state.e41_2_1_61,
              state.e41_2_1_62,
              state.e41_2_1_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `作動の状況`,
              state.e41_2_1_71,
              state.e41_2_1_72,
              state.e41_2_1_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `電源を必要とする排煙機の予備電源による作動の状況`,
              state.e41_2_1_81,
              state.e41_2_1_82,
              state.e41_2_1_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `排煙機の排煙風量`,
              state.e41_2_1_91,
              state.e41_2_1_92,
              state.e41_2_1_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_1_101,
              state.e41_2_1_102,
              state.e41_2_1_103
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              `排煙口`,
              `機械排煙設備の排煙口の外観`,
              `排煙口の位置`,
              state.e41_2_1_111,
              state.e41_2_1_112,
              state.e41_2_1_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              ``,
              `排煙口の周囲の状況`,
              state.e41_2_1_121,
              state.e41_2_1_122,
              state.e41_2_1_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              ``,
              `排煙口の取付けの状況`,
              state.e41_2_1_131,
              state.e41_2_1_132,
              state.e41_2_1_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `手動開放装置の周囲の状況`,
              state.e41_2_1_141,
              state.e41_2_1_142,
              state.e41_2_1_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `手動開放装置の操作方法の表示の状況`,
              state.e41_2_1_151,
              state.e41_2_1_152,
              state.e41_2_1_153,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              `機械排煙設備の排煙口の性能`,
              `手動開放装置による開放の状況`,
              state.e41_2_1_161,
              state.e41_2_1_162,
              state.e41_2_1_163,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              ``,
              `排煙口の開放の状況`,
              state.e41_2_1_171,
              state.e41_2_1_172,
              state.e41_2_1_173,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(18)",
              ``,
              ``,
              `排煙口の排煙風量`,
              state.e41_2_1_181,
              state.e41_2_1_182,
              state.e41_2_1_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(19)",
              ``,
              ``,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_1_191,
              state.e41_2_1_192,
              state.e41_2_1_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              ``,
              `煙感知器による作動の状況`,
              state.e41_2_1_201,
              state.e41_2_1_202,
              state.e41_2_1_203
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              `排煙風道`,
              `機械排煙設備の排煙風道`,
              `排煙風道の劣化及び損傷の状況`,
              state.e41_2_1_211,
              state.e41_2_1_212,
              state.e41_2_1_213,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(22)",
              ``,
              `（隠蔽部分及び埋設部分`,
              `排煙風道の取付けの状況`,
              state.e41_2_1_221,
              state.e41_2_1_222,
              state.e41_2_1_223,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(23)",
              ``,
              `を除く。）`,
              `排煙風道の材質`,
              state.e41_2_1_231,
              state.e41_2_1_232,
              state.e41_2_1_233,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(24)",
              ``,
              ``,
              `防煙壁の貫通措置の状況`,
              state.e41_2_1_241,
              state.e41_2_1_242,
              state.e41_2_1_243,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(25)",
              ``,
              ``,
              `排煙風道と可燃物、電線等との離隔距離及び断熱の状況`,
              state.e41_2_1_251,
              state.e41_2_1_252,
              state.e41_2_1_253,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(26)",
              ``,
              `防火ダンパー(外壁の開口部で`,
              `防火ダンパーの取付けの状況`,
              state.e41_2_1_261,
              state.e41_2_1_262,
              state.e41_2_1_263,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(27)",
              ``,
              `延焼のおそれのある部分に`,
              `防火ダンパーの作動の状況`,
              state.e41_2_1_271,
              state.e41_2_1_272,
              state.e41_2_1_273,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(28)",
              ``,
              `設けるものを除く。）`,
              `防火ダンパーの劣化及び損傷の状況`,
              state.e41_2_1_281,
              state.e41_2_1_282,
              state.e41_2_1_283,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(29)",
              ``,
              ``,
              `防火ダンパーの点検口の有無及び大きさ並びに検査口の有無`,
              state.e41_2_1_291,
              state.e41_2_1_292,
              state.e41_2_1_293,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(30)",
              ``,
              ``,
              `防火ダンパーの温度ヒューズ`,
              state.e41_2_1_301,
              state.e41_2_1_302,
              state.e41_2_1_303,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(31)",
              ``,
              ``,
              `壁及び床の防火区画貫通部の措置の状況`,
              state.e41_2_1_311,
              state.e41_2_1_312,
              state.e41_2_1_313
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(32)",
              `特殊な構造の`,
              `特殊な構造の排煙設備の`,
              `排煙口及び給気口の大きさ及び位置`,
              state.e41_2_1_321,
              state.e41_2_1_322,
              state.e41_2_1_323,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(33)",
              `排煙設備`,
              `排煙口及び給気口の外観`,
              `排煙口及び給気口の周囲の状況`,
              state.e41_2_1_331,
              state.e41_2_1_332,
              state.e41_2_1_333,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(34)",
              ``,
              ``,
              `排煙口及び給気口の取付けの状況`,
              state.e41_2_1_341,
              state.e41_2_1_342,
              state.e41_2_1_343,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(35)",
              ``,
              ``,
              `手動開放装置の設置の状況`,
              state.e41_2_1_351,
              state.e41_2_1_352,
              state.e41_2_1_353,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(36)",
              ``,
              ``,
              `手動開放装置の操作方法の表示の状況`,
              state.e41_2_1_361,
              state.e41_2_1_362,
              state.e41_2_1_363,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(37)",
              ``,
              `特殊な構造の排煙設備`,
              `排煙口の排煙風量`,
              state.e41_2_1_371,
              state.e41_2_1_372,
              state.e41_2_1_373,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(38)",
              ``,
              `の排煙口の性能`,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_1_381,
              state.e41_2_1_382,
              state.e41_2_1_383,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(39)",
              ``,
              ``,
              `煙感知器による作動の状況`,
              state.e41_2_1_391,
              state.e41_2_1_392,
              state.e41_2_1_393,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(40)",
              ``,
              `特殊な構造の排煙設備の`,
              `給気風道の劣化及び損傷の状況`,
              state.e41_2_1_401,
              state.e41_2_1_402,
              state.e41_2_1_403,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(41)",
              ``,
              `給気風道（隠蔽部分及び`,
              `給気風道の材質`,
              state.e41_2_1_411,
              state.e41_2_1_412,
              state.e41_2_1_413,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(42)",
              ``,
              `埋設部分を除く。）`,
              `給気風道の取付けの状況`,
              state.e41_2_1_421,
              state.e41_2_1_422,
              state.e41_2_1_423,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(43)",
              ``,
              ``,
              `防煙壁の貫通措置の状況`,
              state.e41_2_1_431,
              state.e41_2_1_432,
              state.e41_2_1_433,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(44)",
              ``,
              `特殊な構造の排煙設備`,
              `給気送風機の設置の状況`,
              state.e41_2_1_441,
              state.e41_2_1_442,
              state.e41_2_1_443,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(45)",
              ``,
              `の給気送風機の外観`,
              `給気風道との接続の状況`,
              state.e41_2_1_451,
              state.e41_2_1_452,
              state.e41_2_1_453,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(46)",
              ``,
              `特殊な構造の排煙設備`,
              `排煙口の開放と連動起動の状況`,
              state.e41_2_1_461,
              state.e41_2_1_462,
              state.e41_2_1_463,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(47)",
              ``,
              `の給気送風機の性能`,
              `作動の状況`,
              state.e41_2_1_481,
              state.e41_2_1_482,
              state.e41_2_1_483,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(48)",
              ``,
              ``,
              `電源を必要とする給気送風機の予備電源による作動の状況`,
              state.e41_2_1_481,
              state.e41_2_1_482,
              state.e41_2_1_483,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(49)",
              ``,
              ``,
              `給気送風機の給気風量`,
              state.e41_2_1_491,
              state.e41_2_1_492,
              state.e41_2_1_493,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(50)",
              ``,
              ``,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_1_501,
              state.e41_2_1_502,
              state.e41_2_1_503,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(51)",
              ``,
              `特殊な構造の排煙設備`,
              `吸込口の設置位置`,
              state.e41_2_1_511,
              state.e41_2_1_512,
              state.e41_2_1_513,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(52)",
              ``,
              `の給気送風機の吸込口`,
              `吸込口の周囲の状況`,
              state.e41_2_1_521,
              state.e41_2_1_522,
              state.e41_2_1_523,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(53)",
              ``,
              ``,
              `屋外に設置された吸込口への雨水等の防止措置の状況`,
              state.e41_2_1_531,
              state.e41_2_1_532,
              state.e41_2_1_533
            )}
            <View style={styles.section_4}>
              <Text style={styles.text8}>
                ※塗りつぶしの項目は３年以内に１回検査を行う
              </Text>
            </View>
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_E()}
            {createTableRow_5_95(
              "2",
              "令第123条第３項第２号に規定する階段室又は付室、令第129条の13の３第１３項に規定する昇降路又は乗降ロビー"
            )}
            {createTableRow_5_40_35_5_5_5_5(
              "(1)",
              `特別避難階段の階段室又は付室及び非常用エレベーターの`,
              `排煙機、排煙口及び給気口の作動の状況`,
              state.e41_2_2_11,
              state.e41_2_2_12,
              state.e41_2_2_13,
              0
            )}
            {createTableRow_5_40_35_5_5_5_5(
              "(2)",
              `昇降路又は乗降ロビーに設ける排煙口及び給気口`,
              `給気口の周囲の状況`,
              state.e41_2_2_21,
              state.e41_2_2_22,
              state.e41_2_2_23
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              `加圧防排煙設備`,
              `排煙風道（隠蔽部分`,
              `排煙風道の劣化及び損傷の状況`,
              state.e41_2_2_31,
              state.e41_2_2_32,
              state.e41_2_2_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              `及び埋設部分を除く。）`,
              `排煙風道の取付けの状況`,
              state.e41_2_2_41,
              state.e41_2_2_42,
              state.e41_2_2_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `排煙風道の材質`,
              state.e41_2_2_51,
              state.e41_2_2_52,
              state.e41_2_2_53,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              `給気口の外観`,
              `給気口の周囲の状況`,
              state.e41_2_2_61,
              state.e41_2_2_62,
              state.e41_2_2_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `給気口の取付けの状況`,
              state.e41_2_2_71,
              state.e41_2_2_72,
              state.e41_2_2_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `給気口の手動開放装置の周囲の状況`,
              state.e41_2_2_81,
              state.e41_2_2_82,
              state.e41_2_2_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `給気口の手動開放装置の操作方法の表示の状況`,
              state.e41_2_2_91,
              state.e41_2_2_92,
              state.e41_2_2_93,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              `給気口の性能`,
              `給気口の手動開放装置による開放の状況`,
              state.e41_2_2_101,
              state.e41_2_2_102,
              state.e41_2_2_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `給気口の開放の状況`,
              state.e41_2_2_111,
              state.e41_2_2_112,
              state.e41_2_2_113,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              `給気風道（穏蔽部分`,
              `給気風道の劣化及び損傷の状況`,
              state.e41_2_2_121,
              state.e41_2_2_122,
              state.e41_2_2_123,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              `及び埋設部分を除く。）`,
              `給気風道の取付けの状況`,
              state.e41_2_2_131,
              state.e41_2_2_132,
              state.e41_2_2_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `給気風道の材質`,
              state.e41_2_2_141,
              state.e41_2_2_142,
              state.e41_2_2_143,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              `給気送風機の外観`,
              `給気送風機の設置の状況`,
              state.e41_2_2_151,
              state.e41_2_2_152,
              state.e41_2_2_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              ``,
              `給気風道との接続の状況`,
              state.e41_2_2_161,
              state.e41_2_2_162,
              state.e41_2_2_163,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              `給気送風機の性能`,
              `給気口の開放と連動起動の状況`,
              state.e41_2_2_171,
              state.e41_2_2_172,
              state.e41_2_2_173,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(18)",
              ``,
              ``,
              `給気送風機の作動の状況`,
              state.e41_2_2_181,
              state.e41_2_2_182,
              state.e41_2_2_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(19)",
              ``,
              ``,
              `電源を必要とする給気送風機の予備電源による作動の状況`,
              state.e41_2_2_191,
              state.e41_2_2_192,
              state.e41_2_2_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              ``,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_2_201,
              state.e41_2_2_202,
              state.e41_2_2_203,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              ``,
              `給気送風機の吸込口`,
              `吸込口の設置位置`,
              state.e41_2_2_211,
              state.e41_2_2_212,
              state.e41_2_2_213,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(22)",
              ``,
              ``,
              `吸込口の周囲の状況`,
              state.e41_2_2_221,
              state.e41_2_2_222,
              state.e41_2_2_223,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(23)",
              ``,
              ``,
              `屋外に設置された吸込口への雨水等の防止措置の状況`,
              state.e41_2_2_231,
              state.e41_2_2_232,
              state.e41_2_2_233,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5_color(
              "(24)",
              ``,
              `遮煙開口部の性能`,
              `遮煙開口部の排出風速`,
              state.e41_2_2_241,
              state.e41_2_2_242,
              state.e41_2_2_243,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(25)",
              ``,
              `空気逃し口の外観`,
              `空気逃し口の大きさ及び位置`,
              state.e41_2_2_251,
              state.e41_2_2_252,
              state.e41_2_2_253,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(26)",
              ``,
              ``,
              `空気逃し口の周囲の状況`,
              state.e41_2_2_261,
              state.e41_2_2_262,
              state.e41_2_2_263,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(27)",
              ``,
              ``,
              `空気逃し口の取付けの状況`,
              state.e41_2_2_271,
              state.e41_2_2_272,
              state.e41_2_2_273,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(28)",
              ``,
              `空気逃し口の性能`,
              `空気逃し口の作動の状況`,
              state.e41_2_2_281,
              state.e41_2_2_282,
              state.e41_2_2_283,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(29)",
              ``,
              `圧力調整装置の外観`,
              `圧力調整装置の大きさ及び位置`,
              state.e41_2_2_291,
              state.e41_2_2_292,
              state.e41_2_2_293,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(30)",
              ``,
              ``,
              `圧力調整装置の周囲の状況`,
              state.e41_2_2_301,
              state.e41_2_2_302,
              state.e41_2_2_303,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(31)",
              ``,
              ``,
              `圧力調整装置の取付けの状況`,
              state.e41_2_2_311,
              state.e41_2_2_312,
              state.e41_2_2_313,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(32)",
              ``,
              `圧力調整装置の性能`,
              `圧力調整装置の作動の状況`,
              state.e41_2_2_321,
              state.e41_2_2_322,
              state.e41_2_2_323,
              1,
              1
            )}
            {createTableRow_5_95("3", "令第126条の２第１項に規定する居室等")}
            {createTableRow_5_12_63_5_5_5_5(
              "(1)",
              `可動防煙壁`,
              `手動降下装置の作動の状況`,
              state.e41_2_3_11,
              state.e41_2_3_12,
              state.e41_2_3_13,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(2)",
              ``,
              `手動降下装置による連動の状況`,
              state.e41_2_3_21,
              state.e41_2_3_22,
              state.e41_2_3_23,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(3)",
              ``,
              `煙感知器による連動の状況`,
              state.e41_2_3_31,
              state.e41_2_3_32,
              state.e41_2_3_33,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(4)",
              ``,
              `可動防煙壁の材質`,
              state.e41_2_3_41,
              state.e41_2_3_42,
              state.e41_2_3_43,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(5)",
              ``,
              `可動防煙壁の防煙区画`,
              state.e41_2_3_51,
              state.e41_2_3_52,
              state.e41_2_3_53,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(6)",
              ``,
              `中央管理室における制御及び作動状態の監視の状況`,
              state.e41_2_3_61,
              state.e41_2_3_62,
              state.e41_2_3_63
            )}
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text8}>
              ※塗りつぶしの項目は３年以内に１回検査を行う
            </Text>
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_E()}
            {createTableRow_5_95("4", "予備電源")}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `自家用発電装置`,
              `自家用発電装置等の状況`,
              `自家用発電機室の防火区画等の貫通措置の状況`,
              state.e41_2_4_11,
              state.e41_2_4_12,
              state.e41_2_4_13,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              "",
              "",
              "発電機の発電容量",
              state.e41_2_4_21,
              state.e41_2_4_22,
              state.e41_2_4_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `発電機及び原動機の状況`,
              state.e41_2_4_31,
              state.e41_2_4_32,
              state.e41_2_4_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `燃料油、潤滑油及び冷却水の状況`,
              state.e41_2_4_41,
              state.e41_2_4_42,
              state.e41_2_4_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `始動用の空気槽の圧力`,
              state.e41_2_4_51,
              state.e41_2_4_52,
              state.e41_2_4_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              ``,
              `セル始動用蓄電池及び電気ケーブルの接続の状況`,
              state.e41_2_4_61,
              state.e41_2_4_62,
              state.e41_2_4_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `燃料及び冷却水の漏洩の状況`,
              state.e41_2_4_71,
              state.e41_2_4_72,
              state.e41_2_4_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `計器類及びランプ類の指示及び点灯の状況`,
              state.e41_2_4_81,
              state.e41_2_4_82,
              state.e41_2_4_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `自家用発電装置の取付けの状況`,
              state.e41_2_4_91,
              state.e41_2_4_92,
              state.e41_2_4_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `自家用発電機室の給排気の状況
          （屋内に設置されている場合に限る。）`,
              state.e41_2_4_101,
              state.e41_2_4_102,
              state.e41_2_4_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `接地線の接続の状況`,
              state.e41_2_4_111,
              state.e41_2_4_112,
              state.e41_2_4_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              ``,
              `絶縁抵抗`,
              state.e41_2_4_121,
              state.e41_2_4_122,
              state.e41_2_4_123,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              `自家用発電装置の性能`,
              `電源の切替えの状況`,
              state.e41_2_4_131,
              state.e41_2_4_132,
              state.e41_2_4_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `始動の状況`,
              state.e41_2_4_141,
              state.e41_2_4_142,
              state.e41_2_4_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `運転の状況`,
              state.e41_2_4_151,
              state.e41_2_4_152,
              state.e41_2_4_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              ``,
              `排気の状況`,
              state.e41_2_4_161,
              state.e41_2_4_162,
              state.e41_2_4_163,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              ``,
              `コンプレッサー、燃料ポンプ、冷却水ポンプ等
          の補機類の作動の状況`,
              state.e41_2_4_171,
              state.e41_2_4_172,
              state.e41_2_4_173
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(18)",
              `直結エンジン`,
              `直結エンジンの外観`,
              `直結エンジンの設置の状況`,
              state.e41_2_4_181,
              state.e41_2_4_182,
              state.e41_2_4_183,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(19)",
              ``,
              ``,
              `燃料油、潤滑油及び冷却水の状況`,
              state.e41_2_4_191,
              state.e41_2_4_192,
              state.e41_2_4_193,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(20)",
              ``,
              ``,
              `セル始動用蓄電池及び電気ケーブルの接続の状況`,
              state.e41_2_4_201,
              state.e41_2_4_202,
              state.e41_2_4_203,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(21)",
              ``,
              ``,
              `計器類及びランプ類の指示及び点灯の状況`,
              state.e41_2_4_211,
              state.e41_2_4_212,
              state.e41_2_4_213,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(22)",
              ``,
              ``,
              `給気部及び排気管の取付けの状況`,
              state.e41_2_4_221,
              state.e41_2_4_222,
              state.e41_2_4_223,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(23)",
              ``,
              ``,
              `Ｖベルト`,
              state.e41_2_4_231,
              state.e41_2_4_232,
              state.e41_2_4_233,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(24)",
              ``,
              ``,
              `接地線の接続の状況`,
              state.e41_2_4_241,
              state.e41_2_4_242,
              state.e41_2_4_243,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(25)",
              ``,
              ``,
              `絶縁抵抗`,
              state.e41_2_4_251,
              state.e41_2_4_252,
              state.e41_2_4_253,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(26)",
              ``,
              `直結エンジンの性能`,
              `始動及び停止並びに運転の状況`,
              state.e41_2_4_261,
              state.e41_2_4_262,
              state.e41_2_4_263
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_2_6_11,
              state.e41_2_6_12,
              state.e41_2_6_13,
              state.e41_2_6_14,
              state.e41_2_6_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_2_6_21,
              state.e41_2_6_22,
              state.e41_2_6_23,
              state.e41_2_6_24,
              state.e41_2_6_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_2_6_31,
              state.e41_2_6_32,
              state.e41_2_6_33,
              state.e41_2_6_34,
              state.e41_2_6_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_2_6_41,
              state.e41_2_6_42,
              state.e41_2_6_43,
              state.e41_2_6_44,
              state.e41_2_6_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_2_6_51,
              state.e41_2_6_52,
              state.e41_2_6_53,
              state.e41_2_6_54,
              state.e41_2_6_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const PageInvestLight = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}>別記第三号（A４)</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>検査結果表</Text>
          </View>
          <View style={styles.section_2}>
            <Text style={styles.text1}>
              {`(非常用の照明装置) 第${bNumber}棟 ${state9.e92_1}`}
            </Text>
          </View>
          <View style={tableStyle_18}>
            {createTableHeader_20_20_40_20(
              state.e40_3_11,
              state.e40_3_12,
              state.e40_3_21,
              state.e40_3_22,
              state.e40_3_31,
              state.e40_3_32
            )}
          </View>
          <View style={tableStyle_2}>
            {createTableHeader_head_E()}
            {createTableRow_5_95("1", `照明器具`)}
            {createTableRow_5_12_63_5_5_5_5(
              "(1)",
              `非常用の照明器具`,
              `使用電球、ランプ等 `,
              state.e41_3_1_11,
              state.e41_3_1_12,
              state.e41_3_1_13,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(2)",
              ``,
              `照明器具の取付けの状況`,
              state.e41_3_1_21,
              state.e41_3_1_22,
              state.e41_3_1_23,
              1
            )}
            {createTableRow_5_95(
              "2",
              `電池内蔵形の蓄電池、電源別置形の蓄電池及び自家用発電装置`
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(1)",
              `予備電源`,
              `予備電源への切替え及び器具の点灯の状況並びに予備電源の性能`,
              state.e41_3_2_11,
              state.e41_3_2_12,
              state.e41_3_2_13
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(2)",
              `照度`,
              `照度の状況`,
              state.e41_3_2_21,
              state.e41_3_2_22,
              state.e41_3_2_23
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(3)",
              `分電盤`,
              `非常用電源分岐回路の表示の状況 `,
              state.e41_3_2_31,
              state.e41_3_2_32,
              state.e41_3_2_33
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(4)",
              `配線`,
              `配電管等の防火区画貫通措置の状況（隠蔽部分及び埋設部分を除く。） `,
              state.e41_3_2_41,
              state.e41_3_2_42,
              state.e41_3_2_43
            )}
            {createTableRow_5_95("3", `電源別置形の蓄電池及び自家用発電装置`)}
            {createTableRow_5_12_63_5_5_5_5(
              "(1)",
              `配線`,
              `照明器具の取付けの状況及び配線の接続の状況（隠蔽部分及び埋設部分を除く。）`,
              state.e41_3_3_11,
              state.e41_3_3_12,
              state.e41_3_3_13,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(2)",
              ``,
              `電気回路の接続の状況`,
              state.e41_3_3_21,
              state.e41_3_3_22,
              state.e41_3_3_23,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(3)",
              ``,
              `接続部（幹線分岐及びボックス内に限る。）の耐熱処理の状況`,
              state.e41_3_3_31,
              state.e41_3_3_32,
              state.e41_3_3_33,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(4)",
              ``,
              `予備電源から非常用の照明器具間の配線の耐熱処理の状況（隠蔽部分及び埋設部分を除く。）`,
              state.e41_3_3_41,
              state.e41_3_3_42,
              state.e41_3_3_43
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(5)",
              `切替回路`,
              `常用の電源から蓄電池設備への切替えの状況`,
              state.e41_3_3_51,
              state.e41_3_3_52,
              state.e41_3_3_53,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(6)",
              ``,
              `蓄電池設備と自家用発電装置併用の場合の切替えの状況`,
              state.e41_3_3_61,
              state.e41_3_3_62,
              state.e41_3_3_63
            )}
            {createTableRow_5_95("4", `電池内蔵形の蓄電池`)}
            {createTableRow_5_12_63_5_5_5_5(
              "(1)",
              `配線及び`,
              `充電ランプの点灯の状況 `,
              state.e41_3_4_11,
              state.e41_3_4_12,
              state.e41_3_4_13,
              0
            )}
            {createTableRow_5_12_63_5_5_5_5(
              "(2)",
              `充電ランプ`,
              `誘導灯及び非常用照明兼用器具の専用回路の確保の状況 `,
              state.e41_3_4_21,
              state.e41_3_4_22,
              state.e41_3_4_23
            )}
            {createTableRow_5_95("5", `電源別置形の蓄電池`)}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `蓄電池`,
              `蓄電池等の状況`,
              `蓄電池室の防火区画等の貫通措置の状況`,
              state.e41_3_5_11,
              state.e41_3_5_12,
              state.e41_3_5_13,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              ``,
              ``,
              `蓄電池室の換気の状況`,
              state.e41_3_5_21,
              state.e41_3_5_22,
              state.e41_3_5_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `蓄電池の設置の状況`,
              state.e41_3_5_31,
              state.e41_3_5_32,
              state.e41_3_5_33,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              `蓄電池の性能`,
              `電圧 `,
              state.e41_3_5_41,
              state.e41_3_5_42,
              state.e41_3_5_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `電解液比重 `,
              state.e41_3_5_51,
              state.e41_3_5_52,
              state.e41_3_5_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              ``,
              `電解液の温度`,
              state.e41_3_5_61,
              state.e41_3_5_62,
              state.e41_3_5_63,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              `充電器`,
              `充電器室の防火区画等の貫通措置の状況`,
              state.e41_3_5_71,
              state.e41_3_5_72,
              state.e41_3_5_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `キュービクルの取付けの状況`,
              state.e41_3_5_81,
              state.e41_3_5_82,
              state.e41_3_5_83,
              1,
              1
            )}
            {createTableRow_5_95("6", `自家用発電装置`)}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(1)",
              `自家用`,
              `自家用発電装置等の状況`,
              `自家用発電機室の防火区画等の貫通措置の状況`,
              state.e41_3_6_11,
              state.e41_3_6_12,
              state.e41_3_6_13,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(2)",
              `発電装置`,
              ``,
              `発電機の発電容量`,
              state.e41_3_6_21,
              state.e41_3_6_22,
              state.e41_3_6_23,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(3)",
              ``,
              ``,
              `発電機及び原動機の状況`,
              state.e41_3_6_31,
              state.e41_3_6_32,
              state.e41_3_6_33,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(4)",
              ``,
              ``,
              `燃料油、潤滑油及び冷却水の状況`,
              state.e41_3_6_41,
              state.e41_3_6_42,
              state.e41_3_6_43,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(5)",
              ``,
              ``,
              `始動用の空気槽の圧力`,
              state.e41_3_6_51,
              state.e41_3_6_52,
              state.e41_3_6_53,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(6)",
              ``,
              ``,
              `セル始動用蓄電池及び電気ケーブルの接続の状況`,
              state.e41_3_6_61,
              state.e41_3_6_62,
              state.e41_3_6_63,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(7)",
              ``,
              ``,
              `燃料及び冷却水の漏洩の状況`,
              state.e41_3_6_71,
              state.e41_3_6_72,
              state.e41_3_6_73,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(8)",
              ``,
              ``,
              `計器類及びランプ類の指示及び点灯の状況`,
              state.e41_3_6_81,
              state.e41_3_6_82,
              state.e41_3_6_83,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(9)",
              ``,
              ``,
              `自家用発電装置の取付けの状況`,
              state.e41_3_6_91,
              state.e41_3_6_92,
              state.e41_3_6_93,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(10)",
              ``,
              ``,
              `自家用発電機室の給排気の状況
         （屋内に設置されている場合に限る。）`,
              state.e41_3_6_101,
              state.e41_3_6_102,
              state.e41_3_6_103,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(11)",
              ``,
              ``,
              `接地線の接続の状況`,
              state.e41_3_6_111,
              state.e41_3_6_112,
              state.e41_3_6_113,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(12)",
              ``,
              ``,
              `絶縁抵抗`,
              state.e41_3_6_121,
              state.e41_3_6_122,
              state.e41_3_6_123,
              0,
              1
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(13)",
              ``,
              `自家用発電装置等の性能`,
              `電源の切替えの状況`,
              state.e41_3_6_131,
              state.e41_3_6_132,
              state.e41_3_6_133,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(14)",
              ``,
              ``,
              `始動の状況`,
              state.e41_3_6_141,
              state.e41_3_6_142,
              state.e41_3_6_143,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(15)",
              ``,
              ``,
              `運転の状況`,
              state.e41_3_6_151,
              state.e41_3_6_152,
              state.e41_3_6_153,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(16)",
              ``,
              ``,
              `排気の状況`,
              state.e41_3_6_161,
              state.e41_3_6_162,
              state.e41_3_6_163,
              0,
              0
            )}
            {createTableRow_5_12_22_41_5_5_5_5(
              "(17)",
              ``,
              ``,
              `コンプレッサー、燃料ポンプ、冷却水ポンプ等の
         補機類の作動の状況`,
              state.e41_3_6_171,
              state.e41_3_6_172,
              state.e41_3_6_173
            )}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={tableStyle_2}>
            {createTableRow_100("特記事項")}
            {createTableRow_5_25_30_30_10_header(
              "番号",
              "検査項目",
              "指摘の具体的内容等",
              "改善策の具体的内容等",
              "改善(予定)年月"
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_3_8_11,
              state.e41_3_8_12,
              state.e41_3_8_13,
              state.e41_3_8_14,
              state.e41_3_8_15_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_3_8_21,
              state.e41_3_8_22,
              state.e41_3_8_23,
              state.e41_3_8_24,
              state.e41_3_8_25_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_3_8_31,
              state.e41_3_8_32,
              state.e41_3_8_33,
              state.e41_3_8_34,
              state.e41_3_8_35_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_3_8_41,
              state.e41_3_8_42,
              state.e41_3_8_43,
              state.e41_3_8_44,
              state.e41_3_8_45_1
            )}
            {createTableRow_5_25_30_30_10(
              state.e41_3_8_51,
              state.e41_3_8_52,
              state.e41_3_8_53,
              state.e41_3_8_54,
              state.e41_3_8_55_1
            )}
          </View>
        </Page>
      </>
    );
  };

  const MyDocAnnex = ({
    state2,
    state41,
    state42,
    state43,
    bNumber,
    state9,
  }) => {
    const CheackInvestVentilation_annex = state2["e21_ni1"];
    const CheackInvestSmoke_annex = state2["e21_ni2"];
    const CheackInvestLight_annex = state2["e21_ni3"];
    return (
      <>
        <Page2 state={state2} bNumber={bNumber} state9={state9} />
        {CheackInvestVentilation_annex && (
          <PageInvestVentilation
            state={state41}
            bNumber={bNumber}
            state9={state9}
          />
        )}
        {CheackInvestSmoke_annex && (
          <PageInvestSmoke state={state42} bNumber={bNumber} state9={state9} />
        )}
        {CheackInvestLight_annex && (
          <PageInvestLight state={state43} bNumber={bNumber} state9={state9} />
        )}
      </>
    );
  };

  const Summary1 = ({ state, state3, stateS }) => {
    return (
      <Page size="A4" orientation="portrait">
        <View style={styles.section_1}>
          <Text style={styles.text1}>
            第三十六号の七様式（第六条、第六条の二の二、第六条の三、第十一条の四関係）（Ａ４）
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>定期検査報告概要書</Text>
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>（建築設備（昇降機を除く。））</Text>
        </View>
        <View style={styles.section_6}>
          {!!state.e01_i1 && (
            <Text style={styles.text1}>
              台帳番号 {state.e01_i1}-{state.e01_i2}
            </Text>
          )}
        </View>
        <View style={styles.section_2}>
          <Text style={styles.text1}>(第一面)</Text>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【1.所有者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】
            </Text>
            {!!state.e11_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.e11_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.e11_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.e11_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ホ．電話番号】　
            </Text>
            {!!state.e11_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e11_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【2.管理者】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．氏名のフリガナ】　
            </Text>
            {!!state.e12_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ロ．氏名】</Text>
            {!!state.e12_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．郵便番号】</Text>
            {!!state.e12_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．住所】</Text>
            {!!state.e12_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ni}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ホ．電話番号】</Text>
            {!!state.e12_ho && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e12_ho}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【3.報告対象建築物】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．所在地】</Text>
            {!!state.e13_i && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_i}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．名称のフリガナ】
            </Text>
            {!!state.e13_ro && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ro}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ハ．名称】　</Text>
            {!!state.e13_ha && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ha}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【ニ．用途】　</Text>
            {!!state.e13_ni && (
              <Text style={[styles.text1, { width: 415 }]}>{state.e13_ni}</Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【4.検査による指摘の概要】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【イ．指摘の内容】
            </Text>
            {!!state.e14_i1 && <Text style={styles.text1}>{state.e14_i1}</Text>}
            {!!state.e14_i2 && <Text style={styles.text1}>（既存不適格）</Text>}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．指摘の概要】　
            </Text>
            {!!state.e14_ro && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.e14_ro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．改善予定の有無】
            </Text>
            {!!state.e14_ha1 && (
              <Text style={styles.text1}>{state.e14_ha1}</Text>
            )}
            {!!state.e14_ha2_1 && (
              <Text style={styles.text1}>{`(${state.e14_ha2_1.slice(
                0,
                -2
              )}月に改善予定)`}</Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．その他特記事項】
            </Text>
            {!!state.e14_ni && (
              <Text style={[styles.text1, { maxWidth: 415 }]}>
                {state.e14_ni}
              </Text>
            )}
          </View>
        </View>
        <Svg height="10" width="595">
          <Line
            x1="20"
            y1="5"
            x2="575"
            y2="5"
            strokeWidth={1}
            stroke="rgb(0,0,0)"
          />
        </Svg>
        <View style={styles.section_4}>
          <Text style={styles.text1}>【5.不具合の発生状況】</Text>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>【イ．不具合】</Text>
            {!!stateS.esummry5_i && (
              <Text style={[styles.text1, { width: 50 }]}>
                {stateS.esummry5_i}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ロ．不具合記録】
            </Text>
            {!!stateS.esummry5_ro && (
              <Text style={[styles.text1, { width: 50 }]}>
                {stateS.esummry5_ro}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ハ．不具合の概要】
            </Text>
            {!!state3["e3_1_12"] && (
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_1_12"]}
              </Text>
            )}
          </View>
        </View>
        {!!state3["e3_1_22"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_1_22"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_1_32"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_1_32"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_2_12"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_2_12"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_2_22"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_2_22"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_2_32"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_2_32"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_3_12"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_3_12"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_3_22"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_3_22"]}
              </Text>
            </View>
          </View>
        )}
        {!!state3["e3_3_32"] && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {state3["e3_3_32"]}
              </Text>
            </View>
          </View>
        )}
        <View style={styles.section_3}>
          <View style={styles.boxContainer}>
            <Text style={[styles.text1, { width: 130 }]}>
              【ニ．改善の状況】
            </Text>
            {!!stateS.esummry5_ni1 && (
              <Text style={styles.text1}>{stateS.esummry5_ni1}</Text>
            )}
            {!!stateS.esummry5_ni2_1 && (
              <Text style={styles.text1}>
                {`(${stateS.esummry5_ni2_1.slice(0, -2)}月に改善予定)`}
              </Text>
            )}
          </View>
        </View>
        {!!stateS.esummry5_ni3 && (
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 415 }]}>
                {`(理由：${stateS.esummry5_ni3})`}
              </Text>
            </View>
          </View>
        )}
      </Page>
    );
  };

  const Summary2 = ({ state, bNumber, state9 }) => {
    return (
      <>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text
              style={styles.text1}
            >{`(第二面) 第${bNumber}棟 ${state9.e92_1}`}</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>建築設備の状況等</Text>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【1.建築物の概要】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．階数】</Text>
              {!!state.e21_i1 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地上{state.e21_i1}階
                </Text>
              )}
              {!!state.e21_i2 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  地下{state.e21_i2}階
                </Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．建築面積】
              </Text>
              {!!state.e21_ro && (
                <Text style={styles.text1}>{state.e21_ro}㎡</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ハ．延べ面積】
              </Text>
              {!!state.e21_ha && (
                <Text style={styles.text1}>{state.e21_ha}㎡</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ニ．検査対象建築設備】
              </Text>
              {!!state.e21_ni1 && <Text style={styles.text1}>換気設備</Text>}
              {!!state.e21_ni2 && <Text style={styles.text1}>　排煙設備</Text>}
              {!!state.e21_ni3 && (
                <Text style={styles.text1}>　非常用の照明装置</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【2.確認済証交付年月日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．確認済証交付年月日】
              </Text>
              {!!state.e22_i1_1 && (
                <Text style={[styles.text1, { width: 80 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.e22_i1_1))}
                </Text>
              )}
              {!!state.e22_i2 && (
                <Text style={styles.text1}>{state.e22_i2}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．確認済証交付者】
              </Text>
              {!!state.e22_ro1 && (
                <Text style={styles.text1}>{state.e22_ro1}</Text>
              )}
              {!!state.e22_ro2 && (
                <Text style={styles.text1}>{`（${state.e22_ro2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ハ．検査済証交付年月日】
              </Text>
              {!!state.e22_ha1_1 && (
                <Text style={[styles.text1, { width: 80 }]}>
                  {new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
                    era: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }).format(new Date(state.e22_ha1_1))}
                </Text>
              )}
              {!!state.e22_ha2 && (
                <Text style={styles.text1}>{state.e22_ha2}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．検査済証交付者】
              </Text>
              {!!state.e22_ni1 && (
                <Text style={styles.text1}>{state.e22_ni1}</Text>
              )}
              {!!state.e22_ni2 && (
                <Text style={styles.text1}>{`（${state.e22_ni2}）`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【3.検査日等】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【イ．今回の検査】
              </Text>
              {!!state.e23_i_1 && (
                <Text style={styles.text1}>{`${state.e23_i_1}実施`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ロ．前回の検査】
              </Text>
              {!!state.e23_ro1 && (
                <Text style={styles.text1}>{state.e23_ro1}</Text>
              )}
              {!!state.e23_ro2_1 && (
                <Text style={styles.text1}>{`（${state.e23_ro2_1}報告）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 200 }]}>
                【ハ．前回の検査に関する書類の写し】
              </Text>
              {!!state.e23_ha && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e23_ha}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【4.換気設備の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e24_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_ai1}
                </Text>
              )}
              {!!state.e24_ai2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e24_ai2}
                </Text>
              )}
              {!!state.e24_ai3 && (
                <Text style={styles.text1}>{`第${state.e24_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e24_ai4 && (
                <Text style={styles.text1}>{`第${state.e24_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e24_aro && (
                <Text style={styles.text1}>{state.e24_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e24_aha && (
                <Text style={styles.text1}>{state.e24_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e24_ani1 && (
                <Text style={styles.text1}>{state.e24_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e24_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_ani2}
                </Text>
              )}
              {!!state.e24_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e24_ani3}知事登録
                </Text>
              )}
              {!!state.e24_ani4 && (
                <Text style={styles.text1}>{`第${state.e24_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e24_aho && (
                <Text style={styles.text1}>{state.e24_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e24_ahe && (
                <Text style={styles.text1}>{state.e24_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e24_ato && (
                <Text style={styles.text1}>{state.e24_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e24_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_bi1}
                </Text>
              )}
              {!!state.e24_bi2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e24_bi2}
                </Text>
              )}
              {!!state.e24_bi3 && (
                <Text style={styles.text1}>{`第${state.e24_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e24_bi4 && (
                <Text style={styles.text1}>{`第${state.e24_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e24_bro && (
                <Text style={styles.text1}>{state.e24_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e24_bha && (
                <Text style={styles.text1}>{state.e24_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e24_bni1 && (
                <Text style={styles.text1}>{state.e24_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e24_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e24_bni2}
                </Text>
              )}
              {!!state.e24_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e24_bni3}知事登録
                </Text>
              )}
              {!!state.e24_bni4 && (
                <Text style={styles.text1}>{`第${state.e24_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e24_bho && (
                <Text style={styles.text1}>{state.e24_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e24_bhe && (
                <Text style={styles.text1}>{state.e24_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e24_bto && (
                <Text style={styles.text1}>{state.e24_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【5.換気設備の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【イ．無窓居室】
              </Text>
              {!!state.e25_i1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_i1_2 && (
                <Text style={styles.text1}>{`（${state.e25_i1_2}）`}</Text>
              )}

              {!!state.e25_i2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_i2_2 && (
                <Text style={styles.text1}>{`（${state.e25_i2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_i3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_i3_2 && (
                <Text style={styles.text1}>{`（${state.e25_i3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_i4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_i4_2 && (
                <Text style={styles.text1}>{`（${state.e25_i4_2}）`}</Text>
              )}
              {!!state.e25_i5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ロ．火気使用室】
              </Text>
              {!!state.e25_ro1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_ro1_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro1_2}）`}</Text>
              )}

              {!!state.e25_ro2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_ro2_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ro3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ro4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_ro4_2 && (
                <Text style={styles.text1}>{`（${state.e25_ro4_2}）`}</Text>
              )}
              {!!state.e25_ro5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>【ハ．居室等】</Text>
              {!!state.e25_ha1_1 && (
                <Text style={styles.text1}>自然換気設備</Text>
              )}
              {!!state.e25_ha1_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha1_2}）`}</Text>
              )}

              {!!state.e25_ha2_1 && (
                <Text style={styles.text1}>機械換気設備</Text>
              )}
              {!!state.e25_ha2_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha2_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ha3_1 && (
                <Text style={styles.text1}>中央管理方式の空気調和設備</Text>
              )}
              {!!state.e25_ha3_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha3_2}）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}></Text>
              {!!state.e25_ha4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e25_ha4_2 && (
                <Text style={styles.text1}>{`（${state.e25_ha4_2}）`}</Text>
              )}
              {!!state.e25_ha5_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 150 }]}>
                【ニ．防火ダンバーの有無】
              </Text>
              {!!state.e25_ni && (
                <Text style={styles.text1}>{state.e25_ni}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【6.排煙設備の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e28_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_ai1}
                </Text>
              )}
              {!!state.e28_ai2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e28_ai2}
                </Text>
              )}
              {!!state.e28_ai3 && (
                <Text style={styles.text1}>{`第${state.e28_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e28_ai4 && (
                <Text style={styles.text1}>{`第${state.e28_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e28_aro && (
                <Text style={styles.text1}>{state.e28_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e28_aha && (
                <Text style={styles.text1}>{state.e28_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e28_ani1 && (
                <Text style={styles.text1}>{state.e28_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e28_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_ani2}
                </Text>
              )}
              {!!state.e28_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e28_ani3}知事登録
                </Text>
              )}
              {!!state.e28_ani4 && (
                <Text style={styles.text1}>{`第${state.e28_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e28_aho && (
                <Text style={styles.text1}>{state.e28_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e28_ahe && (
                <Text style={styles.text1}>{state.e28_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e28_ato && (
                <Text style={styles.text1}>{state.e28_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e28_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_bi1}
                </Text>
              )}
              {!!state.e28_bi2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e28_bi2}
                </Text>
              )}
              {!!state.e28_bi3 && (
                <Text style={styles.text1}>{`第${state.e28_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e28_bi4 && (
                <Text style={styles.text1}>{`第${state.e28_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e28_bro && (
                <Text style={styles.text1}>{state.e28_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e28_bha && (
                <Text style={styles.text1}>{state.e28_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e28_bni1 && (
                <Text style={styles.text1}>{state.e28_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e28_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e28_bni2}
                </Text>
              )}
              {!!state.e28_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e28_bni3}知事登録
                </Text>
              )}
              {!!state.e28_bni4 && (
                <Text style={styles.text1}>{`第${state.e28_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e28_bho && (
                <Text style={styles.text1}>{state.e28_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e28_bhe && (
                <Text style={styles.text1}>{state.e28_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e28_bto && (
                <Text style={styles.text1}>{state.e28_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 150 }]}>
              【7.排煙設備の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 250 }]}>
                【イ．避難安全検証法等の適用】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i1_1 && (
                <Text style={styles.text1}>区画避難安全検証法</Text>
              )}
              {!!state.e29_i1_2 && (
                <Text style={styles.text1}>{`（${state.e29_i1_2}階）`}</Text>
              )}

              {!!state.e29_i2_1 && (
                <Text style={styles.text1}>階避難安全検証法</Text>
              )}
              {!!state.e29_i2_2 && (
                <Text style={styles.text1}>{`（${state.e29_i2_2}階）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i3_1 && (
                <Text style={styles.text1}>全館避難安全検証法</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_i4_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e29_i4_2 && (
                <Text
                  style={[styles.text1, { width: 445 }]}
                >{`(${state.e29_i4_2})`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 250 }]}>
                【ロ．特別避難階段の階段室又は付室】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ro1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ro1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro1_2}区画）`}</Text>
              )}
              {!!state.e29_ro2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ro2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro2_2}区画）`}</Text>
              )}
              {!!state.e29_ro3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro3_2}区画）`}</Text>
              )}
              {!!state.e29_ro4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 350 }]}>
                【ハ．非常用エレベーターの昇降路又は乗降ロビー】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ha1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ha1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ha1_2}区画）`}</Text>
              )}
              {!!state.e29_ha2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ha2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ha2_2}区画）`}</Text>
              )}
              {!!state.e29_ro3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ro3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ro3_2}区画）`}</Text>
              )}
              {!!state.e29_ro4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 350 }]}>
                【ニ．非常用エレベーターの昇降路又は乗降ロビーの用に供する付室】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ni1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ni1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni1_2}区画）`}</Text>
              )}
              {!!state.e29_ni2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ni2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni2_2}区画）`}</Text>
              )}
              {!!state.e29_ni3_1 && <Text style={styles.text1}>加圧式</Text>}
              {!!state.e29_ni3_2 && (
                <Text style={styles.text1}>{`（${state.e29_ni3_2}区画）`}</Text>
              )}
              {!!state.e29_ni4_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ホ．居室等】</Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_ho1_1 && <Text style={styles.text1}>吸引式</Text>}
              {!!state.e29_ho1_2 && (
                <Text style={styles.text1}>{`（${state.e29_ho1_2}区画）`}</Text>
              )}
              {!!state.e29_ho2_1 && <Text style={styles.text1}>給気式</Text>}
              {!!state.e29_ho2_2 && (
                <Text style={styles.text1}>{`（${state.e29_ho2_2}区画）`}</Text>
              )}
              {!!state.e29_ho3_1 && <Text style={styles.text1}>無</Text>}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【へ．予備電源】
              </Text>
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_he1_1 && (
                <Text style={styles.text1}>{`蓄電池　`}</Text>
              )}
              {!!state.e29_he2_1 && (
                <Text style={styles.text1}>{`自家用発電装置　`}</Text>
              )}
              {!!state.e29_he3_1 && (
                <Text style={styles.text1}>{`直結エンジン　`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 50 }]}></Text>
              {!!state.e29_he4_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e29_he4_2 && (
                <Text style={[styles.text1, { width: 445 }]}>
                  {`(${state.e29_he4_2})`}
                </Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【8.非常用の照明装置の検査者】</Text>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（代表となる検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e212_ai1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_ai1}
                </Text>
              )}
              {!!state.e212_ai2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e212_ai2}
                </Text>
              )}
              {!!state.e212_ai3 && (
                <Text style={styles.text1}>{`第${state.e212_ai3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建築設備検査員</Text>
              {!!state.e212_ai4 && (
                <Text style={styles.text1}>{`第${state.e212_ai4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e212_aro && (
                <Text style={styles.text1}>{state.e212_aro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e212_aha && (
                <Text style={styles.text1}>{state.e212_aha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e212_ani1 && (
                <Text style={styles.text1}>{state.e212_ani1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e212_ani2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_ani2}
                </Text>
              )}
              {!!state.e212_ani3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e212_ani3}知事登録
                </Text>
              )}
              {!!state.e212_ani4 && (
                <Text style={styles.text1}>{`第${state.e212_ani4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e212_aho && (
                <Text style={styles.text1}>{state.e212_aho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e212_ahe && (
                <Text style={styles.text1}>{state.e212_ahe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e212_ato && (
                <Text style={styles.text1}>{state.e212_ato}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_4}>
            <Text style={styles.text1}>（その他の検査者）</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【イ．資格】</Text>
              {!!state.e212_bi1 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_bi1}
                </Text>
              )}
              {!!state.e212_bi2 && (
                <Text style={[styles.text1, { width: 50 }]}>
                  {state.e212_bi2}
                </Text>
              )}
              {!!state.e212_bi3 && (
                <Text style={styles.text1}>{`第${state.e212_bi3}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              <Text style={[styles.text1, { width: 180 }]}>建物設備検査員</Text>
              {!!state.e212_bi4 && (
                <Text style={styles.text1}>{`第${state.e212_bi4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．氏名のフリガナ】
              </Text>
              {!!state.e212_bro && (
                <Text style={styles.text1}>{state.e212_bro}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ハ．氏名】</Text>
              {!!state.e212_bha && (
                <Text style={styles.text1}>{state.e212_bha}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>【ニ．勤務先】</Text>
              {!!state.e212_bni1 && (
                <Text style={styles.text1}>{state.e212_bni1}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e212_bni2 && (
                <Text style={[styles.text1, { width: 130 }]}>
                  {state.e212_bni2}
                </Text>
              )}
              {!!state.e212_bni3 && (
                <Text style={[styles.text1, { width: 100 }]}>
                  {state.e212_bni3}知事登録
                </Text>
              )}
              {!!state.e212_bni4 && (
                <Text style={styles.text1}>{`第${state.e212_bni4}号`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ホ．郵便番号】
              </Text>
              {!!state.e212_bho && (
                <Text style={styles.text1}>{state.e212_bho}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ヘ．所在地】　
              </Text>
              {!!state.e212_bhe && (
                <Text style={styles.text1}>{state.e212_bhe}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ト．電話番号】　
              </Text>
              {!!state.e212_bto && (
                <Text style={styles.text1}>{state.e212_bto}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
        </Page>
        <Page size="A4" orientation="portrait">
          <View style={styles.section_1_1}>
            <Text style={styles.text1}></Text>
          </View>
          <View style={styles.section_4}>
            <Text style={[styles.text1, { width: 250 }]}>
              【9.非常用の照明装置の概要】
            </Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【イ．照明器具】
              </Text>
              {!!state.e213_i1_1 && <Text style={styles.text1}>白熱灯</Text>}
              {!!state.e213_i1_2 && (
                <Text style={styles.text1}>{`（${state.e213_i1_2}灯）`}</Text>
              )}
              {!!state.e213_i2_1 && <Text style={styles.text1}>蛍光灯</Text>}
              {!!state.e213_i2_2 && (
                <Text style={styles.text1}>{`（${state.e213_i2_2}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_i3_1 && <Text style={styles.text1}>LEDランプ</Text>}
              {!!state.e213_i3_2 && (
                <Text style={styles.text1}>{`（${state.e213_i3_2}灯）`}</Text>
              )}
              {!!state.e213_i4_1 && <Text style={styles.text1}>その他</Text>}
              {!!state.e213_i4_2 && (
                <Text style={styles.text1}>{`（${state.e213_i4_2}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}>
                【ロ．予備電源】
              </Text>
              {!!state.e213_ro1_1 && (
                <Text style={styles.text1}>蓄電池（内蔵形）</Text>
              )}
              {!!state.e213_ro1_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro1_2}灯）`}</Text>
              )}
              {!!state.e213_ro1_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro1_3}灯）`}</Text>
              )}
              {!!state.e213_ro1_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro1_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro2_1 && (
                <Text style={styles.text1}>蓄電池（別置形）</Text>
              )}
              {!!state.e213_ro2_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro2_2}灯）`}</Text>
              )}
              {!!state.e213_ro2_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro2_3}灯）`}</Text>
              )}
              {!!state.e213_ro2_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro2_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro3_1 && (
                <Text style={styles.text1}>自家用発電装置</Text>
              )}
              {!!state.e213_ro3_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro3_2}灯）`}</Text>
              )}
              {!!state.e213_ro3_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro3_3}灯）`}</Text>
              )}
              {!!state.e213_ro3_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro3_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro4_1 && (
                <Text style={styles.text1}>
                  蓄電池（別置形）・自家発電装置併用
                </Text>
              )}
              {!!state.e213_ro4_2 && (
                <Text
                  style={styles.text1}
                >{`（居室${state.e213_ro4_2}灯）`}</Text>
              )}
              {!!state.e213_ro4_3 && (
                <Text
                  style={styles.text1}
                >{`（廊下${state.e213_ro4_3}灯）`}</Text>
              )}
              {!!state.e213_ro4_4 && (
                <Text
                  style={styles.text1}
                >{`（階段${state.e213_ro4_4}灯）`}</Text>
              )}
            </View>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              <Text style={[styles.text1, { width: 130 }]}></Text>
              {!!state.e213_ro5_1 && (
                <Text style={[styles.text1, { width: 50 }]}>その他</Text>
              )}
              {!!state.e213_ro5_2 && (
                <Text
                  style={[styles.text1, { width: 365 }]}
                >{`(${state.e213_ro5_2})`}</Text>
              )}
            </View>
          </View>
          <Svg height="10" width="595">
            <Line
              x1="20"
              y1="5"
              x2="575"
              y2="5"
              strokeWidth={1}
              stroke="rgb(0,0,0)"
            />
          </Svg>
          <View style={styles.section_4}>
            <Text style={styles.text1}>【12.備考】</Text>
          </View>
          <View style={styles.section_3}>
            <View style={styles.boxContainer}>
              {!!state.e220_1 && (
                <Text style={[styles.text1, { width: 545 }]}>
                  {state.e220_1}
                </Text>
              )}
            </View>
          </View>
        </Page>
      </>
    );
  };

  const MyDoc = () => {
    const annexList = [];

    if (buldingNumberEquipment > 1) {
      for (let index = 2; index < buldingNumberEquipment + 1; index++) {
        annexList.push(
          <MyDocAnnex
            key={index}
            state2={states[`B${index}`].e2}
            state41={states[`B${index}`].e41}
            state42={states[`B${index}`].e42}
            state43={states[`B${index}`].e43}
            bNumber={index}
            state9={states[`B${index}`].e9}
          />
        );
      }
    }

    const summaryList = [];

    for (let index = 1; index < buldingNumberEquipment + 1; index++) {
      summaryList.push(
        <Summary2
          key={index}
          state={states[`B${index}`].e2}
          bNumber={index}
          state9={states[`B${index}`].e9}
        />
      );
    }

    return (
      <Document>
        <Page1 state={state.e1} state0={state.e0} state9={state.e9} />
        <Page2 state={state.e2} bNumber={1} state9={state.e9} />
        <Page3 state={state.e3} />
        {CheackInvestVentilation && (
          <PageInvestVentilation
            state={state.e41}
            bNumber={1}
            state9={state.e9}
          />
        )}
        {CheackInvestSmoke && (
          <PageInvestSmoke state={state.e42} bNumber={1} state9={state.e9} />
        )}
        {CheackInvestLight && (
          <PageInvestLight state={state.e43} bNumber={1} state9={state.e9} />
        )}
        {annexList}
        <Summary1 state={state.e1} state3={state.e3} stateS={state.esummry} />
        {summaryList}
      </Document>
    );
  };

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: "Nasu-Regular",
    src: fontRegular,
  });

  // フォント「ナス 太字」
  Font.register({
    family: "Nasu-Bold",
    src: fontBold,
  });

  // CSSスタイル定義
  const styles = StyleSheet.create({
    text1: {
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 1,
    },
    text8: {
      fontSize: "8pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 1,
    },
    textTable: {
      textAlign: "center",
      fontSize: "10.5pt",
      fontFamily: "Nasu-Regular",
      lineHeight: 2,
    },

    section_1: {
      textAlign: "left",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_1_1: {
      textAlign: "center",
      marginTop: 18,
      marginLeft: 20,
      marginRight: 20,
    },
    section_2: {
      textAlign: "center",
      marginHorizontal: 20,
      marginVertical: 0,
    },
    section_3: {
      textAlign: "left",
      marginLeft: 30,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L50: {
      textAlign: "left",
      marginLeft: 50,
      marginRight: 20,
      marginVertical: 0,
    },
    section_L150: {
      textAlign: "left",
      marginLeft: 150,
      marginRight: 20,
      marginVertical: 0,
    },
    section_4: {
      textAlign: "left",
      marginLeft: 20,
      marginVertical: 0,
    },
    section_5: {
      textAlign: "right",
      marginRight: 20,
      marginVertical: 0,
    },
    section_6: {
      position: "absolute",
      top: 20,
      right: 50,
    },
    boxContainer: {
      flexDirection: "row",
    },
  });

  return (
    <div>
      <PDFDownloadLink
        document={<MyDoc />}
        fileName={
          "建築設備[" +
          state.e0.e01_i1 +
          "-" +
          state.e0.e01_i2 +
          "]" +
          state.e0.e01_ro1 +
          "_報告書_結果表_概要書.pdf"
        }
        style={{
          color: "#ffffff",
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Button fullWidth variant="contained" style={{ background: "#a1887f" }}>
          申請書及び概要書PDFの出力
        </Button>
      </PDFDownloadLink>
    </div>
  );
};
export default PdfEquipment;
