export const ariari = {
  height: "100%",
  borderLeft: 1,
  borderBottom: 1,
};

export const arinashi = {
  height: "100%",
  borderLeft: 1,
};

export const bangou = {
  height: "100%",
  borderBottom: 1,
};
