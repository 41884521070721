import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import {
  InputTextFieldFireEquip,
  InputTextFieldMultiFireEquip,
} from "../Parts/InputText";
import { InputRadioFireEquip } from "../Parts/InputRadio";
import { InputCheakBoxFireEquip } from "../Parts/InputCheakBox";
import {
  InputDateFireEquip,
  InputDateMonthFireEquip,
} from "../Parts/InputDate";
import { InputSelectFireEquip } from "../Parts/InputSelect";
import { COPYDATA_FIREEQUIPMENT } from "../Parts/Word";

import GenericTemplate from "../GenericTemplate";
import { prefecture } from "../Prefecture/Prefecture";

const F2 = () => {
  const location = useLocation();

  return (
    <GenericTemplate
      title="第２面　防火設備の状況等"
      type={"防火設備"}
      selectedIndex={location.pathname}
    >
      <Box
        component="form"
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <F20 />
        <F21 />
        <F22 />
        <F23 />
        <F24 />
        <F25 />
        <F26 />
        <F27 />
        <F28 />
      </Box>
    </GenericTemplate>
  );
};

export default F2;

export const F20 = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();

  const params = useParams();
  const page = params.page;
  const bulding = params.b;

  const copydata = () => {
    dispatch({
      type: COPYDATA_FIREEQUIPMENT,
      bulding,
      page,
      page_moto: "f2",
    });
  };

  if (location.pathname !== "/f2/f2/B1") {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={copydata}
              style={{ background: "#e47373" }}
            >
              １棟目をコピー
            </Button>
          </Grid>
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextFieldFireEquip key_num={"f92_1"} page={"f9"} />
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ mb: 1, borderBottom: 1 }}
        >
          <Grid item xs={2} sx={{ mb: 1 }}>
            棟名(任意)
          </Grid>
          <Grid item xs={10} sx={{ mb: 1 }}>
            <InputTextFieldFireEquip key_num={"f92_1"} page={"f9"} />
          </Grid>
        </Grid>
      </>
    );
  }
});

export const F21 = React.memo(() => {
  return (
    <div>
      【1　建築物の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　階数
        </Grid>
        <Grid item xs={2} textAlign="left">
          地上
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldFireEquip key_num={"f21_i1"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
        <Grid item xs={2} textAlign="left">
          地下
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldFireEquip key_num={"f21_i2"} />
        </Grid>
        <Grid item xs={1}>
          階
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　建築面積
        </Grid>
        <Grid item xs={8}>
          <InputTextFieldFireEquip key_num={"f21_ro"} />
        </Grid>
        <Grid item xs={2}>
          ㎡
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　延べ面積
        </Grid>
        <Grid item xs={8} sx={{ mb: 3 }}>
          <InputTextFieldFireEquip key_num={"f21_ha"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 3 }}>
          ㎡
        </Grid>
      </Grid>
    </div>
  );
});

export const F22 = React.memo(() => {
  return (
    <div>
      【２　確認済証交付年月日等】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　確認済証交付年月日
        </Grid>
        <Grid item xs={5}>
          <InputDateFireEquip
            key_num1={"f22_i1_1"}
            placeholderText={"交付年月日"}
          />
        </Grid>
        <Grid item xs={5}>
          <InputTextFieldFireEquip
            key_num={"f22_i2"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　確認済証交付者
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"f22_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextFieldFireEquip key_num={"f22_ro2"} />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ハ　検査済証交付年月日
        </Grid>
        <Grid item xs={5}>
          <InputDateFireEquip
            key_num1={"f22_ha1_1"}
            placeholderText={"交付年月日"}
          />
        </Grid>
        <Grid item xs={5}>
          <InputTextFieldFireEquip
            key_num={"f22_ha2"}
            label={"第○○号の○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ニ　検査済証交付者
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioFireEquip key_num={"f22_ni1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="建築主事"
                  control={<Radio />}
                  label="建築主事"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指定確認検査機関"
                  control={<Radio />}
                  label="指定確認検査機関"
                />
              </Grid>
              <Grid item xs={4}>
                <InputTextFieldFireEquip key_num={"f22_ni2"} />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const F23 = React.memo(() => {
  return (
    <div>
      【3　検査日等】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　今回の検査
        </Grid>
        <Grid item xs={10} textAlign="left">
          <InputDateFireEquip key_num1={"f23_i_1"} placeholderText={"実施日"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ロ　前回の検査
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputRadioFireEquip key_num={"f23_ro1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  value="実施"
                  control={<Radio />}
                  label="実施"
                />
              </Grid>
              <Grid item xs={4}>
                <InputDateFireEquip
                  key_num1={"f23_ro2_1"}
                  placeholderText={"報告日"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="未実施"
                  control={<Radio />}
                  label="未実施"
                />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={3} sx={{ mb: 1 }}>
          ハ　前回の検査に関する書類の写し
        </Grid>
        <Grid item xs={9} sx={{ mb: 1 }}>
          <InputRadioFireEquip key_num={"f23_ha"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const F24 = React.memo(() => {
  return (
    <div>
      【4　防火設備の検査者】
      <div>（代表となる検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectFireEquip
            key_num={"f24_ai1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectFireEquip key_num={"f24_ai2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldFireEquip
            key_num={"f24_ai3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          防火設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldFireEquip
            key_num={"f24_ai4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_aro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_aha"} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_ani1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectFireEquip
            key_num={"f24_ani2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldFireEquip key_num={"f24_ani3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldFireEquip
            key_num={"f24_ani4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_aho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_ahe"} />
        </Grid>
        <Grid item xs={2}>
          ト　電話番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_ato"} />
        </Grid>
      </Grid>
      <div>（その他の検査者）</div>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　資格
        </Grid>
        <Grid item xs={2}>
          <InputSelectFireEquip
            key_num={"f24_bi1"}
            items={["一級建築士", "二級建築士", "木造建築士"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputSelectFireEquip key_num={"f24_bi2"} items={prefecture} />
        </Grid>
        <Grid item xs={6}>
          <InputTextFieldFireEquip
            key_num={"f24_bi3"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          防火設備検査員
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <InputTextFieldFireEquip
            key_num={"f24_bi4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　氏名のフリガナ
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_bro"} />
        </Grid>
        <Grid item xs={2}>
          ハ　氏名
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_bha"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2}>
          ニ　勤務先
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_bni1"} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <InputSelectFireEquip
            key_num={"f24_bni2"}
            items={["一級建築士事務所", "二級建築士事務所", "木造建築士事務所"]}
          />
        </Grid>
        <Grid item xs={2}>
          <InputTextFieldFireEquip key_num={"f24_bni3"} />
        </Grid>
        <Grid item xs={2}>
          知事登録
        </Grid>
        <Grid item xs={3}>
          <InputTextFieldFireEquip
            key_num={"f24_bni4"}
            label={"第○○○○号の○○○○の部分のみ記入"}
          />
        </Grid>
        <Grid item xs={2}>
          ホ　郵便番号
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_bho"} />
        </Grid>
        <Grid item xs={2}>
          へ　所在地
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldFireEquip key_num={"f24_bhe"} />
        </Grid>
        <Grid item xs={2} sx={{ mb: 1 }}>
          ト　電話番号
        </Grid>
        <Grid item xs={10} sx={{ mb: 1 }}>
          <InputTextFieldFireEquip key_num={"f24_bto"} />
        </Grid>
      </Grid>
    </div>
  );
});

export const F25 = React.memo(() => {
  return (
    <div>
      【5　防火設備の概要】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={4}>
          イ　避難安全検証法等の適用
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_i1_1"} />
              区画避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_i1_2"}
                label={"◯階の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_i2_1"} />
              階避難安全検証法
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_i2_2"}
                label={"◯階の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_i3_1"} />
              全館避難安全検証法
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_i4_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip key_num={"f25_i4_2"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={4}>
          ロ　防火設備
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_ro1_1"} />
              防火扉
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_ro1_2"}
                label={"◯枚の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_ro2_1"} />
              防火シャッター
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_ro2_2"}
                label={"◯枚の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_ro3_1"} />
              耐火クロススクリーン
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_ro3_2"}
                label={"◯枚の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_ro4_1"} />
              ドレンチャー
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_ro4_2"}
                label={"◯台の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} textAlign="left">
          <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={6}>
              <InputCheakBoxFireEquip key_num={"f25_ro5_1"} />
              その他
            </Grid>
            <Grid item xs={6}>
              <InputTextFieldFireEquip
                key_num={"f25_ro5_2"}
                label={"◯台の◯の部分のみ記載"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export const F26 = React.memo(() => {
  return (
    <div>
      【6　防火設備の検査の状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　指摘の内容
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"f26_i1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="要是正の指摘あり"
                  control={<Radio />}
                  label="要是正の指摘あり"
                />
              </Grid>
              <Grid item xs={5}>
                <InputCheakBoxFireEquip key_num={"f26_i2"} />
                （既存不適格）
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value="指摘なし"
                  control={<Radio />}
                  label="指摘なし"
                />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　指摘の概要
        </Grid>
        <Grid item xs={10}>
          <InputTextFieldMultiFireEquip key_num={"f26_ro"} />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善予定の有無
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioFireEquip key_num={"f26_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <InputDateMonthFireEquip key_num1={"f26_ha2_1"} />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const F27 = React.memo(() => {
  return (
    <div>
      【7　防火設備の不具合の発生状況】
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          イ　不具合
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"f27_i"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={2}>
          ロ　不具合記録
        </Grid>
        <Grid item xs={10}>
          <InputRadioFireEquip key_num={"f27_ro"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel value="有" control={<Radio />} label="有" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel value="無" control={<Radio />} label="無" />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ mb: 1, borderBottom: 1 }}
      >
        <Grid item xs={2} sx={{ mb: 3 }}>
          ハ　改善の状況
        </Grid>
        <Grid item xs={10} sx={{ mb: 3 }}>
          <InputRadioFireEquip key_num={"f27_ha1"}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  value="実施済"
                  control={<Radio />}
                  label="実施済"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="改善予定"
                  control={<Radio />}
                  label="改善予定"
                />
              </Grid>
              <Grid item xs={3}>
                <InputDateMonthFireEquip key_num1={"f27_ha2_1"} />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  value="予定なし"
                  control={<Radio />}
                  label="予定なし"
                />
              </Grid>
            </Grid>
          </InputRadioFireEquip>
        </Grid>
      </Grid>
    </div>
  );
});

export const F28 = React.memo(() => {
  return (
    <div>
      【8　備考】
      <InputTextFieldMultiFireEquip key_num={"f28_1"} />
    </div>
  );
});
