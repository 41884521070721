import React from "react";
import { Text, View, Font, StyleSheet } from "@react-pdf/renderer";

import fontRegular from "../fonts/Nasu-Regular.ttf"; //ttfファイル参照
import fontBold from "../fonts/Nasu-Bold.ttf"; //ttfファイル参照

Font.register({
  family: "Nasu-Regular",
  src: fontRegular,
});

// フォント「ナス 太字」
Font.register({
  family: "Nasu-Bold",
  src: fontBold,
});

// CSSスタイル定義
const styles = StyleSheet.create({
  textTableCenter: {
    textAlign: "center",
    fontSize: "10.5pt",
    fontFamily: "Nasu-Regular",
  },
  textTableLeft: {
    textAlign: "Left",
    fontSize: "10.5pt",
    fontFamily: "Nasu-Regular",
  },
});

export const createTableHeaderA4 = () => {
  return (
    <View style={tableRowStyle} fixed>
      <View style={[firstTableColHeaderStyle, { width: 70 }]}>
        <Text style={styles.textTableCenter}>
          不具合等を
          {"\n"}
          把握した年月
        </Text>
      </View>

      <View style={[tableColHeaderStyle, { width: 145 }]}>
        <Text style={styles.textTableCenter}>不具合等の概要</Text>
      </View>

      <View style={[tableColHeaderStyle, { width: 145 }]}>
        <Text style={styles.textTableCenter}>考えられる原因</Text>
      </View>

      <View style={[tableColHeaderStyle, { width: 70 }]}>
        <Text style={styles.textTableCenter}>改善(予定) {"\n"}年月</Text>
      </View>

      <View style={[tableColHeaderStyle, { width: 145 }]}>
        <Text style={styles.textTableCenter}>改善措置の概要等</Text>
      </View>
    </View>
  );
};

export const createTableRowA4 = (state1, state2, state3, state4, state5) => {
  return (
    <View style={tableRowStyle}>
      <View style={[firstTableColStyle, { width: 70 }]}>
        <Text style={styles.textTableCenter}>
          {state1 ? state1.slice(0, -2) : "-"}
        </Text>
      </View>

      <View style={[tableColStyle, { width: 145 }]}>
        <Text style={styles.textTableLeft}>{state2 ? state2 : "-"}</Text>
      </View>

      <View style={[tableColStyle, { width: 145 }]}>
        <Text style={styles.textTableLeft}>{state3 ? state3 : "-"}</Text>
      </View>

      <View style={[tableColStyle, { width: 70 }]}>
        <Text style={styles.textTableCenter}>
          {state4 ? state4.slice(0, -2) : "-"}
        </Text>
      </View>

      <View style={[tableColStyle, { width: 145 }]}>
        <Text style={styles.textTableLeft}>{state5 ? state5 : "-"}</Text>
      </View>
    </View>
  );
};

export const tableStyleA4 = {
  display: "table",
  width: "auto",
  marginTop: 18,
  marginLeft: 20,
  marginRight: 20,
};

const tableRowStyle = {
  flexDirection: "row",
};

const firstTableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  backgroundColor: "#ffffff",
};

const tableColHeaderStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderBottomColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  backgroundColor: "#ffffff",
};

const firstTableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderTopWidth: 0,
};

const tableColStyle = {
  width: "20%",
  borderStyle: "solid",
  borderColor: "#000",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
};
